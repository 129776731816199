import * as React from 'react';
import { View } from 'react-native';
import { TextNormal } from '../common/Typography';
import { ViewStyle } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import colors from '../../constants/colors';
import { PromoCode } from '../../sharedTypes';

const PromoCodeItemCell = ({
  promoCode,
  style,
  onClick,
}: {
  promoCode: PromoCode;
  style?: ViewStyle;
  onClick: (event: any, dispute: any) => void;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  return (
    // @ts-ignore
    <View
      onClick={(event: any) => onClick(event, promoCode)}
      ref={ref}
      style={[
        {
          height: 60,
          paddingHorizontal: 10,
          borderWidth: 1,
          borderColor: colors.borderColor,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
          flexDirection: 'row',
          backgroundColor: colors.white,
        },
        isHovered && {
          'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
        },
        style,
      ]}
    >
      <Section label={promoCode.code} />
      <Section
        label={
          promoCode.discountType === 'absolute'
            ? `${promoCode.discountAmount} $`
            : `${(promoCode.discountAmount * 100).toFixed()} %`
        }
      />

      <Section label={`${(promoCode.companyShare * 100).toFixed()} %`} />
      <Section label={`${(promoCode.trainerShare * 100).toFixed()} %`} />
      <Section label={`${promoCode.usedBy.length}`} />
    </View>
  );
};

export default PromoCodeItemCell;

export const Section = ({
  label,
  onClick,
  withHover,
  sorted,
}: {
  label: string;
  onClick?: () => void;
  withHover?: boolean;
  sorted?: boolean;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  return (
    <View
      ref={ref}
      // @ts-ignore
      style={[
        { flex: 1, justifyContent: 'center', paddingLeft: 5 },
        isHovered && withHover
          ? {
              // @ts-ignore
              'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
            }
          : null,
      ]}
      // @ts-ignore
      onClick={onClick}
    >
      <TextNormal style={{ fontWeight: sorted ? 'bold' : 'normal' }}>
        {label}
      </TextNormal>
    </View>
  );
};
