import * as React from 'react';
import { Box } from 'react-native-kondo';
import { TextTitle, TextNormal } from '../common/Typography';
import colors from '../../constants/colors';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { GymDocument, Routing } from '../../types';
import { UIStoreType } from '../../stores/ui';
import { formatError } from '../../utils/text';
import { GymStore } from '../../stores/gym';
import GymItemCell from './GymItemCell';
import FittLoadingScreen from '../common/FittLoadingScreen';
import { useHover } from 'react-native-web-hooks';
import { View } from 'react-native';
import FittTitle from '../common/FittTitle';
import FittTextInput from '../common/FittTextInput';
import FittSelect from '../common/FittSelect';
import { CompanyStore } from '../../stores/company';
import { generateGymsCSV } from '../../utils/api';
import AllTable from '../common/AllTable';
import { gymCellSectionRenderer } from './gymCellSectionRenderer';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  gym?: GymStore;
  company?: CompanyStore;
}

@inject('ui', 'routing', 'gym', 'company')
@observer
export default class GymListingScreen extends React.Component<P> {
  state = {
    loading: false,
    city: { label: 'any', value: 'any' },
    visibility: { label: 'Visible to users', value: 'all' },
    searchTerm: '',
  };

  componentDidMount() {
    this._fetchGyms();
  }

  _fetchGyms = async () => {
    try {
      const { searchTerm, city } = this.state;
      this.setState({ loading: true });
      await this.props.gym!.fetchGyms({
        searchTerm,
        city: city.value !== 'any' ? city.value : undefined,
        visibility: this.state.visibility.value,
        detailedData: true,
      });
      await this.props.company!.fetchCompanies();
    } catch (e) {
      const err = e as Error;
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(err),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _onChangeSearchTerm = (searchTerm: string) => {
    this.setState({ searchTerm, loading: true }, this._debouncedFetch);
  };

  _debouncedFetch = debounce(async () => {
    await this._fetchGyms();
  }, 500);

  _onClickGenerateCSV = async () => {
    try {
      const { csvURL } = await generateGymsCSV();
      window.open(csvURL, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const gyms = this.props.gym!.getGyms();

    return (
      <Box flex={1} p={45}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FittTitle title="Gym management" />

          <Box flexDirection="row" alignItems="center">
            <TextNormal
              style={{ marginRight: 25, cursor: 'pointer' }}
              onClick={this._onClickGenerateCSV}
            >
              Download csv
            </TextNormal>
            <AddButton onClick={() => this.props.routing!.push('/createGym')} />
          </Box>
        </Box>

        <Box
          mb={20}
          mt={50}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <FittTextInput
            placeholder="Search gym"
            style={{ flex: 1, height: 30 }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={this._onChangeSearchTerm}
          />
          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              value={this.state.visibility}
              onChange={(visibility: any) =>
                this.setState(
                  { visibility, loading: true },
                  this._debouncedFetch,
                )
              }
              options={[
                { label: 'Visible to users', value: 'all' },
                { label: 'Visible to trainers', value: 'trainer' },
                { label: 'Visible to admins', value: 'admin' },
              ]}
            />
          </Box>
          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              value={this.state.city}
              onChange={(city: any) =>
                this.setState({ city, loading: true }, this._debouncedFetch)
              }
              options={[
                { label: 'any', value: 'any' },
                ...this.props
                  .gym!.gymCities.slice()
                  .map(gc => ({ label: gc, value: gc })),
              ]}
              // placeholder="cities"
            />
          </Box>
        </Box>

        {this.state.loading ? (
          <FittLoadingScreen />
        ) : (
          <AllTable<GymDocument>
            data={gyms.map(gym => ({
              itemData: gym,
              sections: gymCellSectionRenderer(gym),
            }))}
            onClickUrl={`/gym?id=:id`}
            sections={[
              {
                label: 'Name',
              },
              {
                label: 'Approved Trainers',
              },
              {
                label: 'Selected by nb of Users',
              },
            ]}
          />
        )}
      </Box>
    );
  }
}

const AddButton = ({ onClick }: { onClick: any }) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);
  return (
    <View ref={ref}>
      <Box
        onClick={onClick}
        style={{
          height: 40,
          width: 40,
          borderRadius: 40 / 2,
          // @ts-ignore
          cursor: 'pointer',
          // @ts-ignore
          'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
        }}
        bg={isHovered ? colors.accent : colors.secondary}
        alignItems="center"
        justifyContent="center"
      >
        <TextTitle style={{ color: colors.white }}>+</TextTitle>
      </Box>
    </View>
  );
};
