import * as React from 'react';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import { useRoutingStore } from '../../stores';
import { useUiStore } from '../../stores/ui';
import { TrainerDocument } from '../../types';
import { getShareRateData, updateShareRateData } from '../../utils/api';
import Button from '../common/Button';
import FittBooleanInput from '../common/FittBooleanInput';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittTextInput from '../common/FittTextInput';
import FittTitle from '../common/FittTitle';
import { TextNormal } from '../common/Typography';
import TrainerItemCell from './TrainerItemCell';
import TrainerSectionBar from './TrainerSectionBar';

const SharesScreen = () => {
  const uiStore = useUiStore();
  const routing = useRoutingStore();
  const [companyShare, setCompanyShare] = React.useState('40');
  const [updateAll, setUpdateAll] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [trainers, setTrainers] = React.useState([]);

  const onPressSave = () => {
    uiStore.openAlert({
      title: 'Warning',
      text: 'Are you sure you want to save this new share setting?',
      buttons: [
        {
          label: 'yes',
          onClick: save,
        },
      ],
    });
  };

  React.useEffect(() => {
    const init = async () => {
      const res = await getShareRateData();
      setTrainers(res.trainers);
      setCompanyShare(res.companyShare);
      setLoading(false);
    };
    init();
  }, []);

  const save = async () => {
    uiStore.closeAlert();
    setLoading(true);
    await updateShareRateData({
      companyShare,
      shouldForceUpdateAllTrainer: updateAll,
    });
    const res = await getShareRateData();
    setTrainers(res.trainers);
    setCompanyShare(res.companyShare);
    setLoading(false);
  };

  if (loading) {
    return <FittLoadingScreen />;
  }

  return (
    <Box p={45}>
      <Box flexDirection="row" style={{ marginBottom: 50 }}>
        <FittTitle title="Shares" />
      </Box>

      <Box flexDirection="row" alignItems="flex-end">
        <TextNormal style={{ marginBottom: 15, marginRight: 10 }}>%</TextNormal>
        <FittTextInput
          label={'Company share'}
          value={companyShare}
          style={{ marginRight: 50 }}
          onChangeText={amount => setCompanyShare(amount)}
          type="number"
          max={100}
          min={0}
        />

        <TextNormal style={{ marginBottom: 15, marginRight: 10 }}>%</TextNormal>
        <FittTextInput
          label={'Trainer share'}
          disabled
          value={100 - Number(companyShare)}
          type="number"
          max={100}
          min={0}
        />
      </Box>

      <TextNormal style={{ marginTop: 20 }}>
        Edits how much the company takes on each transaction
      </TextNormal>

      <FittBooleanInput
        style={{ marginTop: 40, marginBottom: 40 }}
        label="Also force update trainers with custom shares with this new share rate?"
        value={updateAll}
        onChange={setUpdateAll}
      />

      <Box flexDirection="row" mb={50}>
        <Button
          onClick={onPressSave}
          label="Save"
          disabled={
            companyShare === '' ||
            isNaN(Number(companyShare)) ||
            Number(companyShare) < 0 ||
            Number(companyShare) > 100
          }
        />
      </Box>

      <Box height={1} bg={colors.borderColor} />

      <Box flexDirection="row" style={{ marginBottom: 50, marginTop: 50 }}>
        <FittTitle title="Trainers with custom share rate" />
      </Box>

      <Box>
        <TrainerSectionBar onClick={() => null} />
        {trainers.map((trainer: TrainerDocument) => (
          <TrainerItemCell
            key={trainer.id}
            trainer={trainer}
            style={{ marginBottom: 2 }}
            onClick={() => {
              routing!.push(`/trainer?id=${trainer.id}`);
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SharesScreen;
