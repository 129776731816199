import { action, observable, toJS } from 'mobx';

import * as api from '../utils/api';
import { GymDocument, Id, OpeningHours } from '../types';

export interface GymStore {
  createGym: (payload: {
    placeId?: string;
    name?: string;
    country?: string;
    city?: string;
    zipCode?: string;
    address?: string;
    pictures?: string[];
    districtManager?: string;
    company?: string;
    manager?: string;
    phone?: string;
    visibility?: string;
  }) => Promise<void>;
  editGym: (payload: {
    name: string;
    country: string;
    city: string;
    zipCode: string;
    address: string;
    pictures: string[];
    gymId: Id;
    districtManager?: string;
    companyId?: string;
    manager?: string;
    phone?: string;
    visibility?: string;
    lat?: string;
    lng?: string;
    openingHours?: OpeningHours;
    amenities?: string[];
  }) => Promise<void>;
  fetchGyms: (
    payload: {
      searchTerm?: string;
      city?: string;
      visibility?: string;
      detailedData?: boolean;
    } | void,
  ) => Promise<void>;
  fetchGym: (payload: { gymId: Id }) => Promise<void>;
  resetGymOpeningHours: (payload: { gymId: Id }) => Promise<void>;
  getGyms: () => GymDocument[];
  getCurrentGym: () => GymDocument | null;
  gyms: GymDocument[];
  currentGym: GymDocument | null;
  deleteGym: (payload: { gymId: Id }) => Promise<void>;
  setCurrentGym: (gym: GymDocument | null) => void;
  gymCities: string[];
}

class Gym implements GymStore {
  @observable gyms: GymStore['gyms'] = [];
  @observable currentGym: GymStore['currentGym'] = null;
  @observable gymCities: GymStore['gymCities'] = [];

  @action
  createGym: GymStore['createGym'] = async payload => {
    await api.createGym(payload);
  };

  @action
  editGym: GymStore['editGym'] = async payload => {
    await api.editGym(payload);
  };

  @action
  fetchGym: GymStore['fetchGym'] = async payload => {
    const gym = await api.getGym(payload);
    this.currentGym = gym;
  };

  @action
  resetGymOpeningHours: GymStore['resetGymOpeningHours'] = async payload => {
    await api.resetGymOpeningHours(payload);
  };

  @action
  getCurrentGym: GymStore['getCurrentGym'] = () => {
    if (this.currentGym) {
      return toJS(this.currentGym);
    }
    return null;
  };

  @action
  fetchGyms: GymStore['fetchGyms'] = async payload => {
    const gyms = await api.getGyms(payload);
    const gymCities = await api.getGymCities();
    this.gyms = gyms;
    this.gymCities = gymCities;
  };

  @action
  deleteGym: GymStore['deleteGym'] = async payload => {
    await api.deleteGym(payload);
  };

  getGyms: GymStore['getGyms'] = () => {
    return this.gyms.slice();
  };

  setCurrentGym: GymStore['setCurrentGym'] = gym => {
    this.currentGym = gym;
  };
}

export default new Gym();
