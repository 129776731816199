import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import config from '../../config';
import metrics from '../../constants/metrics';
import { useUiStore } from '../../stores/ui';
import { getPm2Url } from '../../utils/api';
import { formatError } from '../../utils/text';

const SystemScreen = observer(() => {
  const uiStore = useUiStore();
  const [url, setUrl] = React.useState('');

  const height = uiStore.innerHeight - metrics.navBarHeight;

  React.useEffect(() => {
    const fetchUrl = async () => {
      try {
        const res = await getPm2Url();
        setUrl(res.url);
      } catch (e) {
        const err = e as Error;
        uiStore.openToaster({
          text: formatError(err),
          type: 'error',
        });
      }
    };

    fetchUrl();
  }, []);

  return (
    <Main>
      <iframe src={url} style={{ height }} />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export default SystemScreen;
