import { action, observable, toJS } from 'mobx';

import * as api from '../utils/api';
import { CompanyDocument } from '../types';

export interface CompanyStore {
  fetchCompanies: () => Promise<void>;
  createCompany: (payload: { name: string }) => Promise<void>;
  getCompanies: () => CompanyDocument[];
  companies: CompanyDocument[];
}

class Company implements CompanyStore {
  @observable companies: CompanyStore['companies'] = [];

  @action
  fetchCompanies: CompanyStore['fetchCompanies'] = async () => {
    const companies = await api.getCompanies();
    this.companies = companies;
  };

  @action
  createCompany: CompanyStore['createCompany'] = async payload => {
    const companies = await api.createCompany(payload);
    this.companies = companies;
  };

  getCompanies: CompanyStore['getCompanies'] = () => {
    return toJS(this.companies);
  };
}

export default new Company();
