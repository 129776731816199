import * as React from 'react';
import { Box } from 'react-native-kondo';
import { observer, inject } from 'mobx-react';
import { Routing } from '../../types';
import { UIStoreType } from '../../stores/ui';
import { TextNormalBold } from '../common/Typography';

import { GymStore } from '../../stores/gym';
import FittBooleanInput from '../common/FittBooleanInput';
import CreateAutoPanel from './CreateAutoPanel';
import CreateManualPanel from './CreateManualPanel';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  gym?: GymStore;
  onChangeSelectedPlace: (selectedPlace: any) => void;
  onChangeFetchingMethod: (dataFetchingMethod: string) => void;
  getRef?: (ref: CreateScreen) => void;
}

@inject('ui', 'routing', 'gym')
@observer
export default class CreateScreen extends React.Component<P> {
  state = {
    dataFetchingMethod: 'manual',
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.getRef) {
        this.props.getRef(this);
      }
    }, 1000);
  }

  _manualPanel: CreateManualPanel | null = null;

  getState = () => {
    return this._manualPanel!.getState();
  };

  render() {
    return (
      <Box mb={10}>
        <TextNormalBold style={{ marginBottom: 10 }}>
          Data fetching method
        </TextNormalBold>
        <Box flexDirection="row" mb={20}>
          <FittBooleanInput
            value={this.state.dataFetchingMethod === 'manual'}
            label="Manual"
            style={{ width: 125, marginRight: 10 }}
            onChange={() =>
              this.setState({ dataFetchingMethod: 'manual' }, () => {
                this.props.onChangeSelectedPlace(null);
                this.props.onChangeFetchingMethod('manual');
              })
            }
          />
          <FittBooleanInput
            value={this.state.dataFetchingMethod === 'auto'}
            label="Auto"
            style={{ width: 100 }}
            onChange={() =>
              this.setState({ dataFetchingMethod: 'auto' }, () => {
                this.props.onChangeSelectedPlace(null);
                this.props.onChangeFetchingMethod('auto');
              })
            }
          />
        </Box>
        {this.state.dataFetchingMethod === 'auto' && (
          <CreateAutoPanel
            onChangeSelectedPlace={this.props.onChangeSelectedPlace}
          />
        )}
        {this.state.dataFetchingMethod === 'manual' && (
          <CreateManualPanel getRef={(ref: any) => (this._manualPanel = ref)} />
        )}
      </Box>
    );
  }
}
