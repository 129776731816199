import * as React from 'react';
import { View } from 'react-native';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { autorun } from 'mobx';
import { Box } from 'react-native-kondo';
import { ClipLoader } from 'react-spinners';

import routes from '../routes';
import { Routing } from '../../types';
import colors from '../../constants/colors';
import NavBar from '../navBar/NavBar';
import Toaster from '../common/Toaster';
import config from '../../config';
import { UIStoreType } from '../../stores/ui';
import { UserStore } from '../../stores/user';
import { formatError } from '../../utils/text';
import SideMenu from './SideMenu';
import metrics from '../../constants/metrics';
import AlertModal from '../common/AlertModal';

interface P {
  ui: UIStoreType;
  routing: Routing;
  user?: UserStore;
}

interface S {
  getMeloading: boolean;
  route: string;
}

@inject('ui', 'routing', 'user')
@observer
class Layout extends React.Component<P, S> {
  state = {
    getMeloading: true,
    route: this.props.routing!.location.pathname,
  };

  _mainLayout: any = React.createRef();

  componentDidMount() {
    this.props.ui.updateWindowSizes({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
    window.addEventListener('resize', () => {
      this.props.ui.updateWindowSizes({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      });
    });

    // scroll top on route changes
    // commented out for now 09/03/20
    // window.scrollTo(0, 0);
    // autorun(() => {
    //   if (this.state.route !== this.props.routing!.location.pathname) {
    //     window.scrollTo(0, 0);
    //     this.setState({ route: this.props.routing!.location.pathname });
    //   }
    // });

    setTimeout(this._getMe, 10);
  }

  componentDidCatch(e: any) {
    if (config.env !== 'dev') {
      this.props.routing!.push('/error');
    }
  }

  _getMe = async () => {
    if (this.props.user!.token) {
      try {
        this.setState({ getMeloading: true });
        await this.props.user!.getMe();
      } catch (e) {
        this.props.ui!.openToaster({
          text: formatError(e),
          type: 'error',
        });
      } finally {
        this.setState({ getMeloading: false });
      }
    } else {
      this.props.routing.push('/login');
      this.setState({ getMeloading: false });
    }
  };

  render() {
    return (
      <View
        style={{
          minHeight: this.props.ui.innerHeight,
          width: this.props.ui.innerWidth,
          backgroundColor: colors.background,
          // @ts-ignore
          'overflow-x': 'hidden',
        }}
        ref={this._mainLayout}
      >
        <View
          style={{
            width: '100%',
            flex: 1,
            flexDirection: 'row',
            paddingTop: metrics.navBarHeight,
          }}
        >
          {this.state.getMeloading ? (
            <Box flex={1} alignItems="center" justifyContent="center">
              <ClipLoader
                sizeUnit={'px'}
                size={64}
                color={colors.primary}
                loading
              />
            </Box>
          ) : (
            <>
              <SideMenu />
              {routes}
            </>
          )}
        </View>
        <AlertModal />
        <Toaster />
        <NavBar />
      </View>
    );
  }
}

// @ts-ignore
export default withRouter(Layout);
