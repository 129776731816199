import * as React from 'react';
import CompanyListing from './CompanyListing';
import { inject, observer } from 'mobx-react';
import { CompanyStore } from '../../stores/company';
import { Box } from 'react-native-kondo';
import FittTextInput from '../common/FittTextInput';
import Button from '../common/Button';
import FittTitle from '../common/FittTitle';
import AllTable from '../common/AllTable';
import { CompanyDocument } from '../../types';
import { companyDocumentCellSectionRenderer } from './CompanyCell';

@inject('company')
@observer
class CompanyScreen extends React.Component<{ company: CompanyStore }> {
  state = {
    name: '',
    loading: true,
  };

  componentDidMount() {
    this._fetch();
  }

  _fetch = async () => {
    this.setState({ loading: true });
    await this.props.company.fetchCompanies();
    this.setState({ loading: false });
  };

  render() {
    const { company } = this.props;

    const companies = company.getCompanies();

    return (
      <Box p={45}>
        <Box flexDirection="row" style={{ marginBottom: 20 }}>
          <FittTitle title="Company management" />
        </Box>
        <FittTextInput
          value={this.state.name}
          onChangeText={name => this.setState({ name })}
        />

        <Button
          label="Create Company"
          style={{ marginVertical: 20, maxWidth: 100 }}
          onClick={() => company.createCompany({ name: this.state.name })}
        />

        <AllTable<CompanyDocument>
          data={companies.map(com => ({
            itemData: com,
            sections: companyDocumentCellSectionRenderer(com),
          }))}
          leftTitle={`Companies`}
          sections={[
            {
              label: 'Company name',
            },
          ]}
        />
      </Box>
    );
  }
}
export default CompanyScreen;
