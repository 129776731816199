import * as React from 'react';
import { Box } from 'react-native-kondo';
import { useRoutingStore } from '../../stores';
import { ConversationDocument } from '../../types';
import { getUserConversations } from '../../utils/api';
import AllTable from '../common/AllTable';
import { conversationCellSectionRenderer } from './conversationCellSectionRenderer';

const UserConversationListing = () => {
  const router = useRoutingStore();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [userId, setUserId] = React.useState<string>();
  const [loading, setLoading] = React.useState(true);
  const [conversations, setConversations] = React.useState<
    ConversationDocument[]
  >([]);

  const fetchConversation = React.useCallback(async ({ id }) => {
    try {
      setLoading(true);

      const res = await getUserConversations({
        userId: id,
      });

      setConversations(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    const init = () => {
      const urlString = window.location.href;
      const url = new URL(urlString);
      const userIdUrl = url.searchParams.get('userId') as string;

      if (userIdUrl) {
        setUserId(userIdUrl);
        fetchConversation({ id: userIdUrl });
      }
    };
    init();
  }, []);

  return (
    <Box mb={10} p={45} flex={1}>
      <AllTable<ConversationDocument>
        data={conversations
          .filter(
            c =>
              c?.users[0]?.firstName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              c?.users[1]?.firstName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()),
          )
          .map(conversation => ({
            itemData: conversation,
            sections: conversationCellSectionRenderer({
              conversation,
            }),
          }))}
        search={{
          placeholder: 'Name',
          onChangeSearchText: setSearchTerm,
        }}
        onClickItem={item =>
          router.push(
            `/conversation?userId=${userId}&conversationId=${item.id}`,
          )
        }
        leftTitle="User's conversations"
        isLoading={loading}
        sections={[
          {
            label: 'User A',
          },
          {
            label: 'User B',
          },
          {
            label: 'Nb of Messages',
          },
        ]}
      />
    </Box>
  );
};

export default UserConversationListing;
