import * as React from 'react';
import { Box } from 'react-native-kondo';
import { UserDocument } from '../../types';
import { TextNormal } from '../common/Typography';
import { TableCellSection } from '../common/AllTable';
import { formatDistance } from 'date-fns';

export function userCellSectionRenderer({
  user,
}: {
  user: UserDocument;
}): TableCellSection[] {
  return [
    {
      render: () => (
        <Box>
          <TextNormal>{user.email}</TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>
            {user.firstName} {user.lastName}
          </TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>{user.phone}</TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>
            {user.provider
              ? user.provider[0].toUpperCase() + user.provider.slice(1)
              : 'N/A'}
          </TextNormal>
        </Box>
      ),
    },
    {
      render: () => {
        let date = '';

        if (user.updatedAt) {
          date = formatDistance(new Date(user.updatedAt), new Date(), {
            addSuffix: true,
          });
        }
        if (user.lastSeen) {
          date = formatDistance(new Date(user.lastSeen), new Date(), {
            addSuffix: true,
          });
        }

        return (
          <Box>
            <TextNormal>{date}</TextNormal>
          </Box>
        );
      },
    },
    {
      style: { alignItems: 'flex-end' },
      render: () => (
        <Box>
          <TextNormal style={{ textAlign: 'right' }}>
            {user.createdAt ? new Date(user.createdAt).toLocaleString() : ''}
          </TextNormal>
        </Box>
      ),
    },
  ];
}
