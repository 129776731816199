import * as React from 'react';
import { Box } from 'react-native-kondo';
import { TextNormal, TextSmall } from '../common/Typography';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { GymDocument, Routing, UserDocument } from '../../types';
import { UIStoreType } from '../../stores/ui';
import { formatError } from '../../utils/text';
import UserItemCell from './UserItemCell';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittTitle from '../common/FittTitle';
import FittTextInput from '../common/FittTextInput';
import { UserStore } from '../../stores/user';
import UserSectionBar from './UserSectionBar';
import FittSelect from '../common/FittSelect';
import { generateUsersCSV, getGyms } from '../../utils/api';
import AllTable from '../common/AllTable';
import { userCellSectionRenderer } from './userCellSectionRenderer';
import FittSelector from '../common/FittSelector';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  user?: UserStore;
}

@inject('ui', 'routing', 'user')
@observer
export default class UserListingScreen extends React.Component<P> {
  state = {
    loading: false,
    searchTerm: '',
    sortedBy: 'createdAt',
    selectedLanguage: { value: undefined, label: 'any language' },
    page: { label: 'Page 1', value: 1 },
    gyms: [] as GymDocument[],
    selectedGym: undefined as GymDocument | undefined,
  };

  componentDidMount() {
    this._fetchUsers();
  }

  _fetchUsers = async () => {
    try {
      const {
        searchTerm,
        page,
        sortedBy,
        selectedLanguage,
        selectedGym,
      } = this.state;
      this.setState({ loading: true });
      await this.props.user!.fetchUsers({
        searchTerm,
        page: page.value,
        sort: sortedBy,
        language: selectedLanguage.value,
        gymId: selectedGym?.id,
      });
      if (!this.state.gyms.length) {
        const gyms = await getGyms({});
        this.setState({ gyms });
      }
    } catch (e) {
      const err = e as Error;
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(err),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _onChangeSearchTerm = (searchTerm: string) => {
    this.setState(
      { searchTerm, loading: true, page: { label: 'Page 1', value: 1 } },
      this._debouncedFetch,
    );
  };

  _debouncedFetch = debounce(async () => {
    await this._fetchUsers();
  }, 500);

  _onChangeSorting = (sortedBy: string) => {
    this.setState({ sortedBy, loading: true }, this._debouncedFetch);
  };

  _onClickGenerateCSV = async () => {
    try {
      const { csvURL } = await generateUsersCSV();
      window.open(csvURL, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const users = this.props.user!.getUsers();

    const gymoptions = this.state.gyms.map(gym => ({
      value: gym,
      label: `${gym.name}`,
    }));

    // @ts-ignore
    gymoptions.unshift({ value: undefined, label: 'Any' });

    return (
      <Box flex={1} p={45}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <FittTitle title={`User management`} />
            <TextSmall style={{ marginTop: 10, marginLeft: 7 }}>
              Showing {users.length} results out of{' '}
              {this.props.user!.totalUsers}
            </TextSmall>
          </Box>

          <Box flexDirection="row" alignItems="center">
            <TextNormal
              style={{ marginRight: 25, cursor: 'pointer' }}
              onClick={this._onClickGenerateCSV}
            >
              Download csv
            </TextNormal>
          </Box>
        </Box>

        <Box
          mb={10}
          mt={50}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <FittTextInput
            placeholder="Search user"
            style={{ height: 30, marginRight: 20 }}
            inputStyle={{ width: 200, height: 30 }}
            onChangeText={this._onChangeSearchTerm}
          />
          <FittSelector
            mainStyle={{ maxWidth: 600, width: '100%' }}
            placeholder={'Select a gym'}
            onChange={(selectedOption: any) => {
              this.setState(
                { selectedGym: selectedOption.value, loading: true },
                this._debouncedFetch,
              );
            }}
            value={undefined}
            options={gymoptions}
          />
          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.selectedLanguage}
              onChange={(selectedLanguage: any) =>
                this.setState(
                  { selectedLanguage, loading: true },
                  this._debouncedFetch,
                )
              }
              options={[
                { value: undefined, label: 'any language' },
                { value: 'fr', label: 'fr' },
                { value: 'en', label: 'en' },
              ]}
            />
          </Box>
          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.page}
              onChange={(page: any) =>
                this.setState({ page, loading: true }, this._debouncedFetch)
              }
              options={Array.from(
                { length: this.props.user!.totalUserPages },
                (_, i) => ({
                  label: `Page ${i + 1}`,
                  value: i + 1,
                }),
              )}
              placeholder="Pages"
            />
          </Box>
        </Box>

        <Box>
          <AllTable<UserDocument>
            data={users.map(user => ({
              itemData: user,
              sections: userCellSectionRenderer({
                user,
              }),
            }))}
            noLoadingIndicator
            isLoading={this.state.loading}
            onClickUrl={`/user?id=:id`}
            sections={[
              {
                label: 'Email',
                onClick: () => this._onChangeSorting('email'),
                style: { cursor: 'pointer' },
                selected: this.state.sortedBy === 'email',
              },
              {
                label: 'Name',
                onClick: () => this._onChangeSorting('firstName'),
                style: { cursor: 'pointer' },
                selected: this.state.sortedBy === 'firstName',
              },
              {
                label: 'Phone',
              },
              {
                label: 'Auth',
              },
              {
                label: 'Last seen',
              },
              {
                label: 'Created at',
                onClick: () => this._onChangeSorting('createdAt'),
                style: { cursor: 'pointer', alignItems: 'flex-end' },
                selected: this.state.sortedBy === 'createdAt',
              },
            ]}
          />
        </Box>
      </Box>
    );
  }
}
