import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Box } from 'react-native-kondo';
import FittTextInput from '../common/FittTextInput';
import Button from '../common/Button';
import FittTitle from '../common/FittTitle';
import { TextNormal } from '../common/Typography';
import FittTextArea from '../common/FittTextArea';
import { UIStoreType } from '../../stores/ui';
import { formatError } from '../../utils/text';
import { AdminStore } from '../../stores/admin';

interface P {
  ui?: UIStoreType;
  admin?: AdminStore;
}

@inject('company', 'admin', 'ui')
@observer
class NotificationsScreen extends React.Component<P> {
  state = {
    title: '',
    body: '',
    sendNotificationLoading: false,
  };

  _onPressSendNotifications = () => {
    const ui = this.props.ui!;

    ui.openAlert({
      title: 'Warning',
      text: `You are about to send a push notification to ALL users. Are you sure you want to continue?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            this._sendNotificationsToAllUsers({
              title: this.state.title,
              body: this.state.body,
            }),
        },
        {
          label: 'No',
          onClick: ui.closeAlert,
        },
      ],
    });
  };

  _sendNotificationsToAllUsers = async ({
    title,
    body,
  }: {
    title: string;
    body: string;
  }) => {
    try {
      const { ui } = this.props;
      ui!.closeAlert();

      this.setState({ sendNotificationLoading: true });

      await this.props.admin!.adminSendPushNotificationToAll({ title, body });
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ sendNotificationLoading: false });
    }
  };

  render() {
    const { title, body } = this.state;

    return (
      <Box p={45}>
        <Box flexDirection="row" style={{ marginBottom: 50 }}>
          <FittTitle title="Notifications" />
        </Box>

        <Box flexDirection="row">
          <Box style={{ marginRight: 45 }}>
            <FittTextInput
              label={'Title'}
              value={title}
              style={{ marginBottom: 15 }}
              onChangeText={title => this.setState({ title })}
            />
            <FittTextArea
              label={'Body'}
              value={body}
              onChangeText={body =>
                body.length <= 144 ? this.setState({ body }) : null
              }
            />
          </Box>

          <Box style={{ justifyContent: 'space-between' }}>
            <Box>
              <TextNormal style={{ marginBottom: 10, fontSize: 17 }}>
                Message preview
              </TextNormal>
              <Box
                style={{
                  borderRadius: 20,
                  width: 400,
                  minHeight: 110,
                  backgroundColor: 'rgb(109,105,107)',
                  padding: 15,
                }}
              >
                <Box
                  flexDirection="row"
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <Box style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <img
                      // @ts-ignore
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 5,
                        marginRight: 10,
                      }}
                      src={require('../common/images/iconIogokettle.png')}
                    />
                    <TextNormal
                      style={{
                        color: 'rgb(196,191,193)',
                      }}
                    >
                      FITTCOACH
                    </TextNormal>
                  </Box>
                  <TextNormal
                    style={{
                      color: 'rgb(196,191,193)',
                    }}
                  >
                    Now
                  </TextNormal>
                </Box>
                <TextNormal
                  style={{
                    color: 'rgb(241,241,241)',
                    fontWeight: 600,
                    marginBottom: 7,
                  }}
                >
                  {title}
                </TextNormal>
                <TextNormal style={{ color: 'rgb(241,241,241)' }}>
                  {body}
                </TextNormal>
              </Box>
            </Box>

            <Button
              label="Send push notification to all"
              style={{ marginVertical: 20 }}
              onClick={this._onPressSendNotifications}
              loading={this.state.sendNotificationLoading}
              disabled={title === '' || body === ''}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}
export default NotificationsScreen;
