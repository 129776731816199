import * as React from 'react';
import { Image, View } from 'react-native';
import { GymDocument } from '../../types';
import { TextNormal } from '../common/Typography';
import { ViewStyle } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import colors from '../../constants/colors';

const GymItemCell = ({
  gym,
  style,
  onClick,
}: {
  gym: GymDocument;
  style?: ViewStyle;
  onClick?: () => void;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  return (
    // @ts-ignore
    <View
      onClick={onClick}
      ref={ref}
      style={[
        {
          height: 60,
          paddingHorizontal: 10,
          alignItems: 'center',
          borderWidth: 1,
          borderColor: colors.borderColor,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
          flexDirection: 'row',
          backgroundColor: colors.white,
        },
        isHovered && {
          'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
        },
        style,
      ]}
    >
      {gym.pictures.length ? (
        <Image
          source={{ uri: gym.pictures[0] }}
          style={{
            height: 45,
            width: 45,
            borderRadius: 6,
            overflow: 'hidden',
            marginRight: 10,
          }}
        />
      ) : null}
      <TextNormal>{gym.name}</TextNormal>
    </View>
  );
};

export default GymItemCell;
