import * as React from 'react';
import { View } from 'react-native';
import { Link } from 'react-router-dom';
import { IoMdLogOut } from 'react-icons/io';

import colors from '../../constants/colors';
import { TextTitle } from '../common/Typography';
import metrics from '../../constants/metrics';
import { UserStore } from '../../stores/user';
import { observer, inject } from 'mobx-react';
import { Routing } from '../../types';
import { Box } from 'react-native-kondo';
import { UIStoreType } from '../../stores/ui';
import { useHover } from 'react-native-web-hooks';

interface P {
  user?: UserStore;
  ui?: UIStoreType;
  routing?: Routing;
}

@inject('user', 'ui', 'routing')
@observer
class NavBar extends React.Component<P> {
  render() {
    if (!this.props.user!.token) {
      return null;
    }

    return (
      <View
        // @ts-ignore
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: metrics.navBarHeight,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          borderBottomWidth: 1,
          borderColor: colors.borderColor,
          backgroundColor: colors.white,
          paddingHorizontal: 20,
          // @ts-ignore
          'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
        }}
      >
        {/* <TextSubtitle style={{ fontSize: 32 }}>📱 App Builder</TextSubtitle> */}
        <Box>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <img src={require('./images/logo.png')} style={{ height: 40 }} />
          </Link>
        </Box>

        <Box
          flexDirection="row"
          style={{
            borderRadius: 10,
            padding: 10,
            // @ts-ignore
            'box-shadow': '1px -1px 2px #FFFFFF, inset -2px 2px 6px #D9D9D9',
          }}
        >
          <TextTitle
            style={{ fontSize: 32, color: colors.accent, marginRight: 8 }}
          >
            Fittcoach
          </TextTitle>
          <TextTitle style={{ fontSize: 32, color: colors.primary }}>
            Admin
          </TextTitle>
        </Box>

        <SettingButton onClick={this.props.user!.logOut} />
      </View>
    );
  }
}

export default NavBar;

const SettingButton = ({ onClick }: { onClick?: any }) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);
  return (
    <View ref={ref}>
      <Box
        onClick={onClick}
        style={{
          height: 50,
          width: 50,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
        }}
        bg={isHovered ? colors.accent : colors.secondary}
        alignItems="center"
        justifyContent="center"
      >
        <IoMdLogOut size={30} color={colors.white} />
      </Box>
    </View>
  );
};
