import { observable, action, toJS } from 'mobx';

import * as api from '../utils/api';
import { Id } from '../types';
import { PromoCode } from '../sharedTypes';

export interface CreatePromoCodePayload {
  code: string;
  maxRedeem: number;
  discountType: string;
  discountAmount: number;
  companyShare: number;
  trainerShare: number;

  selectedGymIds?: Id[];
  selectedUserIds?: Id[];

  gymRule: string;
  userRule: string;
  minimumCost: number;
  serviceType: string;
}

export interface PromoCodeStore {
  promoCodes: PromoCode[];
  currentPromoCode: PromoCode | null;

  createPromoCode: (payload: CreatePromoCodePayload) => Promise<void>;
  fetchPromoCode: (payload: { promoCodeId: Id }) => Promise<void>;
  fetchPromoCodes: (payload: { isActive: boolean }) => Promise<void>;
  getCurrentPromoCode: () => PromoCode | null;
  getPromoCodes: () => PromoCode[] | null;
  editPromoCode: (payload: {
    promoCodeId: Id;
    isActive: boolean;
    selectedUserIds: Id[];
    selectedGymIds: Id[];
  }) => Promise<void>;
}

class PromoCodeStoreClass implements PromoCodeStore {
  @observable promoCodes: PromoCodeStore['promoCodes'] = [];
  @observable currentPromoCode: PromoCodeStore['currentPromoCode'] = null;

  createPromoCode: PromoCodeStore['createPromoCode'] = async payload => {
    await api.createPromoCode(payload);
  };

  @action
  fetchPromoCode: PromoCodeStore['fetchPromoCode'] = async payload => {
    const promoCode = await api.getPromoCode(payload);
    this.currentPromoCode = promoCode;
  };

  @action
  fetchPromoCodes: PromoCodeStore['fetchPromoCodes'] = async payload => {
    const promoCodes = await api.getPromoCodes(payload);
    this.promoCodes = promoCodes;
  };

  getCurrentPromoCode: PromoCodeStore['getCurrentPromoCode'] = () => {
    return toJS(this.currentPromoCode);
  };

  getPromoCodes: PromoCodeStore['getPromoCodes'] = () => {
    return toJS(this.promoCodes);
  };

  editPromoCode: PromoCodeStore['editPromoCode'] = async payload => {
    await api.editPromoCode(payload);
  };
}

const promoCodeObj = new PromoCodeStoreClass();

export default promoCodeObj;
