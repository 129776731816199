import querystring from 'querystring';

import config from '../config';
import { Communique } from '../sharedTypes';
import user from '../stores/user';

const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export function getCommunique() {
  return get('/communique');
}

export function updateCommunique(payload: Partial<Communique>) {
  return put('/admin/communique', payload);
}

export function deleteCommunique() {
  return del('/admin/communique');
}

export function getShareRateData() {
  return get('/admin/shareRateData');
}

export function updateShareRateData(payload: any) {
  return put('/admin/shareRateData', payload);
}

export function generateTransactionsCSV(payload: any): any {
  return get('/admin/transactionsCSV', payload);
}

export function generateUsersCSV() {
  return get('/admin/usersCSV');
}

export function generateGymsCSV() {
  return get('/admin/gymsCSV');
}

export function generateTrainersCSV() {
  return get('/admin/trainersCSV');
}

function getPresignedURL({ fileName = '' }: { fileName?: string }) {
  return get('/getsignedurl', { fileName });
}

function uploadMediaToAWS(
  presignedURL: string,
  file: any,
): Promise<XMLHttpRequest> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', presignedURL);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        resolve(xhr);
      } else if (xhr.status !== 200) {
        console.warn(xhr.response);
        reject(xhr.status);
      }
    };

    xhr.setRequestHeader('Content-Type', 'multipart/form-data');

    xhr.send(file);
  });
}

export async function uploadMediaToApi(payload: { file: File }) {
  const presignedUrls = await getPresignedURL({});
  const res = await uploadMediaToAWS(
    decodeURIComponent(presignedUrls[0].uploadUrl),
    payload.file,
  );
  const url = res.responseURL.slice(0, res.responseURL.indexOf('?'));
  return { url };
}

export function createGym(payload: any): any {
  return post('/admin/gym', payload);
}

export function sendSystemCommand(payload: {
  system: 'api' | 'admin';
  action: 'restart' | 'update';
}): any {
  return post('/admin/system', payload);
}

export function editGym(payload: any): any {
  return put('/admin/gym', payload);
}

export function resetGymOpeningHours(payload: any): any {
  return post('/admin/gym/openingHours', payload);
}

export function deleteGym(payload: any): any {
  return del('/admin/gym', payload);
}

export function login(payload: any): any {
  return post('/login', payload);
}

export function getMe() {
  return get('/me');
}

export function getGyms(payload: any) {
  return get('/admin/gyms', payload);
}

export function getGymCities() {
  return get('/gymcities');
}

export function getGym(payload: any) {
  return get('/admin/gym', payload);
}

export function searchLocation(payload: { searchTerm: string }) {
  return get('/locations', payload);
}

export function getUsers(payload: any) {
  return get('/admin/users', payload);
}

export function getUser(payload: any) {
  return get('/admin/user', payload);
}

export function editUser(payload: any): any {
  return put('/admin/user', payload);
}

export function resendVerificationEmail(payload: any) {
  return post('/admin/resendVerificationEmail', payload);
}

export function sendForgetPasswordEmail(payload: any) {
  return post('/admin/sendForgetPasswordEmail', payload);
}

export function getTrainers(payload: any) {
  return get('/admin/trainers', payload);
}

export function getTrainer(payload: any) {
  return get('/admin/trainer', payload);
}

export function editTrainer(payload: any): any {
  return put('/admin/trainer', payload);
}

export function getDisputes(payload: any) {
  return get('/admin/disputes', payload);
}

export function getDispute(payload: any) {
  return get('/admin/dispute', payload);
}

export function editDispute(payload: any): any {
  return put('/admin/dispute', payload);
}

export function getTransactions(payload: any) {
  return get('/admin/transactions', payload);
}

export function getTransaction(payload: any) {
  return get('/admin/transaction', payload);
}

export function adminSetLoggedAs(payload: any) {
  return post('/admin/adminSetLoggedAs', payload);
}

export function adminRefundTransaction(payload: any) {
  return post('/admin/refundTransaction', payload);
}

export function createPromoCode(payload: any) {
  return post('/admin/promoCode', payload);
}

export function getPromoCode(payload: any) {
  return get('/admin/promoCode', payload);
}

export function getPromoCodes(payload: any) {
  return get('/admin/promoCodes', payload);
}

export function editPromoCode(payload: any) {
  return put('/admin/promoCode', payload);
}

export function adminSendPushNotificationToAll(payload: any) {
  return post('/admin/notification', payload);
}

export function getCompanies() {
  return get('/admin/companies');
}

export function createCompany(payload: any) {
  return post('/admin/company', payload);
}

export function getPm2Url(): Promise<{ url: string }> {
  return get('/admin/pm2');
}

export function getUserConversations(payload: { userId: string }) {
  return get('/admin/conversations', payload);
}

export function getUserConversation(payload: {
  userId: string;
  conversationId: string;
}) {
  return get('/admin/conversation', payload);
}

function get(url: string, params = {}) {
  const queryString = '?' + querystring.stringify(params);
  return _fetch(config.apiURL + url + queryString, {
    method: 'GET',
    headers: getHeaders(),
  });
}

function del(url: string, params = {}) {
  const queryString = '?' + querystring.stringify(params);
  return _fetch(config.apiURL + url + queryString, {
    method: 'DELETE',
    headers: getHeaders(),
  });
}

function post(url: string, data = {}) {
  return _fetch(config.apiURL + url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

function put(url: string, data = {}) {
  return _fetch(config.apiURL + url, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

// @ts-ignore
async function _fetch(url: string, options: any) {
  try {
    const res = await fetch(url, options);
    if (res.status !== 200) {
      if (res.status === 498) {
        await user.refreshAuth({ refreshToken: user.refreshToken! });
        options.headers = getHeaders();
        return _fetch(url, options);
      } else if (res.status === 401) {
        user.logOut();
        return;
      } else {
        const resJson = await res.json();
        throw resJson.error;
      }
    }
    return res.json();
  } catch (errorObject) {
    throw new Error(errorObject);
  }
}

function getHeaders() {
  const { token } = user;

  if (token) {
    return {
      ...BASE_HEADERS,
      authorization: `Bearer ${token}`,
    };
  }
  return BASE_HEADERS;
}
