import * as React from 'react';
import { Box } from 'react-native-kondo';
import { TextNormal, TextSmall } from '../common/Typography';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { Routing, TrainerDocument } from '../../types';
import { UIStoreType } from '../../stores/ui';
import { formatError } from '../../utils/text';
import TrainerItemCell from './TrainerItemCell';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittTitle from '../common/FittTitle';
import FittTextInput from '../common/FittTextInput';
import { TrainerStore } from '../../stores/trainer';
import TrainerSectionBar from './TrainerSectionBar';
import FittSelect from '../common/FittSelect';
import { generateTrainersCSV } from '../../utils/api';
import { GymStore } from '../../stores/gym';
import AllTable from '../common/AllTable';
import { trainerCellSectionRenderer } from './trainerCellSectionRenderer';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  trainer?: TrainerStore;
  gym?: GymStore;
}

@inject('ui', 'routing', 'trainer', 'gym')
@observer
export default class TrainerListingScreen extends React.Component<P> {
  state = {
    loading: false,
    searchTerm: '',
    sortedBy: 'createdAt',
    selectedLanguage: { value: undefined, label: 'any language' },
    status: { value: 'pending', label: 'Pending' },
    selectedGym: { value: 'any', label: 'Any gym' },
    page: { label: 'Page 1', value: 1 },
  };

  componentDidMount() {
    this._fetchTrainers();
    this._fetchGyms();
  }

  _fetchTrainers = async () => {
    try {
      const {
        searchTerm,
        page,
        sortedBy,
        selectedLanguage,
        status,
        selectedGym,
      } = this.state;
      this.setState({ loading: true });
      await this.props.trainer!.fetchTrainers({
        searchTerm,
        page: page.value,
        sort: sortedBy,
        language: selectedLanguage.value,
        //@ts-ignore
        status: status.value,
        gymId: selectedGym.value,
      });
    } catch (e) {
      const err = e as Error;
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(err),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _fetchGyms = async () => {
    try {
      this.setState({ loading: true });
      await this.props.gym!.fetchGyms({});
    } catch (e) {
      const err = e as Error;
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(err),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _onChangeSearchTerm = (searchTerm: string) => {
    this.setState(
      { searchTerm, loading: true, page: { label: 'Page 1', value: 1 } },
      this._debouncedFetch,
    );
  };

  _debouncedFetch = debounce(async () => {
    await this._fetchTrainers();
  }, 500);

  _onChangeSorting = (sortedBy: string) => {
    this.setState({ sortedBy, loading: true }, this._debouncedFetch);
  };

  _handleOnClick = (event: any, trainer: any) => {
    // There might be a better solution, because the search term is hard coded.
    if (event.target.innerHTML.includes("See User's page")) {
      this.props.routing!.push(`/user?id=${trainer.user.id}`);
    } else {
      this.props.routing!.push(`/trainer?id=${trainer.id}`);
    }
  };

  _onClickGenerateCSV = async () => {
    try {
      const { csvURL } = await generateTrainersCSV();
      window.open(csvURL, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const trainers = this.props.trainer!.getTrainers();
    const gyms = this.props.gym!.getGyms();

    return (
      <Box flex={1} p={45}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <FittTitle title={`Trainer management`} />
            <TextSmall style={{ marginTop: 10, marginLeft: 7 }}>
              Showing {trainers.length} results out of{' '}
              {this.props.trainer!.totalTrainers}
            </TextSmall>
          </Box>

          <Box flexDirection="row" alignItems="center">
            <TextNormal
              style={{ marginRight: 25, cursor: 'pointer' }}
              onClick={this._onClickGenerateCSV}
            >
              Download csv
            </TextNormal>
          </Box>
        </Box>

        <Box
          mb={20}
          mt={50}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <FittTextInput
            placeholder="Search trainer"
            style={{ flex: 1, height: 30 }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={this._onChangeSearchTerm}
          />

          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.status}
              onChange={(status: any) =>
                this.setState({ status, loading: true }, this._debouncedFetch)
              }
              options={[
                { value: 'all', label: 'All' },
                { value: 'approved', label: 'Approved' },
                { value: 'unlisted', label: 'Unlisted' },
                { value: 'pending', label: 'Pending' },

                { value: 'initial', label: 'App submission' },
                { value: 'typeform', label: 'Typeform submission' },
                // { value: 'vacation', label: 'Vacation' },
                { value: 'rejected', label: 'Rejected' },
                { value: 'banned', label: 'Banned' },
              ]}
              placeholder="Type"
            />
          </Box>

          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              value={this.state.selectedGym}
              onChange={(selectedGym: any) =>
                this.setState(
                  { selectedGym, loading: true },
                  this._debouncedFetch,
                )
              }
              options={[
                { value: 'any', label: 'Any gym' },
                ...gyms.map(gym => {
                  return { label: gym.name, value: gym.id };
                }),
              ]}
            />
          </Box>

          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.page}
              onChange={(page: any) =>
                this.setState({ page, loading: true }, this._debouncedFetch)
              }
              options={Array.from(
                { length: this.props.trainer!.totalTrainerPages },
                (_, i) => ({
                  label: `Page ${i + 1}`,
                  value: i + 1,
                }),
              )}
              placeholder="Pages"
            />
          </Box>
        </Box>

        <Box>
          <AllTable<TrainerDocument>
            data={trainers.map(trainer => ({
              itemData: trainer,
              sections: trainerCellSectionRenderer({
                trainer,
              }),
            }))}
            noLoadingIndicator
            isLoading={this.state.loading}
            onClickUrl={`/trainer?id=:id`}
            sections={[
              {
                label: 'Email',
                onClick: () => this._onChangeSorting('email'),
                style: { cursor: 'pointer' },
              },
              {
                label: 'Name',
                onClick: () => this._onChangeSorting('firstName'),
                style: { cursor: 'pointer' },
              },
              {
                label: 'State',
              },
              {
                label: 'Created at',
                onClick: () => this._onChangeSorting('createdAt'),
                style: { cursor: 'pointer', alignItems: 'flex-end' },
                selected: this.state.sortedBy === 'createdAt',
              },
            ]}
          />
        </Box>
      </Box>
    );
  }
}
