import * as React from 'react';
import { Box } from 'react-native-kondo';
import { observer, inject } from 'mobx-react';
import { Routing } from '../../types';
import { UIStoreType } from '../../stores/ui';

import FittTextInput from '../common/FittTextInput';
import { GymStore } from '../../stores/gym';
import { TextNormal } from '../common/Typography';
import ImagePicker from '../common/ImagePicker';
import FittBooleanInput from '../common/FittBooleanInput';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  gym?: GymStore;
  onChangeSelectedPlace?: any;
  getRef?: (ref: CreateManualPanel) => void;
}

@inject('user', 'ui', 'routing', 'gym')
@observer
export default class CreateManualPanel extends React.Component<P> {
  state = {
    name: '',
    city: '',
    country: 'Canada',
    zipCode: '',
    address: '',
    pictures: [null, null, null, null, null],
    visibility: 'all',
    phone: '',
    manager: '',
    districtManager: '',
    company: '',
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.getRef) {
        this.props.getRef(this);
      }
    }, 1000);
  }

  getState = () => {
    return {
      ...this.state,
    };
  };

  _handlePicture = (url: string, index: number) => {
    const pictures = [...this.state.pictures];
    // @ts-ignore
    pictures[index] = url;
    this.setState({ pictures });
  };

  render() {
    return (
      <Box>
        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="Name"
              placeholder="gym name"
              value={this.state.name}
              onChangeText={name => this.setState({ name })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            <FittTextInput
              label="Country"
              placeholder="gym country"
              value={this.state.country}
              onChangeText={country => this.setState({ country })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            <FittTextInput
              label="City"
              placeholder="gym city"
              value={this.state.city}
              onChangeText={city => this.setState({ city })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            <FittTextInput
              label="Address"
              placeholder="gym address"
              value={this.state.address}
              onChangeText={address => this.setState({ address })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            <FittTextInput
              label="Postal code"
              placeholder="gym postal code"
              value={this.state.zipCode}
              onChangeText={zipCode => this.setState({ zipCode })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Phone number"
              placeholder="gym phone number"
              value={this.state.phone}
              onChangeText={phone => this.setState({ phone })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            {/* <FittTextInput
              label="Company name"
              placeholder="company name"
              value={this.state.company}
              onChangeText={company => this.setState({ company })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            /> */}
            <FittTextInput
              label="Manager"
              placeholder="gym manager name"
              value={this.state.manager}
              onChangeText={manager => this.setState({ manager })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            <FittTextInput
              label="District manager"
              placeholder="district manager"
              value={this.state.districtManager}
              onChangeText={districtManager =>
                this.setState({ districtManager })
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            <Box style={{ maxWidth: 300 }}>
              <TextNormal style={{ marginBottom: 10, fontSize: 17 }}>
                Visibility
              </TextNormal>
              <FittBooleanInput
                label="Visible by everyone"
                style={{ marginBottom: 10 }}
                value={this.state.visibility === 'all'}
                onChange={() => this.setState({ visibility: 'all' })}
              />
              <FittBooleanInput
                label="Visible only by trainers"
                style={{ marginBottom: 10 }}
                value={this.state.visibility === 'trainer'}
                onChange={() => this.setState({ visibility: 'trainer' })}
              />
              <FittBooleanInput
                label="Visible only by admins"
                value={this.state.visibility === 'admin'}
                onChange={() => this.setState({ visibility: 'admin' })}
              />
            </Box>
          </Box>
        </Box>
        <TextNormal style={{ fontSize: 17, marginBottom: 15 }}>
          Pictures
        </TextNormal>
        <Box flexDirection="row">
          {Array.from({ length: 5 }, (x, i) => i).map(i => (
            <ImagePicker
              key={`pic-${i}`}
              onChangePicture={url => this._handlePicture(url, i)}
              style={{ marginRight: 10 }}
            />
          ))}
        </Box>
      </Box>
    );
  }
}
