import * as React from 'react';
import { Box } from 'react-native-kondo';
import { observer, inject } from 'mobx-react';
import { Id, Routing, TransactionDocument } from '../../types';
import { UIStoreType } from '../../stores/ui';

import FittTextInput from '../common/FittTextInput';

import { formatError } from '../../utils/text';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittSelect from '../common/FittSelect';
import FittTitle from '../common/FittTitle';
import FittBackButton from '../common/FittBackButton';
import { AdminStore } from '../../stores/admin';
import { TransactionStore } from '../../stores/transaction';
import { TextNormalBold } from '../common/Typography';
import UserInformationLayout from './UserInformationLayout';
import colors from '../../constants/colors';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Button from '../common/Button';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  admin?: AdminStore;
  transaction?: TransactionStore;
}

function getInitalState(transaction: TransactionDocument) {
  return {
    status: {
      value: transaction.status,
      label: transaction.status[0].toUpperCase() + transaction.status.slice(1),
    },
  };
}

interface S {
  initialLoading: boolean;
  refundLoading: boolean;
  saveLoading: boolean;
  status: { value: ''; label: '' } | any;
  sessionIndex: any;
}

@inject('ui', 'routing', 'transaction', 'admin')
@observer
export default class EditTransactionPanel extends React.Component<P, S> {
  state = {
    initialLoading: true,
    refundLoading: false,
    saveLoading: false,
    status: { value: 'all', label: 'All' },
    sessionIndex: { label: 'Session 1', value: 0 },
  };

  componentDidMount() {
    this._fetchTransaction();
  }

  _fetchTransaction = async () => {
    try {
      this.setState({ initialLoading: true });
      const urlString = window.location.href;
      const url = new URL(urlString);
      const transactionId = url.searchParams.get('id');

      if (transactionId) {
        await this.props.transaction!.fetchTransaction({ transactionId });

        this.setState({
          ...getInitalState(this.props.transaction!.getCurrentTransaction()!),
        });
      }
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ initialLoading: false });
    }
  };

  _formatCentsToDollars({ cents }: { cents: number }) {
    return '$' + (cents / 100).toFixed(2);
  }

  _refundAndReFetch = async ({ transactionId }: { transactionId: Id }) => {
    try {
      const ui = this.props.ui!;

      this.setState({ refundLoading: true });

      await this.props.admin!.adminRefundTransaction({
        transactionId: transactionId,
      });

      await this._fetchTransaction();

      ui.closeAlert();
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ refundLoading: false });
    }
  };

  _onPressRefund = async ({ transactionId }: { transactionId: Id }) => {
    const ui = this.props.ui!;

    ui.openAlert({
      title: 'Warning',
      text: `You are about to refund this transaction. Are you sure you want to continue?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            this._refundAndReFetch({ transactionId: transactionId }),
        },
        {
          label: 'No',
          onClick: ui.closeAlert,
        },
      ],
    });
  };

  _renderTransactionInformation = () => {
    const transaction = this.props.transaction!.getCurrentTransaction()!;

    return (
      <Box style={{ marginBottom: 30 }}>
        <TextNormalBold style={{ marginBottom: 15 }}>
          Transaction information
        </TextNormalBold>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="Creation date"
              placeholder="Creation date"
              value={new Date(transaction.createdAt).toLocaleString()}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            {['pending', 'expired'].includes(transaction.status) ? (
              <FittTextInput
                label="Expiration date"
                placeholder="Expiration date"
                value={new Date(transaction.expires).toLocaleString()}
                style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
                inputStyle={{ width: '100%', height: 30 }}
                disabled
              />
            ) : null}
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Status"
              placeholder="Status"
              value={
                transaction.status[0].toUpperCase() +
                transaction.status.slice(1)
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <Box style={{ height: 23 }}></Box>
            <Box style={{ flexDirection: 'row' }}>
              <Button
                label="Refund transaction"
                onClick={() =>
                  this._onPressRefund({ transactionId: transaction.id })
                }
                disabled={
                  transaction.status === 'cancelled' ||
                  transaction.status === 'refunded'
                }
                loading={this.state.refundLoading}
                style={{ width: 240 }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  _renderStripeInformation = () => {
    const transaction = this.props.transaction!.getCurrentTransaction()!;

    const fittcoachPayoutPriceInCents =
      transaction.service.totalPriceInCents -
      transaction.service.trainerPayoutInCents;

    const companyStripePayout = this._formatCentsToDollars({
      cents:
        fittcoachPayoutPriceInCents + transaction.service.totalTaxesInCents,
    });

    return (
      <Box style={{ marginBottom: 30 }}>
        <Box style={{ flexDirection: 'row' }}>
          <a
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              marginBottom: 15,
            }}
            href={`https://dashboard.stripe.com/payments/${transaction.stripe.paymentIntentId}`}
            target="_blank"
            rel="noreferrer"
          >
            <TextNormalBold style={{ textDecoration: 'underline' }}>
              Stripe information
              <IoIosArrowForward
                size={20}
                style={{ marginBottom: -5, marginLeft: 3 }}
                color={colors.primary}
              />
            </TextNormalBold>
          </a>
        </Box>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="Payment intent id"
              placeholder="Payment intent id"
              value={transaction.stripe.paymentIntentId}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Invoice URL"
              placeholder="Invoice URL"
              value={transaction.stripe.invoiceUrl}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Company payout amount with taxes"
              placeholder="Company payout amount with taxes"
              value={companyStripePayout}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <Box style={{ width: 150 }}></Box>
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Payout status"
              placeholder="Payout status"
              value={transaction.stripe.payoutStatus}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Trainer payout amount"
              placeholder="Trainer payout amount"
              value={transaction.stripe.payoutAmount}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
          </Box>
        </Box>
      </Box>
    );
  };

  _renderServiceInformation = () => {
    const transaction = this.props.transaction!.getCurrentTransaction()!;

    const fittcoachPayoutPriceInCents =
      transaction.service.totalPriceInCents -
      transaction.service.trainerPayoutInCents;

    const formatServiceTypeDict = {
      all: 'All',
      '1session': '1 Session',
      '3session': '3 Sessions',
      '5session': '5 Sessions',
      '10session': '10 Sessions',
      workoutPlan: 'Workout Plan',
    };

    const promoCodeDiscount = transaction.service.promoCodeDiscount;

    return (
      <Box style={{ marginBottom: 30 }}>
        <TextNormalBold style={{ marginBottom: 15 }}>
          Global service information
        </TextNormalBold>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="Type of service"
              placeholder="Type of service"
              value={
                //@ts-ignore
                formatServiceTypeDict[`${transaction.service.serviceType}`]
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <FittTextInput
              label="Number of sessions"
              placeholder="Number of sessions"
              value={transaction.service.nbOfSessions}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <FittTextInput
              label="Number of hours"
              placeholder="Number of hours"
              value={transaction.service.nbOfHours}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Number of guests"
              placeholder="Number of guests"
              value={transaction.service.nbOfGuests}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Trainer payout price"
              placeholder="Trainer payout price"
              value={this._formatCentsToDollars({
                cents: transaction.service.trainerPayoutInCents,
              })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Company payout price"
              placeholder="Company payout price"
              value={this._formatCentsToDollars({
                cents: fittcoachPayoutPriceInCents,
              })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <Box style={{ width: 150 }}></Box>
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Promo code savings"
              placeholder="Promo code savings"
              value={
                promoCodeDiscount
                  ? promoCodeDiscount === '$0.00'
                    ? 'N/A'
                    : promoCodeDiscount
                  : 'N/A'
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Hourly rate"
              placeholder="Hourly rate"
              value={transaction.service.hourPrice}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Guest price"
              placeholder="Guest price"
              value={transaction.service.guestPrice}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Total price"
              placeholder="Total price"
              value={transaction.service.totalPrice}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Total taxes"
              placeholder="Total tax"
              value={transaction.service.totalTaxes}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Total price with taxes"
              placeholder="Total price with taxes"
              value={transaction.service.finalTotalPrice}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
          </Box>
        </Box>
      </Box>
    );
  };

  _renderSessionInformation = () => {
    const transaction = this.props.transaction!.getCurrentTransaction()!;

    const { sessionIndex } = this.state;
    const currentSession = transaction.service.sessions[sessionIndex.value];

    const sessionsOptions = transaction.service.sessions.map((_, i) => {
      return { value: i, label: 'Session ' + (i + 1) };
    });

    return (
      <Box style={{ marginBottom: 30 }}>
        <TextNormalBold style={{ marginBottom: 15 }}>
          Specific session(s) information
        </TextNormalBold>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittSelect
              isSearchable={true}
              label="Individual session"
              value={this.state.sessionIndex}
              onChange={(sessionIndex: any) => this.setState({ sessionIndex })}
              options={sessionsOptions}
              placeholder="Pick a session"
              style={{ marginBottom: 15 }}
            />
            <FittTextInput
              label="Number of hours"
              placeholder="Number of hours"
              value={currentSession.nbOfHours}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Start date"
              placeholder="Start date"
              value={
                currentSession.startDate
                  ? new Date(currentSession.startDate).toLocaleString()
                  : 'Not scheduled yet'
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="User request date"
              placeholder="User request date"
              value={new Date(currentSession.createdAt).toLocaleString()}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Updated at"
              placeholder="Updated at"
              value={new Date(currentSession.updatedAt).toLocaleString()}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <Box style={{ width: 150 }}></Box>
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Session ID"
              placeholder="Number of guests"
              value={currentSession.id}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Number of guests"
              placeholder="Number of guests"
              value={currentSession.nbOfGuests}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="End date"
              placeholder="End date"
              value={
                currentSession.endDate
                  ? new Date(currentSession.endDate).toLocaleString()
                  : 'Not scheduled yet'
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Date of confirmation by the trainer"
              placeholder="Date of confirmation by the trainer"
              value={transaction.trainerConfirmationDate ? new Date(transaction.trainerConfirmationDate).toLocaleString(): "Not accepted yet"}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
          </Box>
        </Box>
      </Box>
    );
  };
  _renderUserInformation = () => {
    const transaction = this.props.transaction!.getCurrentTransaction()!;
    return (
      <Box style={{ marginBottom: 30 }}>
        <Box style={{ flexDirection: 'row' }}>
          <Link
            to={`/user?id=${transaction.user.id}`}
            style={{ textDecoration: 'none', marginBottom: 15 }}
          >
            <TextNormalBold
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Customer information
              <IoIosArrowForward
                size={20}
                style={{ marginBottom: -5, marginLeft: 3 }}
                color={colors.primary}
              />
            </TextNormalBold>
          </Link>
        </Box>

        <UserInformationLayout user={transaction!.user} />
      </Box>
    );
  };

  _renderTrainerInformation = () => {
    const transaction = this.props.transaction!.getCurrentTransaction()!;
    return (
      <Box style={{ marginBottom: 30 }}>
        <Box style={{ flexDirection: 'row' }}>
          <Link
            to={`/trainer?id=${transaction.trainer.id}`}
            style={{ textDecoration: 'none', marginBottom: 15 }}
          >
            <TextNormalBold
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Trainer information
              <IoIosArrowForward
                size={20}
                style={{ marginBottom: -5, marginLeft: 3 }}
                color={colors.primary}
              />
            </TextNormalBold>
          </Link>
        </Box>
        <UserInformationLayout user={transaction!.trainer.user} />
      </Box>
    );
  };

  _renderGymInformation = () => {
    const transaction = this.props.transaction!.getCurrentTransaction()!;

    return (
      <Box style={{ marginBottom: 30 }}>
        <Box style={{ flexDirection: 'row' }}>
          <Link
            to={`/gym?id=${transaction.gym.id}`}
            style={{ textDecoration: 'none', marginBottom: 15 }}
          >
            <TextNormalBold
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Gym information
              <IoIosArrowForward
                size={20}
                style={{ marginBottom: -5, marginLeft: 3 }}
                color={colors.primary}
              />
            </TextNormalBold>
          </Link>
        </Box>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="Name"
              placeholder="Name"
              value={transaction.gym.name}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <FittTextInput
              label="Postal code"
              placeholder="Postal code"
              value={transaction.gym.location.zip}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <Box style={{ width: 150 }}></Box>
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Manager's name"
              placeholder="Manager's name"
              value={transaction.gym.manager}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="Phone"
              placeholder="Phone"
              value={transaction.gym.phone}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
          </Box>
        </Box>
      </Box>
    );
  };

  render() {
    if (this.state.initialLoading) {
      return <FittLoadingScreen />;
    }

    const transaction = this.props.transaction!.getCurrentTransaction()!;
    return (
      <Box mb={10} p={45} flex={1}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={20}
        >
          <FittBackButton
            onClick={() => this.props.routing!.push('/transactions')}
            label="transactions"
          />

          <FittTitle title={'Transaction #' + transaction!.id} />
          <Box style={{ width: 114 }}></Box>
        </Box>

        {this._renderTransactionInformation()}
        {this._renderStripeInformation()}
        {this._renderServiceInformation()}
        {this._renderSessionInformation()}
        {this._renderUserInformation()}
        {this._renderTrainerInformation()}
        {this._renderGymInformation()}

        <Box
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
          mb={20}
          mt={150}
        ></Box>
      </Box>
    );
  }
}
