import * as React from 'react';
import { View } from 'react-native';
import { TextNormalBold } from './Typography';
import colors from '../../constants/colors';
import { Box } from 'react-native-kondo';
import { useHover } from 'react-native-web-hooks';
import { IoIosArrowBack } from 'react-icons/io';

export default ({ onClick, label }: { onClick: any; label: string }) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);
  return (
    <View ref={ref}>
      <Box
        flexDirection="row"
        alignItems="center"
        // @ts-ignore
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <IoIosArrowBack
          size={20}
          color={isHovered ? colors.accent : colors.black}
        />
        <TextNormalBold
          style={{
            fontWeight: 'bold',
            color: isHovered ? colors.accent : colors.black,
          }}
        >
          {label}
        </TextNormalBold>
      </Box>
    </View>
  );
};
