import { action } from 'mobx';
import * as api from '../utils/api';
import { Id } from '../types';

export interface AdminStore {
  adminSetLoggedAs: ({ userId }: { userId: Id }) => Promise<void>;
  adminRefundTransaction: ({
    transactionId,
  }: {
    transactionId: Id;
  }) => Promise<void>;

  adminSendPushNotificationToAll: ({
    title,
    body,
  }: {
    title: string;
    body: string;
  }) => Promise<void>;
}

class Admin implements AdminStore {
  @action
  adminSetLoggedAs: AdminStore['adminSetLoggedAs'] = async payload => {
    await api.adminSetLoggedAs(payload);
  };

  @action
  adminRefundTransaction: AdminStore['adminRefundTransaction'] = async payload => {
    await api.adminRefundTransaction(payload);
  };

  adminSendPushNotificationToAll: AdminStore['adminSendPushNotificationToAll'] = async payload => {
    await api.adminSendPushNotificationToAll(payload);
  };
}

export default new Admin();
