import * as React from 'react';
import { UserDocument } from '../../types';
import FittTextInput from '../common/FittTextInput';
import { Box } from 'react-native-kondo';

interface P {
  user?: UserDocument;
}

export default function UserInformationLayout({
  user,
}: {
  user: UserDocument;
}) {
  return (
    <Box flexDirection="row">
      <Box flex={1} mr={40}>
        <FittTextInput
          label="First Name"
          placeholder="First name"
          value={user.firstName}
          style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
          inputStyle={{ width: '100%', height: 30 }}
          disabled
        />

        <FittTextInput
          label="Email"
          placeholder="Email"
          value={user.email}
          style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
          inputStyle={{ width: '100%', height: 30 }}
          disabled
        />

        <Box style={{ width: 150 }} />
      </Box>
      <Box flex={1}>
        <FittTextInput
          label="Last Name"
          placeholder="Last name"
          value={user.lastName}
          style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
          inputStyle={{ width: '100%', height: 30 }}
          disabled
        />

        <FittTextInput
          label="Phone"
          placeholder="Phone"
          value={user.phone}
          style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
          inputStyle={{ width: '100%', height: 30 }}
          disabled
        />
      </Box>
    </Box>
  );
}
