import * as React from 'react';
import { TextNormal } from './Typography';
import colors from '../../constants/colors';
import Select from 'react-select';
import { Box } from 'react-native-kondo';

export default ({
  label,
  value,
  onChange,
  options,
  style,
  ...others
}:
  | {
      style?: any;
      label?: string;
      value: any;
      onChange: (newValue: any) => void;
      options: any;
    }
  | Partial<Select['props']>) => {
  function getColor(state: any) {
    if (state.isSelected) {
      return colors.accent;
    } else {
      if (state.isFocused) {
        return colors.accentPale;
      } else {
        return 'white';
      }
    }
  }

  const styles = {
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: getColor(state),
      color: state.isSelected ? colors.white : colors.black,
      padding: 10,
    }),
    control: (base: any, state: any) => ({
      ...base,
      fontFamily: 'Spartan',
      borderColor: state.isFocused ? colors.accent : 'lightGrey',
      boxShadow: colors.accent,
      '&:hover': { borderColor: colors.accent },
    }),
    menu: (base: any) => ({
      ...base,
      fontFamily: 'Spartan',
    }),
  };
  return (
    <Box style={[style, { zIndex: 1000 }]}>
      {label ? (
        <TextNormal style={{ marginBottom: 10, fontSize: 17 }}>
          {label}
        </TextNormal>
      ) : null}
      <Select
        value={value}
        onChange={onChange}
        styles={styles}
        options={options}
        {...others}
      />
    </Box>
  );
};
