import * as React from 'react';
import { Box } from 'react-native-kondo';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { Routing } from '../../types';
import { UIStoreType } from '../../stores/ui';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittTitle from '../common/FittTitle';

import PromoCodeSectionBar from './PromoCodeSectionBar';
import { PromoCodeStore } from '../../stores/promoCode';
import { formatError } from '../../utils/text';
import PromoCodeItemCell from './PromoCodeItemCell';
import Button from '../common/Button';
import FittSelect from '../common/FittSelect';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  promoCode?: PromoCodeStore;
}

@inject('ui', 'routing', 'promoCode')
@observer
export default class PromoCodeListingScreen extends React.Component<P> {
  state = {
    loading: false,
    searchTerm: '',
    sortedBy: 'createdAt',
    selectedLanguage: { value: undefined, label: 'any language' },
    isActive: { value: true, label: 'Active' },
  };

  componentDidMount() {
    this._fetchPromoCodes();
  }

  _fetchPromoCodes = async () => {
    try {
      this.setState({ loading: true });
      const { isActive } = this.state;
      await this.props.promoCode!.fetchPromoCodes({ isActive: isActive.value });
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _onChangeSearchTerm = (searchTerm: string) => {
    this.setState({ searchTerm, loading: true }, this._debouncedFetch);
  };

  _debouncedFetch = debounce(async () => {
    this._fetchPromoCodes();
  }, 500);

  _onChangeSorting = (sortedBy: string) => {
    this.setState({ sortedBy, loading: true }, this._debouncedFetch);
  };

  _handleOnClick = (event: any, promoCode: any) => {
    this.props.routing!.push(`/promoCode?id=${promoCode.id}`);
  };

  _onPressCreate = () => {
    this.props.routing!.push('/createPromoCode');
  };

  render() {
    const promoCodes = this.props.promoCode!.getPromoCodes();

    return (
      <Box flex={1} p={45}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <FittTitle title={`Promo codes`} />
          </Box>

          <Button
            label="Create a PromoCode"
            onClick={() => this._onPressCreate()}
            style={{ width: 240 }}
          />
        </Box>

        <Box
          mb={50}
          mt={50}
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          style={{ zIndex: 1000 }}
        >
          <FittSelect
            isSearchable={false}
            value={this.state.isActive}
            onChange={(isActive: any) =>
              this.setState({ isActive, loading: true }, this._debouncedFetch)
            }
            options={[
              { value: true, label: 'Active' },
              { value: false, label: 'Inactive' },
            ]}
            placeholder="Type"
            style={{ width: 200 }}
          />
        </Box>

        {this.state.loading ? (
          <FittLoadingScreen />
        ) : (
          <Box>
            <PromoCodeSectionBar
              sortedBy={this.state.sortedBy}
              onClick={this._onChangeSorting}
            />
            {promoCodes!.map(promoCode => (
              <PromoCodeItemCell
                key={promoCode.id}
                promoCode={promoCode}
                style={{ marginBottom: 2 }}
                onClick={() =>
                  this.props.routing!.push(`/promoCode?id=${promoCode.id}`)
                }
              />
            ))}
          </Box>
        )}
      </Box>
    );
  }
}
