import * as React from 'react';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import { ConversationDocument, MessageDocument } from '../../types';
import { getUserConversation } from '../../utils/api';
import { TextNormal, TextNormalBold } from '../common/Typography';

const UserConversationScreen = () => {
  const [loading, setLoading] = React.useState(true);
  const [conversation, setConversation] = React.useState<
    ConversationDocument
  >();

  const fetchConversation = React.useCallback(
    async ({ userId, conversationId }) => {
      try {
        setLoading(true);

        const res = await getUserConversation({
          userId,
          conversationId,
        });

        setConversation(res);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  React.useEffect(() => {
    const init = () => {
      const urlString = window.location.href;
      const url = new URL(urlString);
      const userId = url.searchParams.get('userId') as string;
      const conversationId = url.searchParams.get('conversationId') as string;
      if (userId && conversationId) {
        fetchConversation({ userId, conversationId });
      }
    };
    init();
  }, []);

  return (
    <Box mb={10} p={45} flex={1} alignItems="center" bg="white">
      <Box style={{ maxWidth: 1000, width: '100%' }}>
        {conversation?.messages.map(message => (
          <MessageCell
            key={message.id}
            message={message}
            conversation={conversation}
          />
        ))}
      </Box>
    </Box>
  );
};

const MessageCell = ({
  message,
  conversation,
}: {
  message: MessageDocument;
  conversation: ConversationDocument;
}) => {
  // @ts-ignore
  const userIndex = conversation.users.findIndex(u => u.id === message.user.id);

  if (userIndex === -1) {
    return null;
  }

  const user = conversation.users[userIndex];

  return (
    <Box
      mb={20}
      style={{
        alignSelf: userIndex === 0 ? 'flex-start' : 'flex-end',
      }}
    >
      <TextNormal style={{ marginBottom: 10 }}>
        <TextNormalBold
          style={{ color: userIndex === 0 ? colors.accent : colors.secondary }}
        >
          {user?.firstName} {user?.lastName}{' '}
        </TextNormalBold>
        said at {new Date(message.createdAt).toLocaleString()}:
      </TextNormal>
      <TextNormal>{message.text}</TextNormal>
    </Box>
  );
};

export default UserConversationScreen;
