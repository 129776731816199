import * as React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { TextNormal, fonts } from './Typography';

import colors from '../../constants/colors';
import { IoIosArrowDown } from 'react-icons/io';

export default function FittSelector({
  label,
  value,
  options,
  onChange,
  placeholder,
  mainStyle,
}: {
  label?: string;
  value?: { label: string; value: any };
  onChange?: any;
  options?: { label: string; value: any }[];
  placeholder?: string;
  mainStyle?: React.CSSProperties;
}) {
  const colourStyles = {
    control: (styles: any) => {
      return {
        display: 'flex',
      };
    },
    option: (
      styles: any,
      { data, isDisabled, isFocused, isSelected }: any,
    ) => ({
      ...styles,
      cursor: 'pointer',
      //   backgroundColor: 'transparent',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#00a39355',
      },
    }),
    input: (styles: any) => ({
      ...styles,
      borderWidth: 1,
      fontSize: 16,
      color: colors.black,
      fontFamily: `${fonts.Roboto}, sans-serif`,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      fontFamily: `${fonts.Roboto}, sans-serif`,
      fontSize: 16,
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      padding: 0,
      borderBottomWidth: 1,
      borderColor: colors.black,
      cursor: 'pointer',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      fontSize: 16,
      color: colors.black,
      fontFamily: `${fonts.Roboto}, sans-serif`,
      cursor: 'pointer',
      '&hover': {
        color: colors.black,
      },
    }),
    dropdownIndicator: () => ({ opacity: 1 }),
    indicatorSeparator: () => ({ opacity: 1 }),
  };

  return (
    <Main style={mainStyle}>
      {label && (
        <TextNormal style={{ color: colors.accent }}>{label}</TextNormal>
      )}

      <Select
        // @ts-ignore
        styles={colourStyles}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        // noOptionsMessage={'No match'}
      />
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border: 2px solid ${colors.borderColor};
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
`;
