export const colors = {
  primary: '#f15d1e',
  secondary: '#fabcac',
  accent: '#179d8e',
  accentPale: '#c3ebe6',
  white: '#FFFFFF',
  backgroundBox: '#fcfcfc',
  background: 'rgb(249,249,251)',
  black: '#333333',
  error: '#ff3b30',
  paleOrange: 'rgb(251, 153, 108)',
  borderColor: '#EFEFEF',
};

export default colors;
