import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import { observer } from 'mobx-react';
import { useUiStore } from '../../stores/ui';
import { TextNormal } from './Typography';
import Button from './Button';
import colors from '../../constants/colors';

const AlertModal = observer(() => {
  const ui = useUiStore();
  const alertOptions = ui.getAlertOptions();

  return (
    <Modal
      show={ui.isAlertShown}
      onHide={ui.closeAlert}
      size={alertOptions.size || 'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {alertOptions.title && (
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <TextNormal style={{ color: colors.black, fontSize: 32 }}>
              {alertOptions.title}
            </TextNormal>
          </Modal.Title>
        </Modal.Header>
      )}
      {alertOptions.text || alertOptions.renderContent ? (
        <Modal.Body>
          {alertOptions.text && <TextNormal>{alertOptions.text}</TextNormal>}
          {alertOptions.renderContent ? alertOptions.renderContent() : null}
        </Modal.Body>
      ) : null}
      {alertOptions.buttons && alertOptions.buttons.length ? (
        <Modal.Footer>
          {alertOptions.buttons.map((button, i) => (
            <Button
              style={{ maxWidth: 150 }}
              key={`${i}-modalbutton`}
              label={button.label}
              onClick={button.onClick}
            />
          ))}
        </Modal.Footer>
      ) : null}
    </Modal>
  );
});

export default AlertModal;
