import * as React from 'react';
import { View } from 'react-native';
import { ViewStyle } from 'react-native';
import colors from '../../constants/colors';
import { Section } from '../user/UserItemCell';

const PromoCodeSectionBar = ({
  style,
  sortedBy,
  onClick,
}: {
  style?: ViewStyle;
  sortedBy?: string;
  onClick: (newSort: string) => void;
}) => {
  return (
    <View
      style={[
        {
          height: 60,
          paddingHorizontal: 10,

          borderWidth: 1,
          borderColor: colors.borderColor,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
          flexDirection: 'row',
          backgroundColor: colors.white,
          marginBottom: 10,
        },

        style,
      ]}
    >
      <Section
        label="Code"
        withHover
        sorted={sortedBy === 'code'}
        onClick={() => onClick('code')}
      />
      <Section
        label="Amount"
        withHover
        sorted={sortedBy === 'amount'}
        onClick={() => onClick('amount')}
      />
      <Section
        label="Company shareloss"
        withHover
        sorted={sortedBy === 'shareloss'}
        onClick={() => onClick('shareloss')}
      />
      <Section
        label="Trainer shareloss"
        withHover
        sorted={sortedBy === 'shareloss'}
        onClick={() => onClick('shareloss')}
      />
      <Section
        label="Redeemed times"
        withHover
        sorted={sortedBy === 'redeemed'}
        onClick={() => onClick('redeemed')}
      />
    </View>
  );
};

export default PromoCodeSectionBar;
