import * as React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';

import HomeScreen from './home/HomeScreen';
import LoginScreen from './login/LoginScreen';
import NotFoundScreen from './404/NotFoundScreen';
import GymListingScreen from './gym/GymListingScreen';
import CreateOrEditGymScreen from './gym/CreateOrEditGymScreen';
import UserListingScreen from './user/UserListingScreen';
import TrainerListingScreen from './trainer/TrainerListingScreen';
import EditUserPanel from './user/EditUserPanel';
import EditTrainerPanel from './trainer/EditTrainerPanel';
import EditDisputePanel from './dispute/EditDisputePanel';
import SystemScreen from './system/SystemScreen';
import CompanyScreen from './company/CompanyScreen';
import DisputeListingScreen from './dispute/DisputeListingScreen';
import PromoCodeListingScreen from './promoCode/PromoCodeListingScreen';
import TransactionListingScreen from './transaction/TransactionListingScreen';
import EditTransactionPanel from './transaction/EditTransactionPanel';
import NotificationsScreen from './Notifications/NotificationsScreen';
import CreatePromoCodeScreen from './promoCode/CreatePromoCodeScreen';
import EditPromoCodeScreen from './promoCode/EditPromoCodeScreen';
import DatabaseScreen from './database/DatabaseScreen';
import SharesScreen from './shares/SharesScreen';
import CommuniqueScreen from './communique/CommuniqueScreen';
import UserConversationListing from './user/UserConversationListing';
import UserConversationScreen from './user/UserConversationScreen';

const routes = [
  <Switch key="switch">
    <Route exact path="/" component={HomeScreen} key="HomeScreen" />,
    <Route path="/login" component={LoginScreen} key="LoginScreen" />,
    <Route
      path="/users"
      component={UserListingScreen}
      key="UserListingScreen"
    />
    ,
    <Route path="/user" component={EditUserPanel} key="EditUserPanel" />,
    <Route
      path="/trainer"
      component={EditTrainerPanel}
      key="EditTrainerPanel"
    />
    ,
    <Route
      path="/dispute"
      component={EditDisputePanel}
      key="EditDisputePanel"
    />
    ,
    <Route
      path="/transaction"
      component={EditTransactionPanel}
      key="EditTransactionPanel"
    />
    ,
    <Route
      path="/fittcoaches"
      component={TrainerListingScreen}
      key="TrainerListingScreen"
    />
    ,
    <Route
      path="/transactions"
      component={TransactionListingScreen}
      key="TransactionListingScreen"
    />
    ,
    <Route
      path="/disputes"
      component={DisputeListingScreen}
      key="DisputeListingScreen"
    />
    ,
    <Route
      path="/notifications"
      component={NotificationsScreen}
      key="NotificationsScreen"
    />
    ,
    <Route
      path="/promocodes"
      component={PromoCodeListingScreen}
      key="PromoCodeListingScreen"
    />
    ,
    <Route
      path="/promoCode"
      component={EditPromoCodeScreen}
      key="EditPromoCodeScreen"
    />
    ,
    <Route
      path="/createPromoCode"
      component={CreatePromoCodeScreen}
      key="CreatePromoCodeScreen"
    />
    ,
    <Route
      path="/conversations"
      component={UserConversationListing}
      key="UserConversationListing"
    />
    ,
    <Route
      path="/conversation"
      component={UserConversationScreen}
      key="UserConversationScreen"
    />
    ,
    <Route path="/gyms" component={GymListingScreen} key="GymListingScreen" />,
    <Route
      path="/createGym"
      component={CreateOrEditGymScreen}
      key="CreateOrEditGymScreen"
    />
    ,
    <Route path="/companies" component={CompanyScreen} key="CompanyScreen" />,
    <Route path="/gym" component={CreateOrEditGymScreen} key="EditGymScreen" />,
    <Route path="/systems" component={SystemScreen} key="SystemScreen" />
    ,
    <Route path="/database" component={DatabaseScreen} key="DatabaseScreen" />,
    <Route path="/shares" component={SharesScreen} key="SharesScreen" />,
    <Route
      path="/communique"
      component={CommuniqueScreen}
      key="CommuniqueScreen"
    />
    ,
    <Route path="*" component={NotFoundScreen} key="Notfound404" />
  </Switch>,
];

export default routes;
