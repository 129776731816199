import * as React from 'react';
import { Box } from 'react-native-kondo';
import { observer, inject } from 'mobx-react';
import { Routing, DisputeDocument } from '../../types';
import { UIStoreType } from '../../stores/ui';

import FittTextInput from '../common/FittTextInput';
import FittTextArea from '../common/FittTextArea';

import Button from '../common/Button';
import { formatError } from '../../utils/text';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittSelect from '../common/FittSelect';
import FittTitle from '../common/FittTitle';
import FittBackButton from '../common/FittBackButton';
import { Link } from 'react-router-dom';
import { AdminStore } from '../../stores/admin';
import { DisputeStore } from '../../stores/dispute';
import { TextNormalBold } from '../common/Typography';
import UserInformationLayout from './UserInformationLayout';
import colors from '../../constants/colors';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  admin?: AdminStore;
  dispute?: DisputeStore;
}

function getInitalState(dispute: DisputeDocument) {
  return {
    isResolved: dispute.isResolved
      ? { value: true, label: 'Resolved' }
      : { value: false, label: 'Unresolved' },
    adminNotes: dispute.adminNotes,
  };
}

interface S {
  initialLoading: boolean;
  saveLoading: boolean;
  isResolved: { value: ''; label: '' } | any;
  adminNotes: string;
}

@inject('ui', 'routing', 'dispute')
@observer
export default class EditDisputePanel extends React.Component<P, S> {
  state = {
    initialLoading: true,
    saveLoading: false,
    isResolved: { value: false, label: 'Unresolved' },
    adminNotes: '',
  };

  componentDidMount() {
    this._fetchDispute();
  }

  _fetchDispute = async () => {
    try {
      this.setState({ initialLoading: true });
      const urlString = window.location.href;
      const url = new URL(urlString);
      const disputeId = url.searchParams.get('id');

      if (disputeId) {
        await this.props.dispute!.fetchDispute({ disputeId });

        this.setState({
          ...getInitalState(this.props.dispute!.getCurrentDispute()!),
        });
      }
    } catch (e) {
      console.log(e);
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ initialLoading: false });
    }
  };

  _onClickSave = async (action: string) => {
    try {
      if (action === 'save') {
        this.setState({ saveLoading: true });
      }
      const { isResolved, adminNotes } = this.state;
      await this.props.dispute!.editDispute({
        isResolved: isResolved.value,
        adminNotes: adminNotes,
        disputeId: this.props.dispute!.getCurrentDispute()!.id,
      });
      if (action === 'save') {
        this.props.ui!.openToaster({
          text: 'Dispute saved!',
        });
      }
    } catch (e) {
      this.props.ui!.openToaster({
        text: formatError(e),
        type: 'error',
      });
    } finally {
      this.setState({ saveLoading: false });
    }
  };

  render() {
    if (this.state.initialLoading) {
      return <FittLoadingScreen />;
    }

    const dispute = this.props.dispute!.getCurrentDispute()!;

    return (
      <Box mb={10} p={45} flex={1}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={20}
        >
          <FittBackButton
            onClick={() => this.props.routing!.push('/disputes')}
            label="disputes"
          />

          <FittTitle title={'Dispute #' + dispute!.id} />

          <Box>
            <Button
              label="Save"
              onClick={() => this._onClickSave('save')}
              loading={this.state.saveLoading}
              style={{
                width: 240,
                marginBottom: dispute.transaction ? 30 : 0,
              }}
            />
            {dispute.transaction ? (
              <Link
                to={`/transaction?id=${dispute!.transaction}`}
                style={{ textDecoration: 'none', marginBottom: 15 }}
              >
                <Button
                  label="See related transaction"
                  style={{ width: 240, backgroundColor: colors.accent }}
                />
              </Link>
            ) : null}
          </Box>
        </Box>

        <Box style={{ marginBottom: 30 }}>
          <TextNormalBold style={{ marginBottom: 15 }}>
            Dispute information
          </TextNormalBold>

          <Box flexDirection="row">
            <Box flex={1} mr={40}>
              <FittTextInput
                label="The trainer showed up"
                placeholder="The trainer showed up."
                value={dispute!.trainerDidShow ? 'Yes' : 'No'}
                style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
                inputStyle={{ width: '100%', height: 30 }}
                disabled
              />

              <FittTextInput
                label="The trainer stayed the entire time"
                placeholder="The trainer stayed the entire time"
                value={dispute!.trainerStayedEntireSession ? 'Yes' : 'No'}
                style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
                inputStyle={{ width: '100%', height: 30 }}
                disabled
              />

              <FittTextArea
                label="User's message"
                placeholder="User's message"
                value={dispute!.message}
                style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
                inputStyle={{ width: '100%', height: 30 }}
                disabled
              />
              <Box style={{ width: 150 }}></Box>
            </Box>
            <Box flex={1}>
              <FittSelect
                style={{ marginTop: 29, marginRight: 10, marginBottom: 15 }}
                isSearchable={false}
                value={this.state.isResolved}
                onChange={(isResolved: any) => this.setState({ isResolved })}
                options={[
                  { value: false, label: 'Unresolved' },
                  { value: true, label: 'Resolved' },
                ]}
              />
              <FittTextArea
                label="Admin notes"
                placeholder="This can only be seen by admins."
                value={this.state.adminNotes}
                onChangeText={adminNotes => this.setState({ adminNotes })}
                style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
                inputStyle={{ width: '100%', height: 30 }}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ marginBottom: 30 }}>
          <TextNormalBold style={{ marginBottom: 15 }}>
            Customer information
          </TextNormalBold>

          <UserInformationLayout user={dispute!.user} />
        </Box>

        <Box>
          <TextNormalBold style={{ marginBottom: 15 }}>
            Trainer information
          </TextNormalBold>

          <UserInformationLayout user={dispute!.trainer.user} />
        </Box>
        <Box
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
          mb={20}
          mt={150}
        ></Box>
      </Box>
    );
  }
}
