import { Button, Menu, MenuItem, withStyles } from '@material-ui/core';
import * as React from 'react';
import colors from '../../constants/colors';
import { fonts } from './Typography';
import { Actions } from './AllTable';
import { useUiStore } from '../../stores/ui';

const MUIMenu = ({
  children,
  choices = [],
  onClose,
  disabled,
  withWarning,
}: {
  children?: React.ReactNode;
  choices?: Actions['choices'];
  onClose?: (choice: string) => void;
  disabled?: boolean;
  withWarning?: boolean;
}) => {
  const uiStore = useUiStore();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (choice: string) => {
    setAnchorEl(null);

    if (onClose && typeof choice === 'string' && withWarning) {
      uiStore.openAlert({
        title: 'Warning',
        text:
          'This action will be applied to all the selected item(s). Are you sure you want to proceed ?',
        buttons: [
          {
            label: 'Cancel',
            onClick: () => {
              uiStore.closeAlert();
            },
          },
          {
            label: 'Yes',
            onClick: () => {
              uiStore.closeAlert();
              onClose(choice);
            },
          },
        ],
      });
    } else if (onClose && typeof choice === 'string') {
      onClose(choice);
    }
  };
  return (
    <>
      <StyledButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={disabled ? undefined : handleClick}
        style={{
          fontFamily: fonts.Roboto,
          height: 40,
          margin: 0,
          padding: 0,
        }}
        disableRipple={disabled}
      >
        {children}
      </StyledButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ marginTop: 6 }}
      >
        {choices.map(choice => (
          <StyledItem key={choice} onClick={() => handleClose(choice)}>
            {choice}
          </StyledItem>
        ))}
      </Menu>
    </>
  );
};

const StyledButton = withStyles({
  root: {
    height: 40,
    padding: '0',
    margin: '0',
  },
  label: {
    fontSize: 14,
    textTransform: 'none',
  },
})(Button);

const StyledItem = withStyles({
  root: {
    fontSize: 14,
    fontFamily: fonts.Roboto,
    fontWeight: 600,
    color: '#9fa2b4',
    // @ts-ignore
    '&:hover': {
      backgroundColor: colors.accent,
      color: colors.white,
    },
  },
})(MenuItem);

export default MUIMenu;
