import createBrowserHistory from 'history/createBrowserHistory';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { MobXProviderContext } from 'mobx-react';
import * as React from 'react';

import ui from './ui';
import user from './user';
import gym from './gym';
import system from './system';
import trainer from './trainer';
import admin from './admin';
import company from './company';
import dispute from './dispute';
import transaction from './transaction';
import promoCode from './promoCode';

export const routingStore = new RouterStore();
const browserHistory = createBrowserHistory();

export const history = syncHistoryWithStore(browserHistory, routingStore);

export default {
  ui,
  routing: routingStore,
  user,
  gym,
  system,
  trainer,
  admin,
  company,
  dispute,
  transaction,
  promoCode,
};

export function useStores() {
  return React.useContext(MobXProviderContext);
}

export function useRoutingStore(): typeof routingStore {
  const { routing } = useStores();
  return routing as typeof routingStore;
}
