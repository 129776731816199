import * as React from 'react';
import { Box } from 'react-native-kondo';
import FittBox from '../common/FittBox';
import Button from '../common/Button';
import FittTextInput from '../common/FittTextInput';
import { TextTitle } from '../common/Typography';
import { inject, observer } from 'mobx-react';
import { UIStoreType } from '../../stores/ui';
import { formatError } from '../../utils/text';
import { UserStore } from '../../stores/user';
import colors from '../../constants/colors';

interface P {
  ui?: UIStoreType;
  user?: UserStore;
}

@inject('ui', 'routing', 'user')
@observer
export default class LoginScreen extends React.Component<P> {
  state = {
    password: '',
    email: '',
    loading: false,
  };

  _login = async () => {
    try {
      this.setState({ loading: true });
      await this.props.user!.login({
        email: this.state.email,
        password: this.state.password,
        systemOrigin: 'console',
      });
    } catch (e) {
      this.setState({ loading: false });
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    }
  };

  render() {
    const { isMobile } = this.props.ui!;
    return (
      <Box flex={1} alignItems="center" justifyContent="center">
        <FittBox
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '25%',
            width: isMobile ? '100%' : undefined,
          }}
        >
          <Box
            mb={30}
            flexDirection="row"
            style={{
              borderRadius: 10,
              padding: 10,
              // @ts-ignore
              'box-shadow': '1px -1px 2px #FFFFFF, inset -2px 2px 6px #D9D9D9',
            }}
          >
            <TextTitle
              style={{ fontSize: 32, color: colors.accent, marginRight: 8 }}
            >
              Fittcoach
            </TextTitle>
            <TextTitle style={{ fontSize: 32, color: colors.primary }}>
              Admin
            </TextTitle>
          </Box>
          <Box
            flexDirection="row"
            alignItems="center"
            mb={10}
            justifyContent="space-between"
            style={{ width: isMobile ? '100%' : 400 }}
          >
            <FittTextInput
              label="Email"
              value={this.state.email}
              onChangeText={email => this.setState({ email })}
              style={{ width: isMobile ? '100%' : 400 }}
              inputStyle={{ width: '100%' }}
              placeholder="your.email@example.com"
            />
          </Box>
          <Box
            flexDirection="row"
            alignItems="center"
            mb={25}
            justifyContent="space-between"
            style={{ width: isMobile ? '100%' : 400 }}
          >
            <FittTextInput
              label="Password"
              type="password"
              style={{ width: isMobile ? '100%' : 400 }}
              inputStyle={{ width: '100%' }}
              value={this.state.password}
              onChangeText={password => this.setState({ password })}
              placeholder="your secret password"
            />
          </Box>
          <Button
            onClick={this._login}
            loading={this.state.loading}
            label="Login"
            disabled={!this.state.email.length || !this.state.password.length}
            style={{ width: isMobile ? '100%' : 400 }}
          />
        </FittBox>
      </Box>
    );
  }
}
