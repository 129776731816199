import * as React from 'react';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import { TextTitle } from './Typography';
import { ViewStyle } from 'react-native';

const FittTitle = ({
  title,
  style,
  textStyle,
}: {
  title: string;
  style?: ViewStyle;
  textStyle?: ViewStyle;
}) => (
  <Box
    flexDirection="row"
    alignItems="center"
    style={[
      {
        borderRadius: 10,
        padding: 10,
        // @ts-ignore
        'box-shadow': '1px -1px 2px #FFFFFF, inset -2px 2px 6px #D9D9D9',
      },
      style,
    ]}
  >
    <TextTitle style={[{ color: colors.primary }, textStyle]}>
      {title}
    </TextTitle>
  </Box>
);

export default FittTitle;
