import * as React from 'react';
import { Box } from 'react-native-kondo';
import { observer, inject } from 'mobx-react';
import {
  Routing,
  GymDocument,
  OpeningHours,
  CompanyDocument,
} from '../../types';
import { UIStoreType } from '../../stores/ui';
import { TextNormal, TextSmall } from '../common/Typography';
import { setHours, setMinutes } from 'date-fns';

import { GymStore } from '../../stores/gym';

import FittTextInput from '../common/FittTextInput';
import ImagePicker from '../common/ImagePicker';
import Button from '../common/Button';
import colors from '../../constants/colors';
import { formatError } from '../../utils/text';
import FittBooleanInput from '../common/FittBooleanInput';
import FittSelect from '../common/FittSelect';
import { cloneDeep } from 'lodash';
import { CompanyStore } from '../../stores/company';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  gym?: GymStore;
  company?: CompanyStore;
  getRef?: (ref: EditGymPanel) => void;
}

function getInitalState(gym: GymDocument) {
  return {
    name: gym.name,
    openingHours: gym.openingHours,
    amenities: gym.amenities,
    city: gym.location.city,
    country: gym.location.country,
    zipCode: gym.location.zip,
    address: gym.location.address1,
    pictures: [...gym.pictures],
    visibility: gym.visibility,
    phone: gym.phone,
    manager: gym.manager,
    districtManager: gym.districtManager,
    company: gym.company,
    lat: gym.location.lat,
    lng: gym.location.lng,
  };
}

const timeOptions = [
  '0:00',
  '0:30',
  '1:00',
  '1:30',
  '2:00',
  '2:30',
  '3:00',
  '3:30',
  '4:00',
  '4:30',
  '5:00',
  '5:30',
  '6:00',
  '6:30',
  '7:00',
  '7:30',
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

interface S {
  deleteLoading: boolean;
  name: string;
  city: string;
  country: string;
  lng: string;
  lat: string;
  address: string;
  zipCode: string;
  openingHours?: OpeningHours;
  openingHoursLoading: boolean;
  amenities: string[];
  pictures: string[];
  manager: string;
  districtManager: string;
  phone: string;
  company: CompanyDocument;
  visibility: 'all' | 'admin' | 'trainer';
  currentAmenity?: string;
}

@inject('ui', 'routing', 'gym', 'company')
@observer
export default class EditGymPanel extends React.Component<P, S> {
  state = {
    deleteLoading: false,
    openingHoursLoading: false,
    currentAmenity: '',
    ...getInitalState(this.props.gym!.getCurrentGym()!),
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.getRef) {
        this.props.getRef(this);
      }
    }, 1000);
  }

  _handlePicture = (url: string, index: number) => {
    const pictures = [...this.state.pictures];
    // @ts-ignore
    pictures[index] = url;
    this.setState({ pictures });
  };

  getState = () => {
    return { ...this.state };
  };

  _deleteGym = () => {
    this.props.ui!.openToaster({
      text: 'Are you sure you want to delete this gym?',
      buttons: [
        {
          text: 'yes',
          onClick: async () => {
            const currentGym = this.props.gym!.getCurrentGym()!;
            try {
              this.setState({ deleteLoading: true });
              await this.props.gym!.deleteGym({
                gymId: currentGym.id,
              });
              this.props.routing!.push('/gyms');
            } catch (e) {
              this.setState({ deleteLoading: false });
              this.props.ui!.openToaster({
                type: 'error',
                text: formatError(e),
              });
            }
          },
        },
      ],
    });
  };

  _handleOpeningHoursChange = ({
    newTime,
    index,
    openOrClose,
  }: {
    newTime: { label: string; value: string };
    index: number;
    openOrClose: 'open' | 'close';
  }) => {
    console.log(openOrClose);
    const splitTime = newTime.value.split(':');
    const newHours = splitTime[0];
    const newMinutes = splitTime[1];
    let res = new Date();
    res = setHours(res, Number(newHours));
    res = setMinutes(res, Number(newMinutes));

    this.setState(
      (prevState: S) => {
        const openingHours = cloneDeep(prevState.openingHours);
        openingHours!.edited = true;
        if (openOrClose === 'open') {
          openingHours!.days[index].openingTime = String(res);
        } else {
          openingHours!.days[index].closingTime = String(res);
        }
        return { openingHours };
      },
      () => console.log(this.state.openingHours),
    );
  };

  _handleOpenOrClosedChange = ({ index }: { index: number }) => {
    let res = new Date();
    res = setHours(res, 5);
    res = setMinutes(res, 0);
    this.setState((prevState: S) => {
      const openingHours = cloneDeep(prevState.openingHours);
      openingHours!.edited = true;
      if (!openingHours!.days[index].open) {
        openingHours!.days[index].openingTime = String(res);
        openingHours!.days[index].closingTime = String(res);
      }
      openingHours!.days[index].open = !openingHours!.days[index].open;
      return { openingHours };
    });
  };

  _formatNumberToDayName = (index: number) => {
    switch (index) {
      case 0:
        return 'Monday';
      case 1:
        return 'Tuesday';
      case 2:
        return 'Wednesday';
      case 3:
        return 'Thursday';
      case 4:
        return 'Friday';
      case 5:
        return 'Saturday';
      case 6:
        return 'Sunday';
    }
  };

  _formatMinutes = (minutes: number) => {
    if (minutes === 0) {
      return '00';
    }
    return minutes;
  };

  _renderOpeningHours = () => {
    const openingHours = this.state.openingHours;
    return openingHours!.days.map((currentDay, index) => {
      return (
        <Box
          flexDirection="row"
          flex={1}
          alignItems="center"
          key={index}
          style={{ zIndex: 1000 - index }}
        >
          <TextNormal style={{ marginRight: 50, width: 100 }}>
            {this._formatNumberToDayName(index)}
          </TextNormal>
          <FittBooleanInput
            // label="Visible by everyone"
            style={{ marginHorizontal: 20 }}
            value={openingHours!.days[index].open}
            onChange={() => this._handleOpenOrClosedChange({ index })}
          />
          <TextNormal>Open : </TextNormal>
          <FittSelect
            value={`${new Date(
              openingHours!.days[index].openingTime,
            ).getHours()}:${this._formatMinutes(
              new Date(openingHours!.days[index].openingTime).getMinutes(),
            )}`}
            onChange={(newTime: { label: string; value: string }) =>
              this._handleOpeningHoursChange({
                newTime,
                index,
                openOrClose: 'open',
              })
            }
            options={timeOptions.map(option => {
              return { label: option, value: option };
            })}
            isSearchable={false}
            placeholder={
              openingHours!.days[index].open
                ? `${new Date(
                    openingHours!.days[index].openingTime,
                  ).getHours()}:${this._formatMinutes(
                    new Date(
                      openingHours!.days[index].closingTime,
                    ).getMinutes(),
                  )}`
                : 'closed'
            }
            style={{ width: 200, zIndex: 1000 }}
            isDisabled={!openingHours!.days[index].open}
          />
          <TextNormal style={{ marginLeft: 20 }}>Close : </TextNormal>
          <FittSelect
            value={`${new Date(
              openingHours!.days[index].closingTime,
            ).getHours()}:${this._formatMinutes(
              new Date(openingHours!.days[index].closingTime).getMinutes(),
            )}`}
            onChange={(newTime: { label: string; value: string }) =>
              this._handleOpeningHoursChange({
                newTime,
                index,
                openOrClose: 'close',
              })
            }
            options={timeOptions.map(option => {
              return { label: option, value: option };
            })}
            isSearchable={false}
            placeholder={
              openingHours!.days[index].open
                ? `${new Date(
                    openingHours!.days[index].closingTime,
                  ).getHours()}:${this._formatMinutes(
                    new Date(
                      openingHours!.days[index].closingTime,
                    ).getMinutes(),
                  )}`
                : 'closed'
            }
            style={{ width: 200 }}
            isDisabled={!openingHours!.days[index].open}
          />
        </Box>
      );
    });
  };

  _prepOpeningHoursForRender = () => {
    const openingHours = this.state.openingHours;

    // if openingHours doesn't exist, create an object with default values
    if (!openingHours) {
      let time = new Date();
      time = setHours(time, 0);
      time = setMinutes(time, 0);

      const newOpeningHours = {
        edited: true,
        days: Array.from({ length: 7 }, () => ({
          open: true,
          openingTime: String(time),
          closingTime: String(time),
        })),
      };
      // _renderOpeningHours was separated from this function for this purpose :
      // that code needs to run after this setState and this was the only way to make that happen
      this.setState(
        { openingHours: newOpeningHours },
        () => this._renderOpeningHours,
      );
    } else {
      return this._renderOpeningHours();
    }
  };

  _resetGymOpeningHours = () => {
    this.props.ui!.openToaster({
      text: 'Are you sure you want to reset the opening hours for this gym?',
      buttons: [
        {
          text: 'yes',
          onClick: async () => {
            const currentGym = this.props.gym!.getCurrentGym()!;
            try {
              this.setState({ openingHoursLoading: true });
              await this.props.gym!.resetGymOpeningHours({
                gymId: currentGym.id,
              });
              await this.props.gym!.fetchGym({
                gymId: currentGym.id,
              });
              this.setState({
                deleteLoading: false,
                openingHoursLoading: false,
                ...getInitalState(this.props.gym!.getCurrentGym()!),
              });
              this.props.ui!.closeToaster();
            } catch (e) {
              this.setState({ openingHoursLoading: false });
              this.props.ui!.openToaster({
                type: 'error',
                text: formatError(e),
              });
            }
          },
        },
      ],
    });
  };

  _renderVisibility = () => {
    return (
      <Box style={{ maxWidth: 300 }}>
        <TextNormal style={{ marginBottom: 10, fontSize: 17 }}>
          Visibility
        </TextNormal>
        <FittBooleanInput
          label="Visible by everyone"
          style={{ marginBottom: 10 }}
          value={this.state.visibility === 'all'}
          onChange={() => this.setState({ visibility: 'all' })}
        />
        <FittBooleanInput
          label="Visible only by trainers"
          style={{ marginBottom: 10 }}
          value={this.state.visibility === 'trainer'}
          onChange={() => this.setState({ visibility: 'trainer' })}
        />
        <FittBooleanInput
          label="Visible only by admins"
          value={this.state.visibility === 'admin'}
          onChange={() => this.setState({ visibility: 'admin' })}
        />
      </Box>
    );
  };

  _renderTextInputs = () => {
    const companies = this.props.company!.getCompanies();

    return (
      <Box flexDirection="row">
        <Box flex={1} mr={40}>
          <FittTextInput
            label="Name"
            placeholder="gym name"
            value={this.state.name}
            onChangeText={name => this.setState({ name })}
            style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
            inputStyle={{ width: '100%', height: 30 }}
          />
          <FittTextInput
            label="Country"
            placeholder="gym country"
            value={this.state.country}
            onChangeText={country => this.setState({ country })}
            style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
            inputStyle={{ width: '100%', height: 30 }}
          />
          <FittTextInput
            label="City"
            placeholder="gym city"
            value={this.state.city}
            onChangeText={city => this.setState({ city })}
            style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
            inputStyle={{ width: '100%', height: 30 }}
          />
          <FittTextInput
            label="Address"
            placeholder="gym address"
            value={this.state.address}
            onChangeText={address => this.setState({ address })}
            style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
            inputStyle={{ width: '100%', height: 30 }}
          />
          <FittTextInput
            label="Postal code"
            placeholder="gym postal code"
            value={this.state.zipCode}
            onChangeText={zipCode => this.setState({ zipCode })}
            style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
            inputStyle={{ width: '100%', height: 30 }}
          />
        </Box>
        <Box flex={1}>
          <FittTextInput
            label="Phone number"
            placeholder="gym phone number"
            value={this.state.phone}
            onChangeText={phone => this.setState({ phone })}
            style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
            inputStyle={{ width: '100%', height: 30 }}
          />
          {/* <FittTextInput
            label="Company name"
            placeholder="company name"
            value={this.state.company.name}
            // onChangeText={company => this.setState({ company })}
            style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
            inputStyle={{ width: '100%', height: 30 }}
          /> */}
          <FittSelect
            style={{ marginBottom: 15 }}
            label="Company name"
            onChange={(values: any) =>
              this.setState({
                // @ts-ignore
                company: { name: values.label, id: values.value },
              })
            }
            value={
              this.state.company
                ? {
                    label: this.state.company.name,
                    value: this.state.company.id,
                  }
                : undefined
            }
            options={companies.map(c => ({ label: c.name, value: c.id }))}
          />
          <FittTextInput
            label="Manager"
            placeholder="gym manager name"
            value={this.state.manager}
            onChangeText={manager => this.setState({ manager })}
            style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
            inputStyle={{ width: '100%', height: 30 }}
          />
          <FittTextInput
            label="District manager"
            placeholder="district manager"
            value={this.state.districtManager}
            onChangeText={districtManager => this.setState({ districtManager })}
            style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
            inputStyle={{ width: '100%', height: 30 }}
          />
          {this._renderVisibility()}
        </Box>
      </Box>
    );
  };

  _renderPictures = () => {
    return (
      <Box>
        <TextNormal style={{ fontSize: 17, marginBottom: 15 }}>
          Pictures
        </TextNormal>
        <Box flexDirection="row">
          {Array.from({ length: 5 }, (x, i) => i).map(i => (
            <ImagePicker
              key={`pic-${i}`}
              onChangePicture={url => this._handlePicture(url, i)}
              style={{ marginRight: 10 }}
            />
          ))}
        </Box>
      </Box>
    );
  };

  _renderGeolocations = () => {
    return (
      <Box>
        <TextNormal style={{ marginBottom: 10, fontSize: 17, marginTop: 30 }}>
          Geo location
        </TextNormal>
        <TextSmall style={{ marginBottom: 10 }}>
          Only edit this if you know what you are doing
        </TextSmall>
        <FittTextInput
          label="Latitude"
          placeholder="gym latitude coordinate"
          value={this.state.lat}
          onChangeText={lat => this.setState({ lat })}
          style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
          inputStyle={{ width: '100%', height: 30 }}
        />
        <FittTextInput
          label="Longitude"
          placeholder="gym longitude coordinate"
          value={this.state.lng}
          onChangeText={lng => this.setState({ lng })}
          style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
          inputStyle={{ width: '100%', height: 30 }}
        />
      </Box>
    );
  };

  _renderOpeningHoursSection = () => {
    return (
      <Box>
        <TextNormal style={{ marginBottom: 10, fontSize: 17, marginTop: 30 }}>
          Opening Hours
        </TextNormal>
        {this._prepOpeningHoursForRender()}
        <Box flexDirection="row" flex={1} alignItems="center" mt={20}>
          <TextNormal>
            Fetch opening hours from Google and replace current ones :
          </TextNormal>
          <Button
            loading={this.state.openingHoursLoading}
            onClick={this._resetGymOpeningHours}
            label="Reset opening hours"
            style={{
              backgroundColor: colors.primary,
              width: 150,
              marginLeft: 10,
            }}
          />
        </Box>
      </Box>
    );
  };

  _renderAmenities = () => {
    const { amenities } = this.state;
    return (
      <Box style={{ zIndex: -100 }}>
        <TextNormal style={{ marginBottom: 10, fontSize: 17, marginTop: 30 }}>
          Amenities
        </TextNormal>
        <Box flexDirection="row" alignItems="center">
          <FittTextInput
            placeholder="Add amenities"
            value={this.state.currentAmenity}
            onChangeText={currentAmenity => this.setState({ currentAmenity })}
          />
          <Button
            label="Add"
            style={{ marginLeft: 10 }}
            onClick={() => {
              const newAmenities = [...amenities];
              newAmenities.push(this.state.currentAmenity);
              this.setState({ amenities: newAmenities, currentAmenity: '' });
            }}
          />
        </Box>
        <Box
          flexDirection="row"
          flex={1}
          flexWrap="wrap"
          mt={20}
          alignItems="center"
        >
          {amenities.map((amenity: string, index: number) => {
            return (
              <Button
                key={`${amenity}-${index}`}
                inverted
                displayClose
                label={`${amenity}`}
                style={{ marginRight: 10 }}
                onClick={() => {
                  const newAmenities = [...amenities];
                  const index = newAmenities.findIndex(s => s === amenity);
                  newAmenities.splice(index, 1);
                  this.setState({ amenities: newAmenities });
                }}
              />
            );
          })}
        </Box>
      </Box>
    );
  };

  _renderDeleteGym = () => {
    return (
      <Box mt={150} alignItems="flex-end">
        <Button
          loading={this.state.deleteLoading}
          onClick={this._deleteGym}
          label="Delete Gym"
          style={{
            backgroundColor: colors.error,
            width: 150,
          }}
        />
      </Box>
    );
  };

  render() {
    return (
      <Box mb={10}>
        {this._renderTextInputs()}
        {this._renderPictures()}
        {this._renderGeolocations()}
        {this._renderOpeningHoursSection()}
        {this._renderAmenities()}
        {this._renderDeleteGym()}
      </Box>
    );
  }
}
