import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import config from '../../config';
import metrics from '../../constants/metrics';
import { useRoutingStore } from '../../stores';
import { useUiStore } from '../../stores/ui';
import Button from '../common/Button';
import { TextNormal } from '../common/Typography';

const DatabaseScreen = observer(() => {
  const uiStore = useUiStore();
  const router = useRoutingStore();

  return (
    <Main>
      <Button
        style={{ marginTop: 50, marginLeft: 50, maxWidth: 400 }}
        label="Go to Database"
        onClick={() => window.open(config.databaseDashboardURL, '_blank')}
      />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export default DatabaseScreen;
