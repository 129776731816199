import * as React from 'react';
import { TextNormal, TextNormalBold } from '../common/Typography';

import { GymDocument, Routing, UserDocument } from '../../types';
import { Box } from 'react-native-kondo';
import FittBackButton from '../common/FittBackButton';
import FittTitle from '../common/FittTitle';
import { inject } from 'mobx-react';
import FittTextInput from '../common/FittTextInput';
import FittBooleanInput from '../common/FittBooleanInput';
import FittSelect from '../common/FittSelect';
import FittLoadingScreen from '../common/FittLoadingScreen';

import UserSectionBar from '../user/UserSectionBar';
import UserItemCell from '../user/UserItemCell';
import { UserStore } from '../../stores/user';
import debounce from 'lodash.debounce';
import { UIStoreType } from '../../stores/ui';
import { formatError } from '../../utils/text';
import SelectedUserItemCell from './SelectedUserItemCell';
import { GymStore } from '../../stores/gym';
import { CompanyStore } from '../../stores/company';
import { PromoCodeStore } from '../../stores/promoCode';
import GymItemCell from '../gym/GymItemCell';
import SelectedGymItemCell from './SelectedGymItemCell';
import Button from '../common/Button';
import ids from 'short-id';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  user?: UserStore;
  gym?: GymStore;
  company?: CompanyStore;
  promoCode?: PromoCodeStore;
}

interface S {
  selectedUsers?: UserDocument[];
}

@inject('ui', 'routing', 'user', 'gym', 'company', 'promoCode')
export default class CreatePromoCodeScreen extends React.Component<P> {
  state = {
    code: '',
    autoGenerateCode: false,

    discountType: { value: 'absolute', label: 'Absolute ($)' },
    discountAmount: '',
    maxRedeem: '1',
    minimumCost: '2',
    serviceType: { value: 'all', label: 'All' },

    companyShare: '',
    trainerShare: '',

    targettedCustomers: undefined as any,
    fetchUsersLoading: false,
    searchTerm: '',
    sortedBy: 'createdAt',
    page: { label: 'Page 1', value: 1 },

    targettedGyms: undefined as any,
    fetchGymsLoading: false,
    city: { label: 'any', value: 'any' },
    gymSearchTerm: '',

    selectedUsers: [] as UserDocument[],
    selectedGyms: [] as GymDocument[],

    sendPromoCodeLoading: false,
  };

  _onPressCreatePromoCode = () => {
    const ui = this.props.ui!;

    const {
      code,
      maxRedeem,
      discountType,
      discountAmount,
      companyShare,
      trainerShare,

      selectedGyms,
      selectedUsers,
      targettedGyms,
      targettedCustomers,
      minimumCost,
      serviceType,
    } = this.state;

    const selectedGymIds = selectedGyms.map(gym => gym.id);
    const selectedUserIds = selectedUsers.map(user => user.id);

    ui.openAlert({
      title: 'Warning',
      text: `You are about to send a this promo code to the selected users. Are you sure you want to continue?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await this.props.promoCode!.createPromoCode({
                code,
                maxRedeem: parseInt(maxRedeem),
                discountType: discountType.value,
                discountAmount:
                  discountType.value === 'absolute'
                    ? parseInt(discountAmount)
                    : parseFloat(discountAmount) / 100,
                companyShare: parseFloat(companyShare) / 100,
                trainerShare: parseFloat(trainerShare) / 100,
                selectedGymIds,
                selectedUserIds,
                gymRule: targettedGyms ? targettedGyms.value : undefined,
                userRule: targettedCustomers
                  ? targettedCustomers.value
                  : undefined,
                minimumCost: parseInt(minimumCost),
                serviceType: serviceType.value,
              });
              this.props.routing!.push('/promocodes');
              this.props.ui!.openToaster({
                text: 'Successfully created the new promo code.',
              });
            } catch (e) {
              this.props.ui!.openToaster({
                text: formatError(e),
                type: 'error',
              });
            }
            ui.closeAlert();
          },
        },
        {
          label: 'No',
          onClick: ui.closeAlert,
        },
      ],
    });
  };

  _fetchUsers = async () => {
    try {
      const { searchTerm, page, sortedBy } = this.state;
      this.setState({ fetchUsersLoading: true });
      await this.props.user!.fetchUsers({
        searchTerm,
        page: page.value,
        sort: sortedBy,
      });
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ fetchUsersLoading: false });
    }
  };

  _onChangeUserSearchTerm = (searchTerm: string) => {
    this.setState(
      {
        searchTerm,
        fetchUsersLoading: true,
        page: { label: 'Page 1', value: 1 },
      },
      this._debouncedFetchUsers,
    );
  };

  _debouncedFetchUsers = debounce(async () => {
    await this._fetchUsers();
  }, 500);

  _onChangeSorting = (sortedBy: string) => {
    this.setState(
      { sortedBy, fetchUsersLoading: true },
      this._debouncedFetchUsers,
    );
  };

  _fetchGyms = async () => {
    try {
      const { gymSearchTerm, city } = this.state;
      this.setState({ fetchGymsLoading: true });
      await this.props.gym!.fetchGyms({
        searchTerm: gymSearchTerm,
        city: city.value !== 'any' ? city.value : undefined,
      });
      await this.props.company!.fetchCompanies();
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ fetchGymsLoading: false });
    }
  };

  _onChangeGymSearchTerm = (gymSearchTerm: string) => {
    this.setState(
      { gymSearchTerm, fetchGymsLoading: true },
      this._debouncedFetchGyms,
    );
  };

  _debouncedFetchGyms = debounce(async () => {
    await this._fetchGyms();
  }, 500);

  _onChangeAutoGenerateCode = () => {
    const { autoGenerateCode } = this.state;
    if (autoGenerateCode) {
      this.setState({
        code: '',
        autoGenerateCode: !autoGenerateCode,
      });
    } else {
      this.setState({
        code: ids.generate(),
        autoGenerateCode: !autoGenerateCode,
      });
    }
  };

  _addToSelectedUsers = ({ user }: { user: UserDocument }) => {
    if (!this.state.selectedUsers.includes(user)) {
      const newSelectedUsers = this.state.selectedUsers.slice();
      newSelectedUsers.push(user);
      this.setState({ selectedUsers: newSelectedUsers });
    }
  };

  _removedFromSelectedUsers = ({ user }: { user: UserDocument }) => {
    const newSelectedUsers = this.state.selectedUsers.slice();

    const indexOfItemToRemove = newSelectedUsers.findIndex(
      element => element === user,
    );

    newSelectedUsers.splice(indexOfItemToRemove, 1);
    this.setState({ selectedUsers: newSelectedUsers });
  };

  _addToSelectedGyms = ({ gym }: { gym: GymDocument }) => {
    if (!this.state.selectedGyms.includes(gym)) {
      const newSelectedGyms = this.state.selectedGyms.slice();

      newSelectedGyms.push(gym);
      this.setState({ selectedGyms: newSelectedGyms });
    }
  };

  _removedFromSelectedGyms = ({ gym }: { gym: GymDocument }) => {
    const newSelectedGyms = this.state.selectedGyms.slice();

    const indexOfItemToRemove = newSelectedGyms.findIndex(
      element => element === gym,
    );

    newSelectedGyms.splice(indexOfItemToRemove, 1);
    this.setState({ selectedGyms: newSelectedGyms });
  };

  _renderHeader = () => {
    return (
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={20}
      >
        <FittBackButton
          onClick={() => this.props.routing!.push('/promocodes')}
          label="promocodes"
        />

        <FittTitle title={'Create a new Promo Code'} />

        <Box style={{ width: 114 }}></Box>
      </Box>
    );
  };

  _onChangeDiscountAmount = ({
    discountAmount,
  }: {
    discountAmount: string;
  }) => {
    const { discountType } = this.state;
    let { minimumCost } = this.state;
    const minimumStripeTransactionPrice = parseFloat('0.5');

    const isANumber = /^\d+$/.test(discountAmount);
    if (discountAmount === '') {
      this.setState({
        discountAmount,
        companyShare: '',
        trainerShare: '',
      });
    } else if (discountAmount.charAt(0) === '0') {
      return null;
    } else if (isANumber) {
      if (discountType.value === 'absolute') {
        if (
          parseInt(discountAmount) + 1 > parseInt(minimumCost) ||
          minimumCost === ''
        ) {
          minimumCost = (parseInt(discountAmount) + 1).toString();
        }
      } else if (discountType.value === 'relative') {
        if (parseInt(discountAmount) > 99) {
          discountAmount = '99';
        }
        if (
          minimumStripeTransactionPrice / (1 - parseInt(discountAmount) / 100) >
            parseInt(minimumCost) ||
          minimumCost === ''
        ) {
          minimumCost = Math.ceil(
            minimumStripeTransactionPrice /
              (1 - parseInt(discountAmount) / 100),
          ).toString();
        }
      }

      this.setState({
        discountAmount,
        companyShare: '40',
        trainerShare: '60',
        minimumCost,
      });
    }
  };

  _onChangeMinimumCost = ({ minimumCost }: { minimumCost: string }) => {
    const { discountType, discountAmount } = this.state;

    const minimumStripeTransactionPrice = parseFloat('0.5');

    const isANumber = /^\d*$/.test(minimumCost);

    if (minimumCost.charAt(0) === '0') {
      return null;
    } else if (minimumCost === '') {
      if (discountAmount === '') {
        minimumCost = discountType.value === 'absolute' ? '2' : '1';
      } else {
        if (discountType.value === 'absolute') {
          minimumCost = (parseInt(discountAmount) + 1).toString();
        } else {
          minimumCost = Math.ceil(
            minimumStripeTransactionPrice /
              (1 - parseInt(discountAmount) / 100),
          ).toString();
        }
      }
    } else if (isANumber) {
      if (discountType.value === 'absolute') {
        if (
          parseInt(discountAmount) + 1 > parseInt(minimumCost) ||
          minimumCost === ''
        ) {
          minimumCost = (parseInt(discountAmount) + 1).toString();
        }
      } else if (discountType.value === 'relative') {
        if (
          minimumStripeTransactionPrice / (1 - parseInt(discountAmount) / 100) >
            parseInt(minimumCost) ||
          minimumCost === ''
        ) {
          minimumCost = Math.ceil(
            minimumStripeTransactionPrice /
              (1 - parseInt(discountAmount) / 100),
          ).toString();
        }
      }
    }
    this.setState({
      minimumCost,
    });
  };

  _renderCodeAndType = () => {
    const {
      discountAmount,
      discountType,
      code,
      autoGenerateCode,
      maxRedeem,
      minimumCost,
      serviceType,
    } = this.state;

    const discountUnit =
      discountType.value === 'absolute' ? 'amount' : 'percentage';

    return (
      <Box flexDirection="row" style={{ marginBottom: 30, zIndex: 1000 }}>
        <Box flex={1} mr={40}>
          <FittTextInput
            label="Code"
            placeholder="Enter the promo code characters"
            value={code}
            style={{
              marginRight: 10,
              width: '100%',
              marginBottom: 15,
            }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={code => this.setState({ code })}
            disabled={autoGenerateCode}
          />
          <Box flexDirection="row">
            <Box style={{ flex: 1, marginRight: 30 }}>
              <Box style={{ width: 192 }}>
                <FittBooleanInput
                  label="Auto generate code"
                  onChange={() => this._onChangeAutoGenerateCode()}
                  value={autoGenerateCode}
                />
              </Box>
            </Box>
            <Box flex={1}>
              <FittSelect
                label={'Type of service'}
                isSearchable={true}
                value={serviceType}
                onChange={(serviceType: any) => this.setState({ serviceType })}
                options={[
                  { value: 'all', label: 'All' },
                  { value: '1session', label: '1 Session' },
                  { value: '3session', label: '3 Sessions' },
                  { value: '5session', label: '5 Sessions' },
                  { value: '10session', label: '10 Sessions' },
                  { value: 'workoutPlan', label: 'Workout plan' },
                ]}
                placeholder="Session type"
              />
            </Box>
          </Box>
        </Box>

        <Box flex={1}>
          <Box flex={1} flexDirection="row" mb={15} style={{ zIndex: 2000 }}>
            <Box flex={1} style={{ marginRight: 30 }}>
              <FittSelect
                label={'Discount type'}
                isSearchable={true}
                value={discountType}
                onChange={(discountType: any) =>
                  this.setState({
                    discountType,
                    discountAmount: '',
                    minimumCost: discountType.value === 'absolute' ? '2' : '1',
                  })
                }
                options={[
                  { value: 'absolute', label: 'Absolute ($)' },
                  { value: 'relative', label: 'Relative (%)' },
                ]}
                placeholder="Discount type"
              />
            </Box>

            <Box flex={1}>
              <FittTextInput
                label={`Discount ${discountUnit}`}
                placeholder={`Numbers only`}
                value={discountAmount}
                inputStyle={{ width: '100%', height: 30, minWidth: 230 }}
                onChangeText={discountAmount => {
                  this._onChangeDiscountAmount({ discountAmount });
                }}
              />
            </Box>
          </Box>

          <Box flex={1} flexDirection="row" mb={15} style={{ zIndex: 1000 }}>
            <FittTextInput
              label={`How many times can a customer use this code`}
              placeholder={`Enter a number`}
              value={maxRedeem}
              inputStyle={{
                width: '100%',
                height: 30,
                minWidth: undefined,
              }}
              style={{ flex: 1, marginRight: 30 }}
              onChangeText={maxRedeem => {
                this.setState({ maxRedeem });
              }}
            />
            <Box flex={1}>
              <FittTextInput
                label={'Minimum cost to use this code ($)'}
                placeholder={`Numbers only`}
                value={minimumCost}
                inputStyle={{ width: '100%', height: 30, minWidth: 230 }}
                onChangeText={minimumCost => {
                  this._onChangeMinimumCost({ minimumCost });
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  _onChangeCompanyShare = ({ companyShare }: { companyShare: string }) => {
    const isANumber = /^\d+$/.test(companyShare);

    if (!companyShare) {
      this.setState({
        companyShare,
        trainerShare: '100',
      });
    } else if (isANumber) {
      if (parseInt(companyShare) > 100) {
        companyShare = '100';
      }
      const trainerShare = (100 - parseInt(companyShare)).toString();
      this.setState({
        companyShare,
        trainerShare,
      });
    }
  };

  _onChangeTrainerShare = ({ trainerShare }: { trainerShare: string }) => {
    const isANumber = /^\d+$/.test(trainerShare);

    if (!trainerShare) {
      this.setState({
        trainerShare,
        companyShare: '100',
      });
    } else if (isANumber) {
      if (parseInt(trainerShare) > 100) {
        trainerShare = '100';
      }
      const companyShare = (100 - parseInt(trainerShare)).toString();
      this.setState({
        trainerShare,
        companyShare,
      });
    }
  };

  _renderShares = () => {
    const { discountAmount, companyShare, trainerShare } = this.state;

    return (
      <Box style={{ marginBottom: 30 }}>
        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <TextNormalBold style={{ marginBottom: 15 }}>
              {`Share distribution for the remaining amount post discount `}
            </TextNormalBold>

            <Box flex={1}>
              <Box flexDirection="row">
                <Box style={{ marginRight: 2 }}>
                  <TextNormal style={{ marginTop: 35, fontSize: 17 }}>
                    %
                  </TextNormal>
                </Box>

                <FittTextInput
                  label={'Fittcoach'}
                  placeholder={'Enter the percentage going to Fittcoach'}
                  value={companyShare}
                  inputStyle={{ width: '100%', height: 30 }}
                  style={{ marginBottom: 15, flex: 1 }}
                  onChangeText={companyShare =>
                    this._onChangeCompanyShare({
                      companyShare,
                    })
                  }
                  disabled={!discountAmount}
                />
              </Box>

              <Box flexDirection="row">
                <Box style={{ marginRight: 2 }}>
                  <TextNormal style={{ marginTop: 35, fontSize: 17 }}>
                    %
                  </TextNormal>
                </Box>
                <FittTextInput
                  label={'Trainer'}
                  placeholder={'Enter the percentage going to the trainer'}
                  value={trainerShare}
                  inputStyle={{ width: '100%', height: 30 }}
                  style={{ flex: 1 }}
                  onChangeText={trainerShare =>
                    this._onChangeTrainerShare({ trainerShare })
                  }
                  disabled={!discountAmount}
                />
              </Box>
            </Box>
          </Box>
          <Box flex={1}></Box>
        </Box>
      </Box>
    );
  };

  _renderUserListing = () => {
    const users = this.props.user!.getUsers();
    const { page, fetchUsersLoading, sortedBy } = this.state;

    return (
      <Box>
        <Box
          mb={30}
          mt={30}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <FittTextInput
            placeholder="Search user"
            style={{ flex: 1, height: 30 }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={this._onChangeUserSearchTerm}
          />

          <Box style={{ width: 200, marginLeft: 100 }}>
            <FittSelect
              isSearchable={false}
              value={page}
              onChange={(page: any) =>
                this.setState(
                  { page, fetchUsersLoading: true },
                  this._debouncedFetchUsers,
                )
              }
              options={Array.from(
                { length: this.props.user!.totalUserPages },
                (_, i) => ({
                  label: `Page ${i + 1}`,
                  value: i + 1,
                }),
              )}
              placeholder="Pages"
            />
          </Box>
        </Box>
        {fetchUsersLoading ? (
          <FittLoadingScreen />
        ) : (
          <Box
            style={{
              maxHeight: 500,
              //@ts-ignore
              overflowY: 'scroll',
            }}
          >
            <UserSectionBar
              sortedBy={sortedBy}
              onClick={this._onChangeSorting}
            />
            {users.map(user => (
              <UserItemCell
                key={user.id}
                user={user}
                style={{ marginBottom: 2 }}
                onClick={() => this._addToSelectedUsers({ user })}
              />
            ))}
          </Box>
        )}
      </Box>
    );
  };

  _renderTargettedCustomers = () => {
    const { selectedUsers, targettedCustomers } = this.state;
    const selectedSpecificUsers =
      targettedCustomers && targettedCustomers.value === 'specific';
    return (
      <Box style={{ marginBottom: 30, marginTop: 15, zIndex: 2000 }}>
        <Box style={{ flexDirection: 'row', marginBottom: 15 }}>
          <TextNormalBold>User selection</TextNormalBold>
        </Box>

        <Box flexDirection="row">
          <Box
            flex={1}
            mr={40}
            style={{
              flexGrow: 1,
            }}
          >
            <FittSelect
              label={'Who can use this code?'}
              isSearchable={true}
              value={targettedCustomers}
              onChange={(targettedCustomers: any) => {
                if (targettedCustomers.value === 'specific') {
                  this._fetchUsers();
                }
                this.setState({ targettedCustomers: targettedCustomers });
              }}
              options={[
                { value: 'all', label: 'Everyone' },
                { value: 'specific', label: 'Specific users' },
                {
                  value: 'firstBooking',
                  label: 'First booking of the account',
                },
              ]}
              placeholder="Select the type of customer"
              style={{ marginBottom: 30 }}
            />

            {selectedSpecificUsers ? (
              <Box>
                <Box style={{ flexDirection: 'row', marginBottom: 15 }}>
                  <TextNormal style={{ fontSize: 17 }}>
                    Selected users
                  </TextNormal>
                </Box>
                <Box
                  style={{
                    maxHeight: 500,
                    //@ts-ignore
                    overflowY:
                      //@ts-ignore
                      targettedCustomers.value === 'specific'
                        ? 'scroll'
                        : 'visible',
                  }}
                >
                  {selectedUsers.map(user => (
                    <SelectedUserItemCell
                      key={user.id}
                      user={user}
                      style={{ marginBottom: 2 }}
                      onClick={() => this._removedFromSelectedUsers({ user })}
                    />
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box flex={1} style={{ flexGrow: 2 }}>
            {selectedSpecificUsers ? this._renderUserListing() : null}
          </Box>
        </Box>
      </Box>
    );
  };

  _renderGymListing = () => {
    const gyms = this.props.gym!.getGyms();
    const { city, fetchGymsLoading } = this.state;

    return (
      <Box>
        <Box
          mb={30}
          mt={30}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <FittTextInput
            placeholder="Search gym"
            style={{ flex: 1, height: 30 }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={this._onChangeGymSearchTerm}
          />
          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              value={city}
              onChange={(city: any) =>
                this.setState(
                  { city, fetchGymsLoading: true },
                  this._debouncedFetchGyms,
                )
              }
              options={[
                { label: 'any', value: 'any' },
                ...this.props
                  .gym!.gymCities.slice()
                  .map(gc => ({ label: gc, value: gc })),
              ]}
            />
          </Box>
        </Box>

        {fetchGymsLoading ? (
          <FittLoadingScreen />
        ) : (
          <Box
            style={{
              maxHeight: 500,
              //@ts-ignore
              overflowY: 'scroll',
            }}
          >
            {fetchGymsLoading ? (
              <FittLoadingScreen />
            ) : (
              <Box>
                {gyms.map(gym => (
                  <GymItemCell
                    key={gym.id}
                    gym={gym}
                    style={{ marginBottom: 8 }}
                    onClick={() => this._addToSelectedGyms({ gym: gym })}
                  />
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  _renderTargettedGyms = () => {
    const { selectedGyms, targettedGyms } = this.state;
    const selectedSpecificGyms =
      targettedGyms && targettedGyms.value === 'specific';
    return (
      <Box style={{ marginBottom: 30, zIndex: 1000 }}>
        <Box style={{ flexDirection: 'row', marginBottom: 15 }}>
          <TextNormalBold>Gym selection</TextNormalBold>
        </Box>

        <Box flexDirection="row">
          <Box
            flex={1}
            mr={40}
            style={{
              flexGrow: 1,
            }}
          >
            <FittSelect
              label={'Which gym is this code for?'}
              isSearchable={true}
              value={targettedGyms}
              onChange={(targettedGyms: any) => {
                if (targettedGyms.value === 'specific') {
                  this._fetchGyms();
                }
                this.setState({ targettedGyms: targettedGyms });
              }}
              options={[
                { value: 'all', label: 'Every gym' },
                { value: 'specific', label: 'Specific gyms' },
              ]}
              placeholder="Select the type of gym"
              style={{ marginBottom: 30 }}
            />

            {selectedSpecificGyms ? (
              <Box>
                <Box style={{ flexDirection: 'row', marginBottom: 15 }}>
                  <TextNormal style={{ fontSize: 17 }}>
                    Selected gyms
                  </TextNormal>
                </Box>
                <Box
                  style={{
                    maxHeight: 500,
                    //@ts-ignore
                    overflowY:
                      //@ts-ignore
                      targettedGyms.value === 'specific' ? 'scroll' : 'visible',
                  }}
                >
                  {selectedGyms.map(gym => (
                    <SelectedGymItemCell
                      //key={user.id}
                      key={gym.id}
                      gym={gym}
                      style={{ marginBottom: 2 }}
                      onClick={() =>
                        this._removedFromSelectedGyms({ gym: gym })
                      }
                    />
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box flex={1} style={{ flexGrow: 2 }}>
            {selectedSpecificGyms ? this._renderGymListing() : null}
          </Box>
        </Box>
      </Box>
    );
  };

  _renderCreatePromoCodeButton = () => {
    const {
      code,
      discountAmount,
      maxRedeem,
      minimumCost,
      sendPromoCodeLoading,
      targettedCustomers,
      targettedGyms,
      serviceType,
    } = this.state;
    return (
      <Box alignItems="flex-end">
        <Button
          label="Create and send promo code"
          style={{ marginVertical: 20, width: 250 }}
          onClick={this._onPressCreatePromoCode}
          loading={sendPromoCodeLoading}
          disabled={
            code === '' ||
            discountAmount === '' ||
            minimumCost === '' ||
            maxRedeem === '' ||
            !serviceType ||
            (!targettedCustomers && !targettedGyms)
          }
        />
      </Box>
    );
  };

  render() {
    return (
      <Box mb={10} p={45} flex={1}>
        {this._renderHeader()}
        {this._renderCodeAndType()}
        {this._renderShares()}
        {this._renderTargettedCustomers()}
        {this._renderTargettedGyms()}
        {this._renderCreatePromoCodeButton()}
      </Box>
    );
  }
}
