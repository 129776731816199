import * as React from 'react';
import { Box } from 'react-native-kondo';
import debounce from 'lodash.debounce';
import { observer, inject } from 'mobx-react';
import { GymDocument, Routing, UserDocument } from '../../types';
import { UIStoreType } from '../../stores/ui';

import Button from '../common/Button';
import { formatError } from '../../utils/text';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittSelect from '../common/FittSelect';
import FittTitle from '../common/FittTitle';
import FittBackButton from '../common/FittBackButton';
import { TextNormal, TextNormalBold } from '../common/Typography';
import FittTextInput from '../common/FittTextInput';
import { TransactionStore } from '../../stores/transaction';
import { PromoCodeStore } from '../../stores/promoCode';
import { PromoCode } from '../../sharedTypes';
import { UserStore } from '../../stores/user';
import SelectedUserItemCell from './SelectedUserItemCell';
import UserSectionBar from '../user/UserSectionBar';
import UserItemCell from '../user/UserItemCell';
import { GymStore } from '../../stores/gym';
import { CompanyStore } from '../../stores/company';
import GymItemCell from '../gym/GymItemCell';
import SelectedGymItemCell from './SelectedGymItemCell';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  promoCode?: PromoCodeStore;
  transaction?: TransactionStore;
  user?: UserStore;
  gym?: GymStore;
  company?: CompanyStore;
}

const promoCodeStates = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

const promoCodeUserRules = [
  { value: 'all', label: 'Everyone' },
  { value: 'specific', label: 'Specific users' },
  { value: 'firstBooking', label: 'First booking of the account' },
];

const promoCodeGymRules = [
  { value: 'all', label: 'Every gym' },
  { value: 'specific', label: 'Specific gyms' },
];

function getInitalState(promoCode: Partial<PromoCode>) {
  const targettedCustomers = promoCodeUserRules.find(
    s => s.value === promoCode.userRule,
  );
  const targettedGyms = promoCodeGymRules.find(
    s => s.value === promoCode.gymRule,
  );

  return {
    promoCodeState: promoCodeStates.find(s => s.value === promoCode.isActive),
    initialLoading: true,
    saveLoading: false,
    targettedCustomers,
    selectedUsers: promoCode.selectedUsers!,
    targettedGyms,
    selectedGyms: promoCode.selectedGyms!,
  };
}

interface S {
  promoCodeState: any;
  initialLoading: boolean;
  saveLoading: boolean;
  searchLoading: boolean;
  searchTerm: string;
  sortedBy: string;
  status: any;
  page: any;
  targettedCustomers: any;
  fetchUsersLoading: boolean;
  selectedUsers: UserDocument[];
  targettedGyms: any;
  fetchGymsLoading: boolean;
  city: any;
  gymSearchTerm: string;
  selectedGyms: GymDocument[];
}

@inject('ui', 'routing', 'promoCode', 'transaction', 'user', 'gym', 'company')
@observer
export default class EditPromoCodeScreen extends React.Component<P, S> {
  state: S = {
    promoCodeState: { value: true, label: 'Active' },
    initialLoading: true,
    saveLoading: false,
    searchLoading: false,
    status: { value: 'all', label: 'All' },

    targettedCustomers: undefined as any,
    fetchUsersLoading: false,
    searchTerm: '',
    sortedBy: 'createdAt',
    page: { label: 'Page 1', value: 1 },
    selectedUsers: [],

    targettedGyms: undefined as any,
    fetchGymsLoading: false,
    city: { label: 'any', value: 'any' },
    gymSearchTerm: '',
    selectedGyms: [],
  };

  componentDidMount() {
    this._fetchPromoCode();
  }

  _fetchPromoCode = async () => {
    try {
      this.setState({ initialLoading: true });
      const urlString = window.location.href;
      const url = new URL(urlString);
      const promoCodeId = url.searchParams.get('id');
      if (promoCodeId) {
        await this.props.promoCode!.fetchPromoCode({ promoCodeId });

        const promoCode = this.props.promoCode!.getCurrentPromoCode()!;

        if (promoCode.userRule === 'specific') {
          this._fetchUsers();
        }

        if (promoCode.gymRule === 'specific') {
          this._fetchGyms();
        }

        this.setState({
          ...getInitalState(this.props.promoCode!.getCurrentPromoCode()!),
        });
      }
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ initialLoading: false });
    }
  };

  _fetchUsers = async () => {
    try {
      const { searchTerm, page, sortedBy } = this.state;
      this.setState({ fetchUsersLoading: true });
      await this.props.user!.fetchUsers({
        searchTerm,
        page: page.value,
        sort: sortedBy,
      });
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ fetchUsersLoading: false });
    }
  };

  _onChangeUserSearchTerm = (searchTerm: string) => {
    this.setState(
      {
        searchTerm,
        fetchUsersLoading: true,
        page: { label: 'Page 1', value: 1 },
      },
      this._debouncedFetchUsers,
    );
  };

  _debouncedFetchUsers = debounce(async () => {
    await this._fetchUsers();
  }, 500);

  _onChangeSorting = (sortedBy: string) => {
    this.setState(
      { sortedBy, fetchUsersLoading: true },
      this._debouncedFetchUsers,
    );
  };

  _fetchGyms = async () => {
    try {
      const { gymSearchTerm, city } = this.state;
      this.setState({ fetchGymsLoading: true });
      await this.props.gym!.fetchGyms({
        searchTerm: gymSearchTerm,
        city: city.value !== 'any' ? city.value : undefined,
      });
      await this.props.company!.fetchCompanies();
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ fetchGymsLoading: false });
    }
  };

  _onChangeGymSearchTerm = (gymSearchTerm: string) => {
    this.setState(
      { gymSearchTerm, fetchGymsLoading: true },
      this._debouncedFetchGyms,
    );
  };

  _debouncedFetchGyms = debounce(async () => {
    await this._fetchGyms();
  }, 500);

  _onClickSave = async (action: string) => {
    try {
      if (action === 'save') {
        this.setState({ saveLoading: true });
      }

      const promoCodeId = this.props.promoCode!.getCurrentPromoCode()!.id;

      const { promoCodeState, selectedUsers, selectedGyms } = this.state;

      await this.props.promoCode!.editPromoCode({
        promoCodeId,
        isActive: promoCodeState.value,
        selectedUserIds: selectedUsers.map(user => user.id),
        selectedGymIds: selectedGyms.map(gym => gym.id),
      });
      if (action === 'save') {
        this.props.ui!.openToaster({
          text: 'Promo code saved!',
        });
      }
    } catch (e) {
      this.props.ui!.openToaster({
        text: formatError(e),
        type: 'error',
      });
    } finally {
      this.setState({ saveLoading: false });
    }
  };

  _addToSelectedUsers = ({ user }: { user: UserDocument }) => {
    if (!this.state.selectedUsers.includes(user)) {
      const newSelectedUsers = this.state.selectedUsers.slice();
      newSelectedUsers.push(user);
      this.setState({ selectedUsers: newSelectedUsers });
    }
  };

  _removedFromSelectedUsers = ({ user }: { user: UserDocument }) => {
    const newSelectedUsers = this.state.selectedUsers.slice();

    const indexOfItemToRemove = newSelectedUsers.findIndex(
      element => element === user,
    );

    newSelectedUsers.splice(indexOfItemToRemove, 1);
    this.setState({ selectedUsers: newSelectedUsers });
  };

  _addToSelectedGyms = ({ gym }: { gym: GymDocument }) => {
    if (!this.state.selectedGyms.includes(gym)) {
      const newSelectedGyms = this.state.selectedGyms.slice();

      newSelectedGyms.push(gym);
      this.setState({ selectedGyms: newSelectedGyms });
    }
  };

  _removedFromSelectedGyms = ({ gym }: { gym: GymDocument }) => {
    const newSelectedGyms = this.state.selectedGyms.slice();

    const indexOfItemToRemove = newSelectedGyms.findIndex(
      element => element === gym,
    );

    newSelectedGyms.splice(indexOfItemToRemove, 1);
    this.setState({ selectedGyms: newSelectedGyms });
  };

  _renderHeader = () => {
    return (
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={20}
      >
        <FittBackButton
          onClick={() => this.props.routing!.push('/promocodes')}
          label="promocodes"
        />

        <FittTitle title="Edit promo code" />

        <Box>
          <Button
            label="Save"
            onClick={() => this._onClickSave('save')}
            loading={this.state.saveLoading}
            style={{ width: 240, marginBottom: 30 }}
          />
        </Box>
      </Box>
    );
  };

  _renderPromoCodeStatus = () => {
    return (
      <Box flexDirection="row" style={{ marginBottom: 30, zIndex: 1000 }}>
        <Box flex={1}>
          <FittSelect
            label="Promo code status"
            onChange={(promoCodeState: any) =>
              this.setState({ promoCodeState })
            }
            options={promoCodeStates}
            value={this.state.promoCodeState}
            style={{ marginBottom: 10 }}
          />
        </Box>
      </Box>
    );
  };

  _renderPromoCodeInformation = () => {
    const promoCode = this.props.promoCode!.getCurrentPromoCode()!;
    console.log(promoCode);

    const formatServiceTypeDict = {
      all: 'All',
      '1session': '1 Session',
      '3session': '3 Sessions',
      '5session': '5 Sessions',
      '10session': '10 Sessions',
      workoutPlan: 'Workout Plan',
    };

    const formatDiscountTypeDict = {
      absolute: 'Absolute ($)',
      relative: 'Relative (%)',
    };

    return (
      <Box style={{ marginBottom: 30 }}>
        <TextNormalBold style={{ marginBottom: 15 }}>
          Promo code information
        </TextNormalBold>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="Code"
              placeholder="Code"
              value={promoCode.code}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <FittTextInput
              label="Discount type"
              placeholder="Discount type"
              value={
                //@ts-ignore
                formatDiscountTypeDict[`${promoCode.discountType}`]
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <FittTextInput
              label="Discount amount"
              placeholder="Discount amount"
              value={`${
                promoCode.discountType === 'absolute'
                  ? `$${promoCode.discountAmount}`
                  : `${promoCode.discountAmount * 100} %`
              }`}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Type of service"
              placeholder="Type of service"
              // value={promoCode.serviceType}
              value={
                //@ts-ignore
                formatServiceTypeDict[`${promoCode.serviceType}`]
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <FittTextInput
              label="Redeemability"
              placeholder="Redeemability"
              value={promoCode.maxRedeem}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <FittTextInput
              label="Minimum cost to use"
              placeholder="Minimum cost to use"
              value={`$${promoCode.minimumCost}`}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
          </Box>
        </Box>
      </Box>
    );
  };

  _renderShareSplit = () => {
    const promoCode = this.props.promoCode!.getCurrentPromoCode()!;
    return (
      <Box style={{ marginBottom: 30 }}>
        <TextNormalBold style={{ marginBottom: 15 }}>
          Share distribution for the remaining amount post discount
        </TextNormalBold>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="Company"
              placeholder="Company"
              value={`${promoCode.companyShare * 100} %`}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <Box style={{ width: 150 }}></Box>
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Trainer"
              placeholder="Trainer"
              value={`${promoCode.trainerShare * 100} %`}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
          </Box>
        </Box>
      </Box>
    );
  };

  _renderUserListing = () => {
    const users = this.props.user!.getUsers();
    const { page, fetchUsersLoading, sortedBy } = this.state;

    return (
      <Box>
        <Box
          mb={30}
          mt={30}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <FittTextInput
            placeholder="Search user"
            style={{ flex: 1, height: 30 }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={this._onChangeUserSearchTerm}
          />

          <Box style={{ width: 200, marginLeft: 100 }}>
            <FittSelect
              isSearchable={false}
              value={page}
              onChange={(page: any) =>
                this.setState(
                  { page, fetchUsersLoading: true },
                  this._debouncedFetchUsers,
                )
              }
              options={Array.from(
                { length: this.props.user!.totalUserPages },
                (_, i) => ({
                  label: `Page ${i + 1}`,
                  value: i + 1,
                }),
              )}
              placeholder="Pages"
            />
          </Box>
        </Box>
        {fetchUsersLoading ? (
          <FittLoadingScreen />
        ) : (
          <Box
            style={{
              maxHeight: 500,
              //@ts-ignore
              overflowY: 'scroll',
            }}
          >
            <UserSectionBar
              sortedBy={sortedBy}
              onClick={this._onChangeSorting}
            />
            {users.map(user => (
              <UserItemCell
                key={user.id}
                user={user}
                style={{ marginBottom: 2 }}
                onClick={() => this._addToSelectedUsers({ user })}
              />
            ))}
          </Box>
        )}
      </Box>
    );
  };

  _renderTargettedCustomers = () => {
    const { selectedUsers, targettedCustomers } = this.state;
    const selectedSpecificUsers =
      targettedCustomers && targettedCustomers.value === 'specific';
    return (
      <Box style={{ marginBottom: 30, marginTop: 15, zIndex: 2000 }}>
        <Box style={{ flexDirection: 'row', marginBottom: 15 }}>
          <TextNormalBold>User selection</TextNormalBold>
        </Box>

        <Box flexDirection="row">
          <Box
            flex={1}
            mr={40}
            style={{
              flexGrow: 1,
            }}
          >
            <FittSelect
              label={'Who can use this code?'}
              isSearchable={true}
              value={targettedCustomers}
              onChange={(targettedCustomers: any) => {
                if (targettedCustomers.value === 'specific') {
                  this._fetchUsers();
                }
                this.setState({ targettedCustomers: targettedCustomers });
              }}
              placeholder="Select the type of customer"
              style={{ marginBottom: 30 }}
              isDisabled
            />

            {selectedSpecificUsers ? (
              <Box>
                <Box style={{ flexDirection: 'row', marginBottom: 15 }}>
                  <TextNormal style={{ fontSize: 17 }}>
                    Selected users
                  </TextNormal>
                </Box>
                <Box
                  style={{
                    maxHeight: 500,
                    //@ts-ignore
                    overflowY:
                      //@ts-ignore
                      targettedCustomers.value === 'specific'
                        ? 'scroll'
                        : 'visible',
                  }}
                >
                  {selectedUsers.map(user => (
                    <SelectedUserItemCell
                      key={user.id}
                      user={user}
                      style={{ marginBottom: 2 }}
                      onClick={() => this._removedFromSelectedUsers({ user })}
                    />
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box flex={1} style={{ flexGrow: 2 }}>
            {selectedSpecificUsers ? this._renderUserListing() : null}
          </Box>
        </Box>
      </Box>
    );
  };

  _renderGymListing = () => {
    const gyms = this.props.gym!.getGyms();
    const { city, fetchGymsLoading } = this.state;

    return (
      <Box>
        <Box
          mb={30}
          mt={30}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <FittTextInput
            placeholder="Search gym"
            style={{ flex: 1, height: 30 }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={this._onChangeGymSearchTerm}
          />
          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              value={city}
              onChange={(city: any) =>
                this.setState(
                  { city, fetchGymsLoading: true },
                  this._debouncedFetchGyms,
                )
              }
              options={[
                { label: 'any', value: 'any' },
                ...this.props
                  .gym!.gymCities.slice()
                  .map(gc => ({ label: gc, value: gc })),
              ]}
            />
          </Box>
        </Box>

        {fetchGymsLoading ? (
          <FittLoadingScreen />
        ) : (
          <Box
            style={{
              maxHeight: 500,
              //@ts-ignore
              overflowY: 'scroll',
            }}
          >
            {fetchGymsLoading ? (
              <FittLoadingScreen />
            ) : (
              <Box>
                {gyms.map(gym => (
                  <GymItemCell
                    key={gym.id}
                    gym={gym}
                    style={{ marginBottom: 8 }}
                    onClick={() => this._addToSelectedGyms({ gym: gym })}
                  />
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  _renderTargettedGyms = () => {
    const { selectedGyms, targettedGyms } = this.state;
    const selectedSpecificGyms =
      targettedGyms && targettedGyms.value === 'specific';
    return (
      <Box style={{ marginBottom: 30, zIndex: 1000 }}>
        <Box style={{ flexDirection: 'row', marginBottom: 15 }}>
          <TextNormalBold>Gym selection</TextNormalBold>
        </Box>

        <Box flexDirection="row">
          <Box
            flex={1}
            mr={40}
            style={{
              flexGrow: 1,
            }}
          >
            <FittSelect
              label={'Which gym is this code for?'}
              isSearchable={true}
              value={targettedGyms}
              onChange={(targettedGyms: any) => {
                if (targettedGyms.value === 'specific') {
                  this._fetchGyms();
                }
                this.setState({ targettedGyms: targettedGyms });
              }}
              placeholder="Select the type of gym"
              style={{ marginBottom: 30 }}
              isDisabled
            />

            {selectedSpecificGyms ? (
              <Box>
                <Box style={{ flexDirection: 'row', marginBottom: 15 }}>
                  <TextNormal style={{ fontSize: 17 }}>
                    Selected gyms
                  </TextNormal>
                </Box>
                <Box
                  style={{
                    maxHeight: 500,
                    //@ts-ignore
                    overflowY:
                      //@ts-ignore
                      targettedGyms.value === 'specific' ? 'scroll' : 'visible',
                  }}
                >
                  {selectedGyms.map(gym => (
                    <SelectedGymItemCell
                      key={gym.id}
                      gym={gym}
                      style={{ marginBottom: 2 }}
                      onClick={() =>
                        this._removedFromSelectedGyms({ gym: gym })
                      }
                    />
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box flex={1} style={{ flexGrow: 2 }}>
            {selectedSpecificGyms ? this._renderGymListing() : null}
          </Box>
        </Box>
      </Box>
    );
  };

  render() {
    if (this.state.initialLoading) {
      return <FittLoadingScreen />;
    }

    return (
      <Box mb={10} p={45} flex={1}>
        {this._renderHeader()}
        {this._renderPromoCodeStatus()}
        {this._renderPromoCodeInformation()}
        {this._renderShareSplit()}
        {this._renderTargettedCustomers()}
        {this._renderTargettedGyms()}
      </Box>
    );
  }
}
