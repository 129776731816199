import * as React from 'react';
import { Box } from 'react-native-kondo';
import { ViewStyle } from 'react-native';
import colors from '../../constants/colors';

interface P {
  style?: ViewStyle;
}

export default class FittBox extends React.Component<P> {
  state = {};

  render() {
    return (
      <Box
        p={25}
        style={[
          {
            borderRadius: 10,
            // @ts-ignore
            'box-shadow': '1px -1px 2px #FFFFFF, inset -2px 2px 6px #D9D9D9',
          },
          this.props.style,
        ]}
        bg={colors.white}
      >
        {this.props.children}
      </Box>
    );
  }
}
