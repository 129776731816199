import * as React from 'react';
import { View } from 'react-native';
import { ViewStyle } from 'react-native';
import colors from '../../constants/colors';
import { Section } from '../user/UserItemCell';

const EditTrainerTransactionSectionBar = ({
  style,
  sortedBy,
  onClick,
}: {
  style?: ViewStyle;
  sortedBy?: string;
  onClick: (newSort: string) => void;
}) => {
  return (
    <View
      style={[
        {
          height: 60,
          paddingHorizontal: 10,

          borderWidth: 1,
          borderColor: colors.borderColor,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
          flexDirection: 'row',
          backgroundColor: colors.white,
          marginBottom: 10,
        },

        style,
      ]}
    >
      <Section
        label="Customer's email"
        withHover
        sorted={sortedBy === 'userEmail'}
        onClick={
          () =>
            alert(
              'Must enable sorting by customer email in the backend! 🙏',
            ) /** () => onClick('userEmail') */
        }
      />
      <Section
        label="Customer's name"
        withHover
        sorted={sortedBy === 'userName'}
        onClick={
          () =>
            alert(
              'Must enable sorting by custumer name state in the backend! 🙏',
            ) /** () => onClick('userName') */
        }
      />
      <Section
        label="State"
        withHover
        sorted={sortedBy === 'state'}
        onClick={
          () =>
            alert(
              'Must enable sorting by state in the backend! 🙏',
            ) /** () => onClick('state') */
        }
      />
      <Section
        label="Created at"
        withHover
        sorted={sortedBy === 'createdAt'}
        onClick={() => onClick('createdAt')}
      />
    </View>
  );
};

export default EditTrainerTransactionSectionBar;
