import { observable, action, toJS } from 'mobx';
import { create, persist } from 'mobx-persist';
import { routingStore } from '.';

import * as api from '../utils/api';
import { Id, UserDocument } from '../types';

import { formatError } from '../utils/text';

export interface LoginPayload {
  email?: string;
  password?: string;
  refreshToken?: string;
  systemOrigin?: 'console';
}

export interface EditUserPayload {
  email: string;
  role: string;
  isVerified: boolean;
  isBanned: boolean;
  language: string;
  firstName: string;
  lastName: string;
  phone: string;
  userId: Id;
}

export interface UserStore {
  token: string | null;
  id: Id | null;
  refreshToken: string | null;
  role: 'normal' | 'admin';
  totalUsers: number;
  totalUserPages: number;
  currentUser: UserDocument | null;

  users: UserDocument[];

  fetchUsers: (
    payload: {
      searchTerm?: string;
      page?: number;
      sort?: string;
      language?: 'en' | 'fr';
      gymId?: string;
    } | void,
  ) => Promise<void>;
  fetchUser: (payload: { userId: Id }) => Promise<void>;
  getUsers: () => UserDocument[];
  getCurrentUser: () => UserDocument | null;
  setCurrentUser: (gym: UserDocument | null) => void;
  login: (payload: LoginPayload) => Promise<void>;
  getMe: () => Promise<void>;
  editUser: (payload: EditUserPayload) => Promise<void>;
  resendVerificationEmail: (payload: { userId: Id }) => Promise<void>;
  sendForgetPasswordEmail: (payload: { userId: Id }) => Promise<void>;
  logOut: () => void;
}

class UserStr implements UserStore {
  @persist @observable token: UserStore['token'] = null;
  @persist @observable id: UserStore['id'] = null;
  @persist @observable role: UserStore['role'] = 'normal';
  @persist @observable refreshToken: UserStore['refreshToken'] = null;
  @observable users: UserStore['users'] = [];
  @observable totalUsers: UserStore['totalUsers'] = 0;
  @observable totalUserPages: UserStore['totalUserPages'] = 1;
  @observable currentUser: UserStore['currentUser'] = null;

  shapeUser = (
    userRepPayload: UserDocument & {
      accessToken: string;
      refreshToken: string;
    },
  ) => {
    const { id, accessToken, refreshToken, role } = userRepPayload;
    this.id = id!;
    this.role = role!;

    if (role !== 'admin') {
      throw new Error("You're not an admin.");
    }

    if (accessToken) {
      this.token = accessToken;
      this.refreshToken = refreshToken;
    }
  };

  @action
  async getMe() {
    try {
      const userRep = await api.getMe();
      this.shapeUser(userRep);
    } catch (e) {
      throw formatError(e);
    }
  }

  @action
  fetchUser: UserStore['fetchUser'] = async payload => {
    const user = await api.getUser(payload);
    this.currentUser = user;
  };

  @action
  getCurrentUser: UserStore['getCurrentUser'] = () => {
    if (this.currentUser) {
      return toJS(this.currentUser);
    }
    return null;
  };

  @action
  async login(payload: LoginPayload) {
    try {
      const userRep = await api.login(payload);
      routingStore.push('/');
      this.shapeUser(userRep);
    } catch (e) {
      throw formatError(e);
    }
  }

  @action
  editUser: UserStore['editUser'] = async payload => {
    await api.editUser(payload);
  };

  @action
  resendVerificationEmail: UserStore['resendVerificationEmail'] = async payload => {
    await api.resendVerificationEmail(payload);
  };

  @action
  sendForgetPasswordEmail: UserStore['sendForgetPasswordEmail'] = async payload => {
    await api.sendForgetPasswordEmail(payload);
  };

  @action
  async refreshAuth(payload: LoginPayload) {
    try {
      const userRep = await api.login(payload);
      if (userRep.accessToken) {
        this.token = userRep.accessToken;
        this.refreshToken = userRep.refreshToken;
      }
    } catch (e) {
      throw formatError(e);
    }
  }

  @action
  logOut = () => {
    routingStore.push('/login');
    this.token = null;
    this.id = null;
  };

  @action
  setCurrentUser: UserStore['setCurrentUser'] = user => {
    this.currentUser = user;
  };

  @action
  fetchUsers: UserStore['fetchUsers'] = async payload => {
    const { users, totalDocs, totalPages } = await api.getUsers(payload);
    this.users = users;
    this.totalUsers = totalDocs;
    this.totalUserPages = totalPages;
  };

  @action
  getUsers: UserStore['getUsers'] = () => {
    return this.users.slice();
  };
}

const hydrate = create({
  storage: localStorage,
});

// create the state
const UserSaved = new UserStr();
hydrate('user', UserSaved);
export default UserSaved;
