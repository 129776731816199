import { observable, action, toJS } from 'mobx';

import * as api from '../utils/api';
import { Id, SessionInfo, TransactionDocument, UserDocument } from '../types';

export interface TransactionStore {
  totalTransactions: number;
  totalTransactionPages: number;
  currentTransaction: TransactionDocument | null;
  Transactions: TransactionDocument[];

  fetchTransactions: (
    payload: {
      year?: number;
      month?: number;
      searchTerm?: string;
      sort?: string;
      status?:
        | 'all'
        | 'pending'
        | 'accepted'
        | 'completed'
        | 'cancelled'
        | 'expired';
      page?: number;
      serviceType?: SessionInfo['serviceType'];
      userId?: UserDocument['id'];
    } | void,
  ) => Promise<void>;
  fetchTransaction: (payload: { transactionId: Id }) => Promise<void>;
  getTransactions: () => TransactionDocument[];
  getCurrentTransaction: () => TransactionDocument | null;
}

class Transaction implements TransactionStore {
  @observable Transactions: TransactionStore['Transactions'] = [];
  @observable totalTransactions: TransactionStore['totalTransactions'] = 0;
  @observable
  totalTransactionPages: TransactionStore['totalTransactionPages'] = 1;
  @observable currentTransaction: TransactionStore['currentTransaction'] = null;

  @action
  fetchTransaction: TransactionStore['fetchTransaction'] = async payload => {
    const Transaction = await api.getTransaction(payload);
    this.currentTransaction = Transaction;
  };

  getCurrentTransaction: TransactionStore['getCurrentTransaction'] = () => {
    if (this.currentTransaction) {
      return toJS(this.currentTransaction);
    }
    return null;
  };

  @action
  fetchTransactions: TransactionStore['fetchTransactions'] = async payload => {
    const { transactions, totalDocs, totalPages } = await api.getTransactions(
      payload,
    );
    this.Transactions = transactions;
    this.totalTransactions = totalDocs;
    this.totalTransactionPages = totalPages;
  };

  getTransactions: TransactionStore['getTransactions'] = () => {
    return toJS(this.Transactions);
  };
}

const TransactionObj = new Transaction();

export default TransactionObj;
