import * as React from 'react';
import { View } from 'react-native';
import { TextNormal } from '../common/Typography';
import { ViewStyle } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import colors from '../../constants/colors';
import { DisputeDocument } from '../../types';

const DisputeItemCell = ({
  dispute,
  style,
  onClick,
}: {
  dispute: DisputeDocument;
  style?: ViewStyle;
  onClick: (event: any, dispute: any) => void;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  const color = dispute.isResolved ? 'rgb(23, 157, 142)' : 'rgb(204, 0, 0)';
  return (
    // @ts-ignore
    <View
      onClick={(event: any) => onClick(event, dispute)}
      ref={ref}
      style={[
        {
          height: 60,
          paddingHorizontal: 10,
          borderWidth: 1,
          borderColor: colors.borderColor,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
          flexDirection: 'row',
          backgroundColor: colors.white,
        },
        isHovered && {
          'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
        },
        style,
      ]}
    >
      <Section label={dispute.user.email} />
      <Section
        withHover
        label={`${dispute.user.firstName} ${dispute.user.lastName}`}
      />
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          paddingLeft: 5,
        }}
      >
        <TextNormal style={{ color: color }}>
          {dispute.isResolved ? 'Resolved' : 'Unresolved'}
        </TextNormal>
      </View>
      <Section
        withHover
        label={`${dispute.trainer.user.firstName} ${dispute.trainer.user.lastName}`}
      />
      <Section
        label={
          dispute.createdAt ? new Date(dispute.createdAt).toLocaleString() : ''
        }
      />
    </View>
  );
};

export default DisputeItemCell;

export const Section = ({
  label,
  onClick,
  withHover,
  sorted,
}: {
  label: string;
  onClick?: () => void;
  withHover?: boolean;
  sorted?: boolean;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  return (
    <View
      ref={ref}
      // @ts-ignore
      style={[
        { flex: 1, justifyContent: 'center', paddingLeft: 5 },
        isHovered && withHover
          ? {
              // @ts-ignore
              'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
            }
          : null,
      ]}
      // @ts-ignore
      onClick={onClick}
    >
      <TextNormal style={{ fontWeight: sorted ? 'bold' : 'normal' }}>
        {label}
      </TextNormal>
    </View>
  );
};
