import * as React from 'react';
import { Box } from 'react-native-kondo';
import debounce from 'lodash.debounce';
import { observer, inject } from 'mobx-react';
import { Routing, TrainerDocument } from '../../types';
import { UIStoreType } from '../../stores/ui';

import { TrainerStore } from '../../stores/trainer';

import Button from '../common/Button';
import { formatError } from '../../utils/text';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittSelect from '../common/FittSelect';
import FittTitle from '../common/FittTitle';
import FittBackButton from '../common/FittBackButton';
import UserInformationLayout from '../dispute/UserInformationLayout';
import { TextNormalBold, TextMedium, TextNormal } from '../common/Typography';
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import colors from '../../constants/colors';
import FittTextInput from '../common/FittTextInput';
import { TransactionStore } from '../../stores/transaction';
import EditTrainerTransactionItemCell from './EditTrainerTransactionItemCell';
import EditTrainerTransactionSectionBar from './EditTrainerTransactionSectionBar';
import ISO6391 from 'iso-639-1';
import FittTextArea from '../common/FittTextArea';
import config from '../../config';
import { generateTransactionsCSV, getShareRateData } from '../../utils/api';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  trainer?: TrainerStore;
  transaction?: TransactionStore;
}

const trainerStates = [
  { value: 'approved', label: 'Approved' },
  { value: 'unlisted', label: 'Unlisted' },
  { value: 'pending', label: 'Pending approval' },
  // { value: 'vacation', label: 'Vacation' },
  { value: 'banned', label: 'Banned' },
  { value: 'initial', label: 'App submission' },
  { value: 'typeform', label: 'Typeform submission' },
];

function getInitalState(trainer: Partial<TrainerDocument>) {
  return {
    trainerState: trainerStates.find(s => s.value === trainer.status),
    initialLoading: true,
    saveLoading: false,
  };
}

interface S {
  trainerState: any;
  initialLoading: boolean;
  saveLoading: boolean;
  searchLoading: boolean;
  searchTerm: string;
  sortedBy: string;
  status: any;
  page: any;
  trainerShareRate: any;
}

@inject('ui', 'routing', 'trainer', 'transaction')
@observer
export default class EditUserPanel extends React.Component<P, S> {
  state: S = {
    trainerState: { value: 'pending', label: 'Pending approval' },
    initialLoading: true,
    saveLoading: false,
    searchLoading: false,
    searchTerm: '',
    sortedBy: 'createdAt',
    status: { value: 'all', label: 'All' },
    page: { label: 'Page 1', value: 1 },
    trainerShareRate: 60,
  };

  componentDidMount() {
    this._fetchTrainer();
    this._fetchTrainerTransactions();
  }

  _fetchTrainer = async () => {
    try {
      this.setState({ initialLoading: true });
      const urlString = window.location.href;
      const url = new URL(urlString);
      const trainerId = url.searchParams.get('id');
      if (trainerId) {
        await this.props.trainer!.fetchTrainer({ trainerId });
        const res = await getShareRateData();
        const currentTrainer = this.props.trainer!.getCurrentTrainer()!;
        let trainerShareRate = 100 - res.companyShare;
        if (currentTrainer.hasCustomShareRate) {
          trainerShareRate = currentTrainer.customShareRate;
        }

        this.setState({
          ...getInitalState(this.props.trainer!.getCurrentTrainer()!),
          trainerShareRate,
        });
      }
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ initialLoading: false });
    }
  };

  _onChangeSearchTerm = (searchTerm: string) => {
    this.setState(
      { searchTerm, searchLoading: true, page: { label: 'Page 1', value: 1 } },
      this._debouncedFetch,
    );
  };

  _debouncedFetch = debounce(async () => {
    await this._fetchTrainerTransactions();
  }, 500);

  _onChangeSorting = (sortedBy: string) => {
    this.setState({ sortedBy, searchLoading: true }, this._debouncedFetch);
  };

  _handleOnClick = (event: any, transaction: any) => {
    this.props.routing!.push(`/transaction?id=${transaction.id}`);
  };

  _fetchTrainerTransactions = async () => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const trainerId = url.searchParams.get('id');

    if (trainerId) {
      try {
        const { searchTerm, sortedBy, status, page } = this.state;
        this.setState({ searchLoading: true });

        await this.props.trainer!.fetchTrainerTransactions({
          trainerId,
          searchTerm,
          sort: sortedBy,
          //@ts-ignore
          status: status.value,
          page: page.value,
        });
      } catch (e) {
        this.props.ui!.openToaster({
          type: 'error',
          text: formatError(e),
        });
      } finally {
        this.setState({ searchLoading: false });
      }
    }
  };

  _onClickSave = async (action: string) => {
    try {
      if (action === 'save') {
        this.setState({ saveLoading: true });
      }
      const { trainerState } = this.state;
      await this.props.trainer!.editTrainer({
        status: trainerState.value,
        customShareRate: this.state.trainerShareRate,
        trainerId: this.props.trainer!.getCurrentTrainer()!.id,
      });
      if (action === 'save') {
        this.props.ui!.openToaster({
          text: 'Trainer saved!',
        });
      } else {
        // this.props.ui!.openToaster({
        //   text: isBanned ? 'User banned!' : 'User unbanned',
        // });
      }
    } catch (e) {
      this.props.ui!.openToaster({
        text: formatError(e),
        type: 'error',
      });
    } finally {
      this.setState({ saveLoading: false });
    }
  };

  _renderEmptyState = () => {
    return (
      <Box mb={10} p={45} flex={1} style={{ flexDirection: 'column' }}>
        <Box
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 60,
          }}
        >
          <FittBackButton
            onClick={() => this.props.routing!.push('/fittcoaches')}
            label="trainers"
          />

          <FittTitle title="Edit trainer" />

          <Box style={{ width: 77 }} />
        </Box>
        <Box style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <TextMedium style={{ color: colors.primary }}>
            User has not yet finished his Fittcoach submission
          </TextMedium>
        </Box>
      </Box>
    );
  };

  _renderHeader = () => {
    const trainer = this.props.trainer!.getCurrentTrainer()!;
    return (
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={20}
      >
        <FittBackButton
          onClick={() => this.props.routing!.push('/fittcoaches')}
          label="trainers"
        />

        <FittTitle title="Edit trainer" />

        <Box>
          <Button
            label="Save"
            onClick={() => this._onClickSave('save')}
            loading={this.state.saveLoading}
            style={{ width: 240, marginBottom: 30 }}
          />
          <a
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              marginBottom: 15,
            }}
            href={`${config.websiteURL}/trainer/${trainer!.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              label="Web trainer's profile"
              loading={this.state.saveLoading}
              style={{ width: 240, backgroundColor: colors.accent }}
            />
          </a>
        </Box>
      </Box>
    );
  };

  _renderTrainerStatus = () => {
    const trainer = this.props.trainer!.getCurrentTrainer()!;

    let options = trainerStates;

    if (trainer.status === 'typeform' || trainer.status === 'initial') {
      options = [
        { value: 'initial', label: 'App submission' },
        { value: 'typeform', label: 'Typeform submission' },
      ];
    }

    return (
      <Box flexDirection="row" style={{ marginBottom: 30, zIndex: 1000 }}>
        <Box flex={1}>
          <FittSelect
            label="Trainer status"
            onChange={(trainerState: any) => this.setState({ trainerState })}
            options={options}
            value={this.state.trainerState}
            style={{ marginBottom: 10 }}
          />
        </Box>
      </Box>
    );
  };

  _renderUserInformation = () => {
    const trainer = this.props.trainer!.getCurrentTrainer()!;
    return (
      <Box style={{ marginBottom: 30 }}>
        <Box style={{ flexDirection: 'row' }}>
          <Link
            to={`/user?id=${trainer.user.id}`}
            style={{ textDecoration: 'none', marginBottom: 15 }}
          >
            <TextNormalBold
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              User information
              <IoIosArrowForward
                size={20}
                style={{ marginBottom: -5, marginLeft: 3 }}
                color={colors.primary}
              />
            </TextNormalBold>
          </Link>
        </Box>
        <UserInformationLayout user={trainer.user} />
      </Box>
    );
  };

  _renderMonetaryInformation = () => {
    const trainer = this.props.trainer!.getCurrentTrainer()!;
    return (
      <Box style={{ marginBottom: 30 }}>
        <TextNormalBold style={{ marginBottom: 15 }}>
          Monetary information
        </TextNormalBold>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="QST tax number"
              placeholder="QST"
              value={trainer.taxInformation.qst}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <FittTextInput
              label="GST tax number"
              placeholder="GST"
              value={trainer.taxInformation.gst}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <Box style={{ width: 150 }}></Box>
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Stripe account ID"
              placeholder="Stripe account ID"
              value={trainer.stripe.accountId}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            <FittTextInput
              label="HST tax number"
              placeholder="HST tax number"
              value={trainer.taxInformation.hst}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
          </Box>
        </Box>
      </Box>
    );
  };

  _renderTrainerInformation = () => {
    const trainer = this.props.trainer!.getCurrentTrainer()!;
    return (
      <Box style={{ marginBottom: 30 }}>
        <TextNormalBold style={{ marginBottom: 15 }}>
          Trainer information
        </TextNormalBold>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            {trainer.spokenLanguages.map((language, index) => {
              return (
                <FittTextInput
                  key={language}
                  label={index === 0 ? 'Spoken language(s)' : undefined}
                  placeholder="specialty"
                  value={ISO6391.getNativeName(language)}
                  style={{
                    marginRight: 10,
                    width: '100%',
                    marginBottom:
                      index === trainer.spokenLanguages.length - 1 ? 30 : 15,
                  }}
                  inputStyle={{ width: '100%', height: 30 }}
                  disabled
                />
              );
            })}
            {trainer.trainingSpecialties.map((specialty, index) => {
              return (
                <FittTextInput
                  key={specialty}
                  label={index === 0 ? 'Specialties' : undefined}
                  placeholder="Specialty"
                  value={specialty}
                  style={{
                    marginRight: 10,
                    width: '100%',
                    marginBottom:
                      index === trainer.trainingSpecialties.length - 1
                        ? 30
                        : 15,
                  }}
                  inputStyle={{ width: '100%', height: 30 }}
                  disabled
                />
              );
            })}
            <FittTextArea
              label="Biography (Fr)"
              placeholder="Biography"
              value={trainer.bioFr || trainer.bio}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />
            {trainer.bioEn ? (
              <FittTextArea
                label="Biography (En)"
                placeholder="Biography"
                value={trainer.bioEn}
                style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
                inputStyle={{ width: '100%', height: 30 }}
                disabled
              />
            ) : null}
          </Box>
          <Box flex={1}>
            {trainer.selectedGyms.map((gym, index) => {
              return (
                <Link
                  key={gym.id}
                  to={`/gym?id=${gym.id}`}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <FittTextInput
                    label={index === 0 ? 'Selected gyms' : undefined}
                    placeholder="Name of the gym"
                    value={`${gym.name}-${gym.id}`}
                    style={{
                      marginRight: 10,
                      width: '100%',
                      height: '100%',
                      marginBottom:
                        index === trainer.selectedGyms.length - 1 ? 30 : 15,
                    }}
                    inputStyle={{
                      width: '100%',
                      height: 30,
                      cursor: 'pointer',
                    }}
                    disabled
                  />
                </Link>
              );
            })}
            {trainer.certifications.map((certification, index) => {
              return (
                <FittTextInput
                  key={certification}
                  label={index === 0 ? 'Certifications' : undefined}
                  placeholder="Certification"
                  value={certification}
                  style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
                  inputStyle={{ width: '100%', height: 30 }}
                  disabled
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  _renderServices = () => {
    const trainer = this.props.trainer!.getCurrentTrainer()!;
    return (
      <Box style={{ marginBottom: 30 }}>
        <TextNormalBold style={{ marginBottom: 15 }}>Services </TextNormalBold>

        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="Price for a single session"
              placeholder="Price for a single session"
              value={trainer.services.standardSession.price}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            <FittTextInput
              label="Trainer is accepting guest(s)"
              placeholder="Trainer is accepting guest(s)"
              value={
                trainer.services.guestOptions.isAcceptingGuests ? 'Yes' : 'No'
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
              disabled
            />

            {trainer.services.guestOptions.isAcceptingGuests ? (
              <FittTextInput
                label="Price per guest"
                placeholder="Price per guest"
                value={trainer.services.guestOptions.price}
                style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
                inputStyle={{ width: '100%', height: 30 }}
                disabled
              />
            ) : null}

            <Box style={{ width: 150 }}></Box>
          </Box>
          {this._renderPackagedSessions()}
        </Box>
      </Box>
    );
  };

  _renderPackagedSessions = () => {
    const trainer = this.props.trainer!.getCurrentTrainer()!;
    if (trainer.services.packagedSessions.isOfferingPackagedSessions) {
      return (
        <Box flex={1}>
          {trainer.services.packagedSessions.options.map(packageSession => {
            return (
              <FittTextInput
                key={packageSession.serviceType}
                label={`Total price for a package of ${packageSession.nbOfSessions}`}
                placeholder="Total price for the package"
                value={`$${(
                  (packageSession.nbOfSessions * packageSession.priceInCents) /
                  100
                ).toFixed(2)}`}
                style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
                inputStyle={{ width: '100%', height: 30 }}
                disabled
              />
            );
          })}
        </Box>
      );
    }

    return null;
  };

  _onClickGenerateCSV = async () => {
    try {
      const { status } = this.state;
      const { csvURL } = await generateTransactionsCSV({
        status: status.value,
        trainerId: this.props.trainer!.getCurrentTrainer()!.id,
      });
      window.open(csvURL, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  _renderSearchBar = () => {
    return (
      <Box style={{ zIndex: 1000 }}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          style={{ marginBottom: 40 }}
        >
          <TextNormalBold>Trainer's transactions</TextNormalBold>
          <Box flexDirection="row" alignItems="center">
            <TextNormal
              style={{ marginRight: 25, cursor: 'pointer' }}
              onClick={this._onClickGenerateCSV}
            >
              Download csv
            </TextNormal>
          </Box>
        </Box>

        <Box
          mb={50}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <FittTextInput
            placeholder="Search transaction"
            style={{ flex: 1, height: 30 }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={this._onChangeSearchTerm}
          />

          <Box style={{ width: 200, marginLeft: 100 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.status}
              onChange={(status: any) =>
                this.setState(
                  { status, searchLoading: true },
                  this._debouncedFetch,
                )
              }
              options={[
                { value: 'all', label: 'All' },
                { value: 'pending', label: 'Pending' },
                { value: 'accepted', label: 'Accepted' },
                { value: 'completed', label: 'Completed' },
                { value: 'refunded', label: 'Refunded' },
                { value: 'cancelled', label: 'Cancelled' },
                { value: 'expired', label: 'Expired' },
              ]}
              placeholder="Type"
            />
          </Box>
          <Box style={{ width: 200, marginLeft: 100 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.page}
              onChange={(page: any) =>
                this.setState(
                  { page, searchLoading: true },
                  this._debouncedFetch,
                )
              }
              options={Array.from(
                { length: this.props.transaction!.totalTransactionPages },
                (_, i) => ({
                  label: `Page ${i + 1}`,
                  value: i + 1,
                }),
              )}
              placeholder="Pages"
            />
          </Box>
        </Box>
      </Box>
    );
  };

  _renderTransactionSectionBar = () => {
    const trainerTransactions = this.props.trainer!.getTrainerTransactions();
    return this.state.searchLoading ? (
      <FittLoadingScreen />
    ) : (
      <Box>
        <EditTrainerTransactionSectionBar
          sortedBy={this.state.sortedBy}
          onClick={this._onChangeSorting}
        />

        {trainerTransactions.map(transaction => (
          <Link
            key={transaction.id}
            to={`/transaction?id=${transaction.id}`}
            style={{ textDecoration: 'none' }}
          >
            <EditTrainerTransactionItemCell
              transaction={transaction}
              style={{ marginBottom: 2 }}
            />
          </Link>
        ))}
      </Box>
    );
  };

  _renderTypeformState = () => {
    return (
      <Box mb={10} p={45} flex={1}>
        {this._renderHeader()}
        {this._renderTrainerStatus()}
        <TextMedium style={{ color: colors.primary, marginBottom: 20 }}>
          User started the typeform. If he completed the typeform submission,
          you can change his status to "App submission".
        </TextMedium>
        {this._renderUserInformation()}
      </Box>
    );
  };

  _renderShareRate = () => {
    return (
      <Box style={{ marginBottom: 30 }}>
        <TextNormalBold style={{ marginBottom: 15 }}>
          Share rates{' '}
        </TextNormalBold>
        <Box flexDirection="row" alignItems="flex-end">
          <TextNormal style={{ marginBottom: 15, marginRight: 10 }}>
            %
          </TextNormal>
          <FittTextInput
            label={'Company share'}
            value={100 - Number(this.state.trainerShareRate)}
            disabled
            style={{ marginRight: 50 }}
            type="number"
            max={100}
            min={0}
          />

          <TextNormal style={{ marginBottom: 15, marginRight: 10 }}>
            %
          </TextNormal>
          <FittTextInput
            label={'Trainer share'}
            onChangeText={amount => this.setState({ trainerShareRate: amount })}
            value={this.state.trainerShareRate}
            type="number"
            max={100}
            min={0}
          />
        </Box>
      </Box>
    );
  };

  _renderInAppSubmissionState = () => {
    return (
      <Box mb={10} p={45} flex={1}>
        {this._renderHeader()}
        {this._renderTrainerStatus()}
        <TextMedium style={{ color: colors.primary, marginBottom: 20 }}>
          User has completed the Typeform form but has not yet finished the
          in-app submission form.
        </TextMedium>
        {this._renderUserInformation()}
      </Box>
    );
  };

  render() {
    if (this.state.initialLoading) {
      return <FittLoadingScreen />;
    }

    const trainer = this.props.trainer!.getCurrentTrainer()!;

    if (trainer!.status === 'typeform') {
      return this._renderTypeformState();
    }

    if (trainer!.status === 'initial') {
      return this._renderInAppSubmissionState();
    }

    return (
      <Box mb={10} p={45} flex={1}>
        {this._renderHeader()}
        {this._renderTrainerStatus()}
        {this._renderUserInformation()}
        {this._renderMonetaryInformation()}
        {this._renderTrainerInformation()}
        {this._renderShareRate()}
        {this._renderServices()}
        {this._renderSearchBar()}
        {this._renderTransactionSectionBar()}
      </Box>
    );
  }
}
