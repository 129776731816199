import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ATag = ({
  noOpacity,
  href = '',
  ...rest
}: any & { href?: string; noOpacity?: boolean }) => {
  if (href.includes('http') || !href) {
    return <ATagElm noOpacity={noOpacity} href={href} {...rest} />;
  }
  return <ALinkElm noOpacity={noOpacity} to={href} {...rest} />;
};

const ALinkElm = styled(Link)<{ noOpacity?: boolean }>`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    opacity: ${props => (props.noOpacity ? 1 : 0.8)};
  }
`;

const ATagElm = styled.a<{ noOpacity?: boolean }>`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    opacity: ${props => (props.noOpacity ? 1 : 0.8)};
  }
`;

export default ATag;
