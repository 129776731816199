import * as React from 'react';
import { View } from 'react-native';
import { TextNormal } from '../common/Typography';
import { ViewStyle } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import colors from '../../constants/colors';
import { TransactionDocument } from '../../types';

export const colorDict = {
  pending: colors.paleOrange,
  accepted: colors.accent,
  completed: colors.primary,
  cancelled: colors.black,
  expired: colors.error,
  refunded: colors.primary,
};

const TransactionItemCell = ({
  transaction,
  style,
  onClick,
}: {
  transaction: TransactionDocument;
  style?: ViewStyle;
  onClick: (event: any, transaction: any) => void;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  //@ts-ignore
  const color = colorDict[transaction.status];

  const status =
    transaction.status[0].toUpperCase() + transaction.status.slice(1);

  return (
    // @ts-ignore
    <View
      onClick={(event: any) => onClick(event, transaction)}
      ref={ref}
      style={[
        {
          height: 60,
          paddingHorizontal: 10,
          borderWidth: 1,
          borderColor: colors.borderColor,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
          flexDirection: 'row',
          backgroundColor: colors.white,
        },
        isHovered && {
          'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
        },
        style,
      ]}
    >
      <Section label={transaction.user.email} />
      <Section
        withHover
        label={`${transaction.user.firstName} ${transaction.user.lastName}`}
      />
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          paddingLeft: 5,
        }}
      >
        <TextNormal style={{ color: color }}>{status}</TextNormal>
      </View>
      <Section label={transaction.trainer.user.email} />

      <Section
        withHover
        label={`${transaction.trainer.user.firstName} ${transaction.trainer.user.lastName}`}
      />
      <Section
        label={
          transaction.createdAt
            ? new Date(transaction.createdAt).toLocaleString()
            : ''
        }
      />
    </View>
  );
};

export default TransactionItemCell;

export const Section = ({
  label,
  onClick,
  withHover,
  sorted,
}: {
  label: string;
  onClick?: () => void;
  withHover?: boolean;
  sorted?: boolean;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  return (
    <View
      ref={ref}
      // @ts-ignore
      style={[
        { flex: 1, justifyContent: 'center', paddingLeft: 5 },
        isHovered && withHover
          ? {
              // @ts-ignore
              'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
            }
          : null,
      ]}
      // @ts-ignore
      onClick={onClick}
    >
      <TextNormal style={{ fontWeight: sorted ? 'bold' : 'normal' }}>
        {label}
      </TextNormal>
    </View>
  );
};
