/* eslint-disable react/display-name */
import * as React from 'react';
import { View } from 'react-native';
import { TextNormal } from './Typography';
import colors from '../../constants/colors';

export default ({
  style,
  label,
  value,
  onChange,
  disabled,
}: {
  style?: any;
  label?: string;
  value?: boolean;
  onChange?: (newValue: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <View
      // @ts-ignore
      onClick={disabled ? undefined : () => onChange(!value)}
      style={[
        {
          cursor: disabled ? undefined : 'pointer',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        style,
      ]}
    >
      <TextNormal>{label}</TextNormal>
      <View
        style={{
          borderRadius: 5,
          borderWidth: 1,
          borderColor: colors.black,
          backgroundColor: disabled ? 'lightgray' : colors.white,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          height: 20,
          width: 20,
        }}
      >
        {value === true && (
          <View
            style={{
              flex: 1,
              backgroundColor: colors.accent,
              width: '100%',
              height: '100%',
            }}
          />
        )}
      </View>
    </View>
  );
};
