import * as React from 'react';
import { View, StyleSheet } from 'react-native';

class HomeScreen extends React.Component {
  render() {
    return <View style={styles.mainContainer}>{}</View>;
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
});

export default HomeScreen;
