import * as React from 'react';
import { View } from 'react-native';
import { TextNormal } from '../common/Typography';
import { ViewStyle } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import colors from '../../constants/colors';
import { TrainerDocument } from '../../types';

export const colorStatusTrainer = (trainer: TrainerDocument) =>
  trainer.user.isBanned
    ? 'black'
    : trainer.status === 'approved'
    ? 'rgb(23, 157, 142)'
    : trainer.status === 'pending'
    ? 'rgb(241, 93, 30)'
    : trainer.status === 'rejected'
    ? 'rgb(204, 0, 0)'
    : trainer.status === 'vacation'
    ? 'rgb(250, 188, 172)'
    : 'black';

const TrainerItemCell = ({
  trainer, // trainer
  style,
  onClick,
}: {
  trainer: TrainerDocument;
  style?: ViewStyle;
  onClick: (event: any, trainer: any) => void;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  let formattedTrainerStatus =
    trainer.status.charAt(0).toUpperCase() + trainer.status.slice(1);

  if (formattedTrainerStatus === 'Initial') {
    formattedTrainerStatus = 'App submission';
  }

  return (
    // @ts-ignore
    <View
      onClick={(event: any) => onClick(event, trainer)}
      ref={ref}
      style={[
        {
          height: 60,
          paddingHorizontal: 10,
          borderWidth: 1,
          borderColor: colors.borderColor,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
          flexDirection: 'row',
          backgroundColor: colors.white,
        },
        isHovered && {
          'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
        },
        style,
      ]}
    >
      <Section label={trainer.user.email} />
      <Section label={`${trainer.user.firstName} ${trainer.user.lastName}`} />
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          paddingLeft: 5,
        }}
      >
        <TextNormal style={{ color: colorStatusTrainer(trainer) }}>
          {trainer.user.isBanned ? 'Banned' : formattedTrainerStatus}
        </TextNormal>
      </View>
      <Section label={"See User's page"} withHover />
      <Section
        label={
          trainer.createdAt ? new Date(trainer.createdAt).toLocaleString() : ''
        }
      />
    </View>
  );
};

export default TrainerItemCell;

export const Section = ({
  label,
  onClick,
  withHover,
  sorted,
}: {
  label: string;
  onClick?: () => void;
  withHover?: boolean;
  sorted?: boolean;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  return (
    <View
      ref={ref}
      // @ts-ignore
      style={[
        { flex: 1, justifyContent: 'center', paddingLeft: 5 },
        isHovered && withHover
          ? {
              // @ts-ignore
              'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
            }
          : null,
      ]}
      // @ts-ignore
      onClick={onClick}
    >
      <TextNormal style={{ fontWeight: sorted ? 'bold' : 'normal' }}>
        {label}
      </TextNormal>
    </View>
  );
};
