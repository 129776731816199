import * as React from 'react';
import { Box } from 'react-native-kondo';
import { TextNormal } from '../common/Typography';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { Routing } from '../../types';
import { UIStoreType } from '../../stores/ui';
import { formatError } from '../../utils/text';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittTitle from '../common/FittTitle';
import FittTextInput from '../common/FittTextInput';
import FittSelect from '../common/FittSelect';
import DisputeSectionBar from './DisputeSectionBar';
import DisputeItemCell from './DisputeItemCell';
import { DisputeStore } from '../../stores/dispute';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  dispute?: DisputeStore;
}

@inject('ui', 'routing', 'dispute')
@observer
export default class DisputeListingScreen extends React.Component<P> {
  state = {
    loading: false,
    searchTerm: '',
    sortedBy: 'createdAt',
    selectedLanguage: { value: undefined, label: 'any language' },
    isResolved: { value: false, label: 'Unresolved' },
  };

  componentDidMount() {
    this._fetchDisputes();
  }

  _fetchDisputes = async () => {
    try {
      const { searchTerm, sortedBy, isResolved } = this.state;
      this.setState({ loading: true });
      await this.props.dispute!.fetchDisputes({
        searchTerm,
        sort: sortedBy,
        isResolved: isResolved.value,
      });
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _onChangeSearchTerm = (searchTerm: string) => {
    this.setState({ searchTerm, loading: true }, this._debouncedFetch);
  };

  _debouncedFetch = debounce(async () => {
    await this._fetchDisputes();
  }, 500);

  _onChangeSorting = (sortedBy: string) => {
    this.setState({ sortedBy, loading: true }, this._debouncedFetch);
  };

  _handleOnClick = (event: any, dispute: any) => {
    this.props.routing!.push(`/dispute?id=${dispute.id}`);
  };

  render() {
    const disputes = this.props.dispute!.getDisputes();
    return (
      <Box flex={1} p={45}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <FittTitle title={`Dispute management`} />
          </Box>

          {/* <Box flexDirection="row" alignItems="center">
            <TextNormal style={{ marginRight: 25, cursor: 'pointer' }}>
              Download csv
            </TextNormal>
          </Box> */}
        </Box>

        <Box
          mb={50}
          mt={50}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <FittTextInput
            placeholder="Search dispute"
            style={{ flex: 1, height: 30 }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={this._onChangeSearchTerm}
          />

          <Box style={{ width: 200, marginLeft: 100, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.isResolved}
              onChange={(isResolved: any) =>
                this.setState(
                  { isResolved, loading: true },
                  this._debouncedFetch,
                )
              }
              options={[
                { value: false, label: 'Unresolved' },
                { value: true, label: 'Resolved' },
                { value: 'all', label: 'All' },
              ]}
              placeholder="Type"
            />
          </Box>
        </Box>

        {this.state.loading ? (
          <FittLoadingScreen />
        ) : (
          <Box>
            <DisputeSectionBar
              sortedBy={this.state.sortedBy}
              onClick={this._onChangeSorting}
            />

            {disputes.map(dispute => (
              <DisputeItemCell
                key={dispute.id}
                dispute={dispute}
                style={{ marginBottom: 2 }}
                onClick={(event: any, dispute: any) => {
                  this._handleOnClick(event, dispute);
                }}
              />
            ))}
          </Box>
        )}
      </Box>
    );
  }
}
