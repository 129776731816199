import { action } from 'mobx';
import * as api from '../utils/api';

export interface SystemStore {
  updateAPI: () => Promise<void>;
  updateAdmin: () => Promise<void>;
}

class System implements SystemStore {
  @action
  updateAPI = async () => {
    await api.sendSystemCommand({ system: 'api', action: 'update' });
  };

  @action
  updateAdmin = async () => {
    await api.sendSystemCommand({ system: 'admin', action: 'update' });
  };
}

export default new System();
