import * as React from 'react';
import { Box } from 'react-native-kondo';
import { ConversationDocument } from '../../types';
import { TextNormal } from '../common/Typography';
import { TableCellSection } from '../common/AllTable';
import { formatDistance } from 'date-fns';

export function conversationCellSectionRenderer({
  conversation,
}: {
  conversation: ConversationDocument;
}): TableCellSection[] {
  return [
    {
      render: () => (
        <Box>
          <TextNormal>
            {conversation.users[0].firstName} {conversation.users[0].lastName}
          </TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>
            {conversation?.users[1]?.firstName}{' '}
            {conversation?.users[1]?.lastName}
          </TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>{conversation?.messages.length}</TextNormal>
        </Box>
      ),
    },
  ];
}
