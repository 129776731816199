import * as React from 'react';
import { Box } from 'react-native-kondo';
import { observer, inject } from 'mobx-react';
import { Routing } from '../../types';
import { UIStoreType } from '../../stores/ui';
import { TextSmall } from '../common/Typography';

import Button from '../common/Button';

import { formatError } from '../../utils/text';
import { GymStore } from '../../stores/gym';
import CreateScreen from './CreateScreen';
import { autorun, toJS } from 'mobx';
import EditGymPanel from './EditGymPanel';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittTitle from '../common/FittTitle';
import FittBackButton from '../common/FittBackButton';
import { CompanyStore } from '../../stores/company';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  gym?: GymStore;
  company?: CompanyStore;
}

@inject('ui', 'routing', 'gym', 'company')
@observer
export default class CreateOrEditGymScreen extends React.Component<P> {
  state = {
    selectedPlace: null,
    loading: false,
    dataFetchingMethod: 'manual',
    pathname: toJS(this.props.routing!.location.search),
    initialLoading: true,
  };
  _createScreen: CreateScreen | null = null;

  _editScreen: EditGymPanel | null = null;

  _fetching = false;

  componentDidMount() {
    this._fetchGym();

    autorun(() => {
      const pathname = toJS(this.props.routing!.location.search);
      if (pathname !== this.state.pathname && !this._fetching) {
        // this._fetchGym();
      }
    });
  }

  _fetchGym = async () => {
    try {
      this._fetching = true;
      const pathname = toJS(this.props.routing!.location.search);
      this.setState({ initialLoading: true, pathname });
      const urlString = window.location.href;
      const url = new URL(urlString);
      const gymId = url.searchParams.get('id')!;
      if (gymId && gymId.length) {
        this.setState({ dataFetchingMethod: 'manual' });
        await this.props.gym!.fetchGym({ gymId });
      } else {
        this.props.gym!.setCurrentGym(null);
      }
      await this.props.company!.fetchCompanies();
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this._fetching = false;
      this.setState({ initialLoading: false });
    }
  };

  _onClickSave = async () => {
    if (this.props.gym!.getCurrentGym()) {
      await this._saveEdit();
    } else {
      await this._create();
    }
  };

  _saveEdit = async () => {
    try {
      const currentGym = this.props.gym!.getCurrentGym()!;
      this.setState({ loading: true });
      const {
        zipCode,
        address,
        country,
        city,
        name,
        pictures,
        districtManager,
        company,
        manager,
        phone,
        visibility,
        lat,
        lng,
        openingHours,
        amenities,
      } = this._editScreen!.getState();

      const photos: any = pictures.filter((p: any) => p !== null);
      await this.props.gym!.editGym({
        zipCode,
        address,
        country,
        city,
        name,
        pictures: photos,
        gymId: currentGym.id,
        districtManager,
        companyId: company.id,
        manager,
        phone,
        visibility,
        lat,
        lng,
        openingHours,
        amenities,
      });
      this.props.ui!.openToaster({
        text: 'Gym saved!',
      });
    } catch (e) {
      this.props.ui!.openToaster({
        text: formatError(e),
        type: 'error',
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _create = async () => {
    try {
      this.setState({ loading: true });

      if (this.state.dataFetchingMethod === 'auto') {
        await this.props.gym!.createGym({
          // @ts-ignore
          placeId: this.state.selectedPlace!.value.placeId,
        });
      } else {
        const {
          zipCode,
          address,
          country,
          city,
          name,
          pictures,
          districtManager,

          manager,
          phone,
          visibility,
        } = this._createScreen!.getState();
        const photos: any = pictures.filter((p: any) => p !== null);
        await this.props.gym!.createGym({
          zipCode,
          address,
          country,
          city,
          name,
          pictures: photos,
          districtManager,

          manager,
          phone,
          visibility,
        });
      }
      this.props.routing!.push('/gyms');
    } catch (e) {
      this.props.ui!.openToaster({
        text: formatError(e),
        type: 'error',
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    if (this.state.initialLoading) {
      return <FittLoadingScreen />;
    }

    const currentGym = this.props.gym!.getCurrentGym();

    return (
      <Box flex={1} p={45}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={20}
        >
          <FittBackButton
            onClick={() => this.props.routing!.push('/gyms')}
            label="gyms"
          />

          <FittTitle title={currentGym ? 'Gym edition' : 'Gym creation'} />

          <Box>
            <Button
              label="Save"
              disabled={
                this.state.dataFetchingMethod === 'auto'
                  ? !this.state.selectedPlace
                  : false
              }
              onClick={this._onClickSave}
              loading={this.state.loading}
            />
            {!currentGym && (
              <TextSmall style={{ marginTop: 5 }}>
                You'll be able to edit fields once saved
              </TextSmall>
            )}
          </Box>
        </Box>

        {currentGym ? (
          <EditGymPanel getRef={(ref: any) => (this._editScreen = ref)} />
        ) : (
          <CreateScreen
            getRef={(ref: any) => (this._createScreen = ref)}
            onChangeFetchingMethod={dataFetchingMethod =>
              this.setState({ dataFetchingMethod })
            }
            onChangeSelectedPlace={selectedPlace =>
              this.setState({ selectedPlace })
            }
          />
        )}
      </Box>
    );
  }
}
