import * as React from 'react';
import { Box } from 'react-native-kondo';
import { TextNormal } from '../common/Typography';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { Routing, TransactionDocument, UserDocument } from '../../types';
import { UIStoreType } from '../../stores/ui';
import { formatError } from '../../utils/text';
import FittTitle from '../common/FittTitle';
import FittTextInput from '../common/FittTextInput';
import FittSelect from '../common/FittSelect';
import { TransactionStore } from '../../stores/transaction';
import { generateTransactionsCSV } from '../../utils/api';
import AllTable from '../common/AllTable';
import { transactionCellSectionRenderer } from './transactionCellSectionRenderer';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  transaction?: TransactionStore;
  userId?: UserDocument['id'];
  title?: string;
  noCSV?: boolean;
}

@inject('ui', 'routing', 'transaction')
@observer
export default class TransactionListingScreen extends React.Component<P> {
  state = {
    loading: false,
    searchTerm: '',
    sortedBy: 'createdAt',
    selectedLanguage: { value: undefined, label: 'any language' },
    status: { value: 'all', label: 'All status' },
    page: { label: 'Page 1', value: 1 },
    serviceType: { value: 'all', label: 'All service types' } as any,
    year: { value: 'all', label: 'All years' },
    month: { value: 'all', label: 'All months' },
    day: { value: 'all', label: 'All days' },
    userId: this.props.userId,
  };

  componentDidMount() {
    this._fetchTransactions();
  }

  _fetchTransactions = async () => {
    try {
      const {
        searchTerm,
        sortedBy,
        status,
        page,
        serviceType,
        year,
        month,
        day,
        userId,
      } = this.state;
      this.setState({ loading: true });
      await this.props.transaction!.fetchTransactions({
        userId,
        searchTerm,
        sort: sortedBy,
        //@ts-ignore
        status: status.value,
        page: page.value,
        serviceType:
          serviceType.value === 'all' ? undefined : serviceType.value,
        year: year.value === 'all' ? undefined : Number(year.value),
        month:
          month.value === 'all' || year.value === 'all'
            ? undefined
            : Number(month.value),
        day:
          month.value === 'all' || year.value === 'all' || day.value === 'all'
            ? undefined
            : Number(day.value),
      });
    } catch (e) {
      const err = e as Error;
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(err),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  _onChangeSearchTerm = (searchTerm: string) => {
    this.setState(
      { searchTerm, loading: true, page: { label: 'Page 1', value: 1 } },
      this._debouncedFetch,
    );
  };

  _debouncedFetch = debounce(async () => {
    await this._fetchTransactions();
  }, 500);

  _onChangeSorting = (sortedBy: string) => {
    this.setState({ sortedBy, loading: true }, this._debouncedFetch);
  };

  _handleOnClick = (event: any, transaction: any) => {
    this.props.routing!.push(`/transaction?id=${transaction.id}`);
  };

  _onClickGenerateCSV = async () => {
    try {
      const { status, year } = this.state;
      const { csvURL } = await generateTransactionsCSV({
        status: status.value,
        year: year.value === 'all' ? undefined : Number(year.value),
      });
      window.open(csvURL, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const transactions = this.props.transaction!.getTransactions();
    const baseYear = 2019;

    return (
      <Box flex={1} p={45}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <FittTitle title={this.props.title || `Transaction management`} />
          </Box>

          <Box flexDirection="row" alignItems="center">
            {this.props.noCSV ? null : (
              <TextNormal
                style={{ marginRight: 25, cursor: 'pointer' }}
                onClick={this._onClickGenerateCSV}
              >
                Download csv
              </TextNormal>
            )}
          </Box>
        </Box>

        <Box
          mt={50}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 2000 }}
        >
          <FittTextInput
            placeholder="Search transaction"
            style={{ flex: 1, height: 30, maxWidth: 300 }}
            inputStyle={{ width: '100%', height: 30 }}
            onChangeText={this._onChangeSearchTerm}
          />

          <Box style={{ width: 200, marginLeft: 50, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.status}
              onChange={(status: any) =>
                this.setState({ status, loading: true }, this._debouncedFetch)
              }
              options={[
                { value: 'all', label: 'All status' },
                { value: 'pending', label: 'Pending' },
                { value: 'accepted', label: 'Accepted' },
                { value: 'completed', label: 'Completed' },
                { value: 'refunded', label: 'Refunded' },
                { value: 'cancelled', label: 'Cancelled' },
                { value: 'expired', label: 'Expired' },
                { value: 'paid', label: 'Paid' },
              ]}
              placeholder="Type"
            />
          </Box>

          <Box style={{ width: 200, marginLeft: 50, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.serviceType}
              onChange={(serviceType: any) =>
                this.setState(
                  { serviceType, loading: true },
                  this._debouncedFetch,
                )
              }
              options={[
                { value: 'all', label: 'All service types' },
                { value: '1session', label: '1 Session' },
                { value: '3sessions', label: '3 Sessions' },
                { value: '5sessions', label: '5 Sessions' },
                { value: '10sessions', label: '10 Sessions' },
                { value: 'workoutPlan', label: 'Workout Plan' },
              ]}
              placeholder="Type"
            />
          </Box>
          <Box style={{ width: 200, marginLeft: 50, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.page}
              onChange={(page: any) =>
                this.setState({ page, loading: true }, this._debouncedFetch)
              }
              options={Array.from(
                { length: this.props.transaction!.totalTransactionPages },
                (_, i) => ({
                  label: `Page ${i + 1}`,
                  value: i + 1,
                }),
              )}
              placeholder="Pages"
            />
          </Box>
        </Box>

        <Box
          mb={25}
          mt={25}
          flexDirection="row"
          alignItems="center"
          style={{ zIndex: 1000 }}
        >
          <Box style={{ flex: 1, height: 30, maxWidth: 300 }} />
          <Box style={{ width: 200, marginLeft: 50, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.year}
              onChange={(year: any) =>
                this.setState({ year, loading: true }, this._debouncedFetch)
              }
              options={[
                { value: 'all', label: 'all years' },
                ...Array.from(
                  { length: new Date().getFullYear() - baseYear },
                  (_, i) => ({
                    value: baseYear + i + 1,
                    label: String(baseYear + i + 1),
                  }),
                ),
              ]}
              placeholder="Type"
            />
          </Box>
          <Box style={{ width: 200, marginLeft: 50, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.month}
              onChange={(month: any) =>
                this.setState({ month, loading: true }, this._debouncedFetch)
              }
              options={[
                { value: 'all', label: 'All months' },
                ...[
                  'January',
                  'Febuary',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ].map((month, i) => {
                  return {
                    value: String(i),
                    label: month,
                  };
                }),
              ]}
              placeholder="Type"
              isDisabled={this.state.year.value === 'all'}
            />
          </Box>
          <Box style={{ width: 200, marginLeft: 50, zIndex: 1000 }}>
            <FittSelect
              isSearchable={false}
              value={this.state.day}
              onChange={(day: any) =>
                this.setState({ day, loading: true }, this._debouncedFetch)
              }
              options={[
                { value: 'all', label: 'All days' },
                ...Array.from(Array(31), (_, i) => {
                  return { value: String(i + 1), label: String(i + 1) };
                }),
              ]}
              placeholder="Type"
              isDisabled={
                this.state.year.value === 'all' ||
                this.state.month.value === 'all'
              }
            />
          </Box>
        </Box>

        <Box>
          <AllTable<TransactionDocument>
            data={transactions.map(transaction => ({
              itemData: transaction,
              sections: transactionCellSectionRenderer({
                transaction,
              }),
            }))}
            noLoadingIndicator
            isLoading={this.state.loading}
            onClickUrl={`/transaction?id=:id`}
            sections={[
              {
                label: "User's email",
              },
              {
                label: "User's name",
              },
              {
                label: 'State',
              },
              {
                label: "Trainer's email",
              },
              {
                label: "Trainer's name",
              },
              {
                label: 'Created at',
                onClick: () => this._onChangeSorting('createdAt'),
                style: { cursor: 'pointer', alignItems: 'flex-end' },
                selected: this.state.sortedBy === 'createdAt',
              },
            ]}
          />
        </Box>
      </Box>
    );
  }
}
