import * as React from 'react';
import { StyleSheet, Text } from 'react-native';
import { inject, observer } from 'mobx-react';
import { shorten } from '../../utils/text';
import colors from '../../constants/colors';
import { UIStoreType } from '../../stores/ui';

interface P {
  children: React.ReactNode;
  style?: any;
  ui?: UIStoreType;
  onClick?: any;
  maxLength?: number;
}

export const fonts = {
  Roboto: 'Roboto',
  Spartan: 'Spartan',
};

@inject('ui')
@observer
export class TextNormal extends React.Component<P> {
  render() {
    const { style, children, maxLength, ...props } = this.props;
    return (
      <Text style={[styles.normal, style]} {...props}>
        {maxLength ? shorten(String(children), maxLength) : children}
      </Text>
    );
  }
}

@inject('ui')
@observer
export class TextTitle extends React.Component<P> {
  render() {
    const { style, children, ...props } = this.props;
    return (
      <Text style={[styles.title, style]} {...props}>
        {children}
      </Text>
    );
  }
}

@inject('ui')
@observer
export class TextMedium extends React.Component<P> {
  render() {
    const { style, children, maxLength, ...props } = this.props;
    return (
      <Text style={[styles.medium, style]} {...props}>
        {maxLength ? shorten(String(children), maxLength) : children}
      </Text>
    );
  }
}

@inject('ui')
@observer
export class TextNormalBold extends React.Component<P> {
  render() {
    const { style, children, maxLength, ...props } = this.props;
    return (
      <Text
        style={[
          {
            fontFamily: fonts.Spartan,
            color: colors.black,
            fontWeight: 'bold',
          },
          style,
        ]}
        {...props}
      >
        {maxLength ? shorten(String(children), maxLength) : children}
      </Text>
    );
  }
}

@inject('ui')
@observer
export class TextSmall extends React.Component<P> {
  render() {
    const { style, children, maxLength, ...props } = this.props;
    return (
      <Text
        style={[
          {
            fontFamily: fonts.Spartan,
            color: colors.black,
            fontSize: 10,
          },
          style,
        ]}
        {...props}
      >
        {maxLength ? shorten(String(children), maxLength) : children}
      </Text>
    );
  }
}

const styles = StyleSheet.create({
  normal: {
    fontFamily: fonts.Spartan,
    color: colors.black,
  },
  medium: {
    fontFamily: fonts.Spartan,
    color: colors.black,
    fontSize: 20,
  },
  title: {
    fontSize: 24,
    fontFamily: fonts.Spartan,
    fontWeight: 'bold',
    color: colors.black,
    marginBottom: -5,
  },
});
