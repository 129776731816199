import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

import colors from '../../constants/colors';
import { UIStoreType } from '../../stores/ui';
import { TextNormal } from './Typography';

const Input = styled.input`
  height: 50px;
  width: '100%';
  border-style: solid;
  border-width: 2px;
  border-color: #dadada;
  border-radius: 10px;
  font-family: 'Spartan';
  font-size: 16px;
  caret-color: ${colors.black};
  background: ${colors.white};
  color: ${colors.black};
  padding-left: 10px;
  padding-right: 10px;
  &:focus {
    outline: none;
    border-color: ${colors.accent};
  }
  &::-webkit-input-placeholder {
    color: gray;
    font-family: 'Spartan';
    font-size: 16px;
  }
  ${({ inputStyle }: any) => inputStyle};
`;

interface P {
  style?: any;
  ui?: UIStoreType;
  label?: string;
  error?: string;
  onChange?: any;
  value?: any;
  type?: string;
  placeholder?: string;
  onChangeText?: (str: string) => void;
  maxLength?: string;
  min?: number;
  max?: number;
  inputStyle?: any;
  lightLabel?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  getRef?: (ref: any) => void;
  getTextInputRef?: (ref: any) => void;
  loading?: boolean;
}

interface S {
  isFocused: boolean;
}

@inject('ui')
@observer
class FittTextInput extends React.Component<P, S> {
  state = {
    isFocused: false,
  };

  _onFocus = () => {
    this.setState({ isFocused: true }, () => {
      if (this.props.onFocus) {
        this.props.onFocus();
      }
    });
  };

  _onBlur = () => {
    this.setState({ isFocused: false }, () => {
      if (this.props.onBlur) {
        this.props.onBlur();
      }
    });
  };

  render() {
    const { isMobile } = this.props.ui!;

    let inputStyle = {
      width: isMobile ? 280 : undefined,
      minWidth: isMobile ? undefined : 320,
      fontFamily: 'Spartan',
    };

    if (this.props.inputStyle) {
      inputStyle = {
        ...inputStyle,
        ...this.props.inputStyle,
      };
    }

    if (this.props.disabled) {
      inputStyle = {
        ...inputStyle,
        // @ts-ignore
        backgroundColor: 'rgb(230,230,230)',
      };
    }

    return (
      <View
        style={[styles.main, this.props.style]}
        ref={(ref: any) => {
          if (this.props.getRef) {
            this.props.getRef(ref);
          }
        }}
      >
        {this.props.label && (
          <TextNormal style={{ marginBottom: 10, fontSize: 17 }}>
            {this.props.label}
          </TextNormal>
        )}
        <View style={styles.row}>
          <Input
            ref={(ref: any) => {
              if (this.props.getTextInputRef) {
                this.props.getTextInputRef(ref);
              }
            }}
            min={this.props.min}
            max={this.props.max}
            // @ts-ignore
            maxLength={this.props.maxLength}
            onFocus={this._onFocus}
            onBlur={this._onBlur}
            disabled={this.props.disabled}
            value={this.props.value}
            onChange={(e: any) => {
              if (this.props.onChange) {
                this.props.onChange(e);
              }
              if (this.props.onChangeText) {
                if (
                  this.props.type === 'price' ||
                  this.props.type === 'number'
                ) {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    this.props.onChangeText(e.target.value);
                  }
                } else {
                  this.props.onChangeText(e.target.value);
                }
              }
            }}
            type={
              this.props.type === 'price'
                ? this.props.ui!.isMobile
                  ? 'tel'
                  : 'number'
                : this.props.type
            }
            placeholder={this.props.placeholder}
            inputStyle={inputStyle}
            style={{ fontFamily: 'Spartan' }}
          />
        </View>

        {this.props.error && this.props.error.length ? (
          <TextNormal
            style={{
              color: 'red',
              marginTop: 5,
            }}
          >
            {this.props.error}
          </TextNormal>
        ) : null}
        {this.props.loading ? (
          <View
            style={{
              width: 25,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent',
              position: 'absolute',
              right: 15,
              top: 3,
              bottom: 3,
            }}
          >
            <ClipLoader
              sizeUnit={'px'}
              size={16}
              color={colors.primary}
              loading={this.props.loading}
            />
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {},
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default FittTextInput;
