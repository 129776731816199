import * as React from 'react';
import { Box } from 'react-native-kondo';
import { GymDocument } from '../../types';
import { TextNormal } from '../common/Typography';
import { TableCellSection } from '../common/AllTable';

export function gymCellSectionRenderer(gym: GymDocument): TableCellSection[] {
  return [
    {
      render: () => (
        <Box>
          <TextNormal>{gym.name}</TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>{gym.nbOfApprovedTrainers}</TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>{gym.nbOfTimeSelectedByUser}</TextNormal>
        </Box>
      ),
    },
  ];
}
