import * as React from 'react';
import { View } from 'react-native';
import { ViewStyle } from 'react-native';
import colors from '../../constants/colors';
import { Section } from './UserItemCell';

const UserSectionBar = ({
  style,
  sortedBy,
  onClick,
}: {
  style?: ViewStyle;
  sortedBy?: string;
  onClick: (newSort: string) => void;
}) => {
  return (
    <View
      style={[
        {
          height: 60,
          paddingHorizontal: 10,

          borderWidth: 1,
          borderColor: colors.borderColor,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
          flexDirection: 'row',
          backgroundColor: colors.white,
          marginBottom: 10,
        },

        style,
      ]}
    >
      <Section
        label="Email"
        withHover
        sorted={sortedBy === 'email'}
        onClick={() => onClick('email')}
      />
      <Section
        label="Name"
        withHover
        sorted={sortedBy === 'firstName'}
        onClick={() => onClick('firstName')}
      />
      <Section label="Phone" sorted={sortedBy === 'phone'} />
      <Section
        label="Created at"
        withHover
        sorted={sortedBy === 'createdAt'}
        onClick={() => onClick('createdAt')}
      />
    </View>
  );
};

export default UserSectionBar;
