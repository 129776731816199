import * as React from 'react';
import { Box } from 'react-native-kondo';
import { observer, inject } from 'mobx-react';
import { GymDocument, Routing, UserDocument } from '../../types';
import { UIStoreType } from '../../stores/ui';

import { UserStore } from '../../stores/user';
import FittTextInput from '../common/FittTextInput';
import Button from '../common/Button';
import colors from '../../constants/colors';
import { formatError } from '../../utils/text';
import FittBooleanInput from '../common/FittBooleanInput';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittSelect from '../common/FittSelect';
import FittTitle from '../common/FittTitle';
import FittBackButton from '../common/FittBackButton';
import { AdminStore } from '../../stores/admin';
import TransactionListingScreen from '../transaction/TransactionListingScreen';
import { TextNormal } from '../common/Typography';
import AllTable from '../common/AllTable';
import { gymCellSectionRenderer } from '../gym/gymCellSectionRenderer';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  user?: UserStore;
  admin?: AdminStore;
}

function getInitalState(user: UserDocument) {
  return {
    ...user,
    email: user.email,
    role: { value: user.role, label: user.role },
    isVerified: user.emailVerification.isVerified,
    isBanned: user.isBanned,
    language: { value: user.language, label: user.language },
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    dob: user.dob,
  };
}

interface S {
  email: string;
  role: { value: ''; label: '' } | any;
  isVerified: boolean;
  isBanned: boolean;
  language: { value: ''; label: '' } | any;
  firstName: string;
  lastName: string;
  phone: string;
  dob?: string;
  userId: string;
  initialLoading: boolean;
  saveLoading: boolean;
  sendVerificationEmailLoading: boolean;
  sendForgetPasswordEmailLoading: boolean;
  banLoading: boolean;
  setLoginAsThisUserLoading: boolean;
}

@inject('ui', 'routing', 'user', 'admin')
@observer
export default class EditUserPanel extends React.Component<
  P,
  S & Partial<UserDocument>
> {
  state = {
    initialLoading: false,
    email: '',
    role: { value: 'admin', label: 'admin' },
    isVerified: false,
    isBanned: false,
    language: { value: 'en', label: 'en' },
    firstName: '',
    lastName: '',
    dob: '',
    phone: '',
    userId: '',
    saveLoading: false,
    sendVerificationEmailLoading: false,
    sendForgetPasswordEmailLoading: false,
    banLoading: false,
    setLoginAsThisUserLoading: false,
  } as S & Partial<UserDocument>;

  componentDidMount() {
    this._fetchUser();
  }

  _fetchUser = async () => {
    try {
      this.setState({ initialLoading: true });
      const urlString = window.location.href;
      const url = new URL(urlString);
      const userId = url.searchParams.get('id') as string;

      if (userId) {
        this.setState({ userId });
        await this.props.user!.fetchUser({ userId });
        this.setState(getInitalState(this.props.user!.getCurrentUser()!));
      }
    } catch (e) {
      this.props.ui!.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      this.setState({ initialLoading: false });
    }
  };

  _onClickBan = () => {
    const { isBanned } = this.state;
    this.props.ui!.openToaster({
      text: `Are you sure you want to ${isBanned ? 'unban' : 'ban'} this user?`,
      buttons: [
        {
          text: 'yes',
          onClick: async () => {
            this.setState({ isBanned: !isBanned }, () =>
              this._onClickSave('ban'),
            );
          },
        },
        {
          text: 'no',
          onClick: () => this.props.ui!.closeToaster(),
        },
      ],
    });
  };

  _onClickResendVerificationEmail = async () => {
    this.setState({ sendVerificationEmailLoading: true });
    try {
      await this.props.user!.resendVerificationEmail({
        userId: this.props.user!.currentUser!.id,
      });
      this.props.ui!.openToaster({
        text: 'Verification email resent!',
      });
    } catch (e) {
      this.props.ui!.openToaster({
        text: formatError(e),
        type: 'error',
      });
    } finally {
      this.setState({ sendVerificationEmailLoading: false });
    }
  };

  _onClickSendForgetPasswordEmail = async () => {
    this.setState({ sendForgetPasswordEmailLoading: true });
    try {
      await this.props.user!.sendForgetPasswordEmail({
        userId: this.props.user!.currentUser!.id,
      });
      this.props.ui!.openToaster({
        text: 'Forget password email sent!',
      });
    } catch (e) {
      this.props.ui!.openToaster({
        text: formatError(e),
        type: 'error',
      });
    } finally {
      this.setState({ sendForgetPasswordEmailLoading: false });
    }
  };

  _onClickSave = async (action: string) => {
    try {
      if (action === 'save') {
        this.setState({ saveLoading: true });
      } else {
        this.setState({ banLoading: true });
      }
      const {
        email,
        role,
        isVerified,
        isBanned,
        language,
        firstName,
        lastName,
        phone,
      } = this.state;
      await this.props.user!.editUser({
        email,
        role: role.value,
        isVerified,
        isBanned,
        language: language.value,
        firstName,
        lastName,
        phone,
        userId: this.props.user!.currentUser!.id,
      });
      if (action === 'save') {
        this.props.ui!.openToaster({
          text: 'User saved!',
        });
      } else {
        this.props.ui!.openToaster({
          text: isBanned ? 'User banned!' : 'User unbanned',
        });
      }
    } catch (e) {
      this.props.ui!.openToaster({
        text: formatError(e),
        type: 'error',
      });
    } finally {
      this.setState({ saveLoading: false, banLoading: false });
    }
  };

  _getBanButtonBackground = () => {
    if (
      this.state.saveLoading ||
      this.state.sendVerificationEmailLoading ||
      this.state.sendForgetPasswordEmailLoading
    ) {
      return 'gray';
    }
    if (this.state.isBanned) {
      return 'rgb(27,162,97)';
    }
    return colors.error;
  };

  _setLoginAsThisUser = async () => {
    try {
      this.setState({ setLoginAsThisUserLoading: true });
      await this.props.admin!.adminSetLoggedAs({
        userId: this.props.user!.getCurrentUser()!.id,
      });
      this.props.ui!.openToaster({
        text: 'You will now log as this user in the Fittcoach app.',
      });
    } catch (e) {
      this.props.ui!.openToaster({
        text: formatError(e),
        type: 'error',
      });
    } finally {
      this.setState({ setLoginAsThisUserLoading: false });
    }
  };

  render() {
    if (this.state.initialLoading) {
      return <FittLoadingScreen />;
    }

    return (
      <Box mb={10} p={45} flex={1}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={20}
        >
          <FittBackButton
            onClick={() => this.props.routing!.push('/users')}
            label="users"
          />

          <FittTitle title="Edit user" />

          <Box>
            <Button
              label="Save"
              onClick={() => this._onClickSave('save')}
              loading={this.state.saveLoading}
              disabled={
                this.state.banLoading ||
                this.state.sendVerificationEmailLoading ||
                this.state.sendForgetPasswordEmailLoading
              }
              style={{ width: 240 }}
            />
          </Box>
        </Box>
        <Box flexDirection="row">
          <Box flex={1} mr={40}>
            <FittTextInput
              label="First Name"
              placeholder="First name"
              value={this.state.firstName}
              onChangeText={firstName => this.setState({ firstName })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />

            <FittTextInput
              label="Email"
              placeholder="Email"
              value={this.state.email}
              onChangeText={email => this.setState({ email })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            <FittSelect
              label="Role"
              onChange={(role: any) => this.setState({ role })}
              options={[
                { value: 'admin', label: 'admin' },
                { value: 'normal', label: 'normal' },
              ]}
              value={this.state.role}
              style={{ marginBottom: 10 }}
            />
            <FittTextInput
              label="Birthdate"
              placeholder="N/A"
              disabled
              value={
                this.state.dob?.length
                  ? new Date(this.state.dob).toLocaleDateString()
                  : this.state.dob
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            <FittTextInput
              label="App version"
              placeholder="N/A"
              disabled
              value={
                this.state.platform && this.state.appVersion
                  ? `${this.state.platform.toUpperCase()} - ${
                      this.state.appVersion
                    }`
                  : 'N/A'
              }
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />

            <Box style={{ width: 150 }}>
              <FittBooleanInput
                label="User is verified"
                onChange={() =>
                  this.setState({ isVerified: !this.state.isVerified })
                }
                value={this.state.isVerified}
              />
            </Box>
          </Box>
          <Box flex={1}>
            <FittTextInput
              label="Last Name"
              placeholder="Last name"
              value={this.state.lastName}
              onChangeText={lastName => this.setState({ lastName })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />
            <FittSelect
              label="Device language"
              onChange={(language: any) => this.setState({ language })}
              options={[
                { value: 'fr', label: 'fr' },
                { value: 'en', label: 'en' },
              ]}
              value={this.state.language}
              style={{ marginBottom: 10 }}
            />
            <FittTextInput
              label="Phone"
              placeholder="Phone"
              value={this.state.phone}
              onChangeText={phone => this.setState({ phone })}
              style={{ marginRight: 10, width: '100%', marginBottom: 15 }}
              inputStyle={{ width: '100%', height: 30 }}
            />

            {this.state.notificationPreferences ? (
              <>
                <TextNormal
                  style={{ marginBottom: 20, fontSize: 17, marginTop: 10 }}
                >
                  Notification preferences
                </TextNormal>

                {Object.keys(this.state.notificationPreferences).map(
                  (key: any) => (
                    <FittBooleanInput
                      key={key}
                      label={key}
                      style={{ maxWidth: 200, marginBottom: 15 }}
                      disabled
                      // @ts-ignore
                      value={this.state.notificationPreferences[key]}
                    />
                  ),
                )}
              </>
            ) : null}
          </Box>
        </Box>
        <Box
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
          mb={20}
          mt={150}
        >
          <Box>
            <Button
              label="Conversations"
              style={{
                width: 240,
              }}
              onClick={() =>
                this.props.routing!.push(
                  `/conversations?userId=${this.state.userId}`,
                )
              }
            />

            <Button
              label="Resend verification email"
              style={{
                backgroundColor:
                  this.state.banLoading ||
                  this.state.saveLoading ||
                  this.state.sendForgetPasswordEmailLoading
                    ? 'gray'
                    : '#00a393',
                marginTop: 10,
                width: 240,
              }}
              onClick={this._onClickResendVerificationEmail}
              loading={this.state.sendVerificationEmailLoading}
              disabled={
                this.state.banLoading ||
                this.state.saveLoading ||
                this.state.sendForgetPasswordEmailLoading
              }
            />
            <Button
              label="Send forget password email"
              style={{
                backgroundColor:
                  this.state.banLoading ||
                  this.state.saveLoading ||
                  this.state.sendVerificationEmailLoading
                    ? 'gray'
                    : '#00a393',
                marginTop: 10,
                width: 240,
              }}
              onClick={this._onClickSendForgetPasswordEmail}
              loading={this.state.sendForgetPasswordEmailLoading}
              disabled={
                this.state.banLoading ||
                this.state.saveLoading ||
                this.state.sendVerificationEmailLoading
              }
            />
            <Button
              label="Login as this user"
              style={{
                backgroundColor:
                  this.state.banLoading ||
                  this.state.saveLoading ||
                  this.state.sendVerificationEmailLoading
                    ? 'gray'
                    : 'rgb(118,117,180)',
                marginTop: 10,
                width: 240,
              }}
              onClick={this._setLoginAsThisUser}
              loading={this.state.setLoginAsThisUserLoading}
              disabled={
                this.state.banLoading ||
                this.state.saveLoading ||
                this.state.sendVerificationEmailLoading
              }
            />
          </Box>
          <Button
            label={this.state.isBanned ? 'Unban user' : 'Ban user'}
            style={{
              width: 240,
              backgroundColor: this._getBanButtonBackground(),
            }}
            onClick={this._onClickBan}
            loading={this.state.banLoading}
            disabled={
              this.state.saveLoading ||
              this.state.sendVerificationEmailLoading ||
              this.state.sendForgetPasswordEmailLoading
            }
          />
        </Box>

        <Box height={2} bg={colors.borderColor} mt={50} mb={10} />
        <TextNormal style={{ marginBottom: 20, fontSize: 17, marginTop: 10 }}>
          Selected Gyms ({this.state.gyms?.length})
        </TextNormal>

        <AllTable<GymDocument>
          data={this.state.gyms?.map(gym => ({
            itemData: gym,
            sections: gymCellSectionRenderer(gym),
          }))}
          style={{ maxHeight: 400 }}
          noLoadingIndicator
          onClickUrl={`/gym?id=:id`}
          sections={[
            {
              label: 'Name',
            },
          ]}
        />

        <Box height={2} bg={colors.borderColor} mt={50} mb={10} />

        {this.state.userId ? (
          <TransactionListingScreen
            userId={this.state.userId}
            title={"User's transactions"}
            noCSV
          />
        ) : null}
      </Box>
    );
  }
}
