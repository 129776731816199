import { observable, action, toJS } from 'mobx';

import * as api from '../utils/api';
import { Id, DisputeDocument } from '../types';

export interface EditDisputePayload {
  isResolved: DisputeDocument['isResolved'];
  adminNotes: DisputeDocument['adminNotes'];
  disputeId: Id;
}

export interface DisputeStore {
  totalDisputes: number;
  totalDisputePages: number;
  currentDispute: DisputeDocument | null;
  disputes: DisputeDocument[];

  fetchDisputes: (
    payload: {
      searchTerm?: string;
      sort?: string;
      isResolved?: boolean | 'all';
    } | void,
  ) => Promise<void>;
  fetchDispute: (payload: { disputeId: Id }) => Promise<void>;
  getDisputes: () => DisputeDocument[];
  getCurrentDispute: () => DisputeDocument | null;
  editDispute: (payload: EditDisputePayload) => Promise<void>;
}

class Dispute implements DisputeStore {
  @observable disputes: DisputeStore['disputes'] = [];
  @observable totalDisputes: DisputeStore['totalDisputes'] = 0;
  @observable totalDisputePages: DisputeStore['totalDisputePages'] = 1;
  @observable currentDispute: DisputeStore['currentDispute'] = null;

  @action
  fetchDispute: DisputeStore['fetchDispute'] = async payload => {
    const dispute = await api.getDispute(payload);
    this.currentDispute = dispute;
  };

  getCurrentDispute: DisputeStore['getCurrentDispute'] = () => {
    if (this.currentDispute) {
      return toJS(this.currentDispute);
    }
    return null;
  };

  @action
  editDispute: DisputeStore['editDispute'] = async payload => {
    await api.editDispute(payload);
  };

  @action
  fetchDisputes: DisputeStore['fetchDisputes'] = async payload => {
    const disputes = await api.getDisputes(payload);
    this.disputes = disputes;
  };

  getDisputes: DisputeStore['getDisputes'] = () => {
    return toJS(this.disputes);
  };
}

const disputeObj = new Dispute();

export default disputeObj;
