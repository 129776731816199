import * as React from 'react';
import { Box } from 'react-native-kondo';
import { TrainerDocument, TransactionDocument } from '../../types';
import { TextNormal } from '../common/Typography';
import { TableCellSection } from '../common/AllTable';
import { colorStatusTrainer } from './TrainerItemCell';

export function trainerCellSectionRenderer({
  trainer,
}: {
  trainer: TrainerDocument;
}): TableCellSection[] {
  const color = colorStatusTrainer(trainer);

  let formattedTrainerStatus =
    trainer.status.charAt(0).toUpperCase() + trainer.status.slice(1);

  if (formattedTrainerStatus === 'Initial') {
    formattedTrainerStatus = 'App submission';
  }

  return [
    {
      render: () => (
        <Box>
          <TextNormal>{trainer.user.email}</TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>
            {trainer.user.firstName} {trainer.user.lastName}
          </TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal style={{ color: color }}>
            {formattedTrainerStatus}
          </TextNormal>
        </Box>
      ),
    },
    {
      style: { alignItems: 'flex-end' },
      render: () => (
        <Box>
          <TextNormal style={{ textAlign: 'right' }}>
            {trainer.createdAt
              ? new Date(trainer.createdAt).toLocaleString()
              : ''}
          </TextNormal>
        </Box>
      ),
    },
  ];
}
