import * as React from 'react';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import { Communique } from '../../sharedTypes';
import { useUiStore } from '../../stores/ui';
import {
  getCommunique,
  updateCommunique,
  deleteCommunique,
} from '../../utils/api';
import Button from '../common/Button';
import FittLoadingScreen from '../common/FittLoadingScreen';
import FittTextArea from '../common/FittTextArea';
import FittTextInput from '../common/FittTextInput';
import FittTitle from '../common/FittTitle';
import { TextNormal, TextNormalBold } from '../common/Typography';

const CommuniqueScreen = () => {
  const uiStore = useUiStore();

  const [englishTitle, setEnglishTitle] = React.useState('');
  const [englishBody, setEnglishBody] = React.useState('');

  const [frenchTitle, setFrenchTitle] = React.useState('');
  const [frenchBody, setFrenchBody] = React.useState('');

  const [currentCommunique, setCurrentCommunique] = React.useState<
    Communique
  >();

  const [loading, setLoading] = React.useState(true);

  const onPressSave = () => {
    uiStore.openAlert({
      title: 'Warning',
      text:
        'Are you sure you want to save this new communique? The old one will be replaced.',
      buttons: [
        {
          label: 'yes',
          onClick: save,
        },
      ],
    });
  };

  const onPressDelete = () => {
    uiStore.openAlert({
      title: 'Warning',
      text: 'Are you sure you want to delete the current communique?',
      buttons: [
        {
          label: 'yes',
          onClick: delCommunique,
        },
      ],
    });
  };

  React.useEffect(() => {
    const init = async () => {
      const res = await getCommunique();
      if (res && res.communique) {
        setCurrentCommunique(res.communique);
      }
      setLoading(false);
    };
    init();
  }, []);

  const save = async () => {
    uiStore.closeAlert();
    setLoading(true);
    await updateCommunique({
      title: {
        en: englishTitle,
        fr: frenchTitle,
      },
      body: {
        en: englishBody,
        fr: frenchBody,
      },
    });
    const res = await getCommunique();
    if (res && res.communique) {
      setCurrentCommunique(res.communique);
    }
    setLoading(false);
  };

  const delCommunique = async () => {
    uiStore.closeAlert();
    setLoading(true);
    await deleteCommunique();
    setCurrentCommunique(undefined);
    setLoading(false);
  };

  if (loading) {
    return <FittLoadingScreen />;
  }

  return (
    <Box p={45}>
      <Box flexDirection="row" style={{ marginBottom: 50 }}>
        <FittTitle title="Communique" />
      </Box>

      <Box flexDirection="row" alignItems="flex-end" mb={40}>
        <FittTextInput
          label={'English title'}
          value={englishTitle}
          style={{ marginRight: 50 }}
          onChangeText={setEnglishTitle}
        />

        <FittTextInput
          label={'French title'}
          onChangeText={setFrenchTitle}
          value={frenchTitle}
        />
      </Box>

      <Box flexDirection="row" alignItems="flex-end" mb={40}>
        <FittTextArea
          label={'English body'}
          value={englishBody}
          style={{ marginRight: 50 }}
          onChangeText={setEnglishBody}
        />

        <FittTextArea
          label={'French body'}
          onChangeText={setFrenchBody}
          value={frenchBody}
        />
      </Box>

      <Box flexDirection="row" mb={50}>
        <Button
          onClick={onPressSave}
          label="Save"
          disabled={
            !englishTitle || !frenchTitle || !englishBody || !frenchBody
          }
          style={{ marginRight: 400 }}
        />
        {currentCommunique && (
          <Button
            onClick={onPressDelete}
            label="Delete current Communique"
            style={{ backgroundColor: colors.error }}
          />
        )}
      </Box>

      <Box height={1} bg={colors.borderColor} />

      {currentCommunique && (
        <Box>
          <Box flexDirection="row" style={{ marginBottom: 50, marginTop: 50 }}>
            <FittTitle title="Current Communique" />
          </Box>
          <TextNormalBold
            style={{ color: colors.paleOrange, marginBottom: 15 }}
          >
            English version
          </TextNormalBold>
          <TextNormalBold>{currentCommunique.title.en}</TextNormalBold>
          <TextNormal>{currentCommunique.body.en}</TextNormal>
          <Box height={1} bg={colors.borderColor} my={20} />
          <TextNormalBold
            style={{ color: colors.paleOrange, marginBottom: 15 }}
          >
            French version
          </TextNormalBold>
          <TextNormalBold>{currentCommunique.title.fr}</TextNormalBold>
          <TextNormal>{currentCommunique.body.fr}</TextNormal>
        </Box>
      )}
    </Box>
  );
};

export default CommuniqueScreen;
