import React, { useRef } from 'react';
import { View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { ClipLoader } from 'react-spinners';

import colors from '../../constants/colors';
import { TextNormal } from './Typography';

export default function({
  label,
  style,
  onClick,
  disabled,
  loading,
  inverted,
  displayClose,
}: {
  label?: string;
  style?: any;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  inverted?: boolean;
  displayClose?: boolean;
}) {
  const ref = useRef(null);

  const isHovered = useHover(ref);

  return (
    <div onClick={disabled || loading ? () => null : onClick}>
      <View
        ref={ref}
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 2,
            borderColor: inverted ? colors.primary : colors.white,
            backgroundColor: disabled ? 'gray' : colors.primary,
            // @ts-ignore
            cursor: 'pointer',
            borderRadius: 10,
            minHeight: 44,
            paddingHorizontal: 10,
            minWidth: 182,
            // @ts-ignore
            'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
          },
          isHovered && !disabled && !loading
            ? { backgroundColor: colors.secondary }
            : {},
          inverted ? { backgroundColor: colors.white } : {},
          displayClose
            ? {
                flexDirection: 'row',
                justifyContent: 'space-between',
                minWidth: 0,
                borderRadius: 50,
              }
            : {},
          style,
        ]}
      >
        {label && !loading ? (
          <TextNormal
            style={{
              fontWeight: 'bold',
              color: inverted ? colors.black : colors.white,
            }}
          >
            {label}
          </TextNormal>
        ) : null}
        {loading && (
          <ClipLoader sizeUnit={'px'} size={16} color={colors.white} loading />
        )}
        {displayClose && (
          <img
            // @ts-ignore
            style={{ width: 15, height: 15, marginLeft: 10 }}
            src={require('./images/close@3x.png')}
          />
        )}
      </View>
    </div>
  );
}
