import * as React from 'react';
import { Box } from 'react-native-kondo';
import { View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { TextNormal } from '../common/Typography';
import colors from '../../constants/colors';
import { UserStore } from '../../stores/user';
import { inject, observer } from 'mobx-react';
import { Routing } from '../../types';
import metrics from '../../constants/metrics';

interface P {
  user?: UserStore;
  routing?: Routing;
}

@inject('user', 'routing')
@observer
export default class SideMenu extends React.Component<P> {
  state = {};

  render() {
    if (!this.props.user!.token) {
      return null;
    }

    const { pathname } = this.props.routing!.location;

    return (
      <>
        <Box width={150} />
        <Box
          width={150}
          bg={colors.white}
          // @ts-ignore
          style={{
            borderRightWidth: 1,
            borderColor: colors.borderColor,
            position: 'fixed',
            bottom: 0,
            justifyContent: 'space-between',
            top: metrics.navBarHeight,
            // @ts-ignore
            'box-shadow': '1px -1px 2px #FFFFFF, inset -2px 2px 6px #D9D9D9',
          }}
          p={20}
        >
          <Box>
            <MenuButton
              label="Users"
              selected={pathname.toLowerCase().includes('user')}
              onClick={() => this.props.routing!.push('/users')}
            />
            <MenuButton
              label="Companies"
              selected={pathname.toLowerCase().includes('companies')}
              onClick={() => this.props.routing!.push('/companies')}
            />
            <MenuButton
              label="Gyms"
              selected={pathname.toLowerCase().includes('gym')}
              onClick={() => this.props.routing!.push('/gyms')}
            />
            <MenuButton
              label="Trainers"
              selected={pathname.includes('/fittcoaches')}
              onClick={() => this.props.routing!.push('/fittcoaches')}
            />
            <MenuButton
              label="Transactions"
              selected={pathname.includes('/transactions')}
              onClick={() => this.props.routing!.push('/transactions')}
            />
            <MenuButton
              label="Disputes"
              selected={pathname.includes('/disputes')}
              onClick={() => this.props.routing!.push('/disputes')}
            />
            <MenuButton
              label="Notifications"
              selected={pathname.includes('/notifications')}
              onClick={() => this.props.routing!.push('/notifications')}
            />
            <MenuButton
              label="Promo codes"
              selected={pathname.includes('/promocodes')}
              onClick={() => this.props.routing!.push('/promocodes')}
            />
            <MenuButton
              label="Share rates"
              selected={pathname.includes('/share')}
              onClick={() => this.props.routing!.push('/shares')}
            />
            <MenuButton
              label="Communique"
              selected={pathname.includes('/communique')}
              onClick={() => this.props.routing!.push('/communique')}
            />
          </Box>
          <Box>
            <MenuButton
              label="Database"
              selected={pathname.includes('/database')}
              onClick={() => this.props.routing!.push('/database')}
            />
            <MenuButton
              label="Systems"
              selected={pathname.includes('/systems')}
              onClick={() => this.props.routing!.push('/systems')}
            />
          </Box>
        </Box>
      </>
    );
  }
}

const MenuButton = ({
  label,
  selected,
  onClick,
}: {
  label: string;
  selected?: boolean;
  onClick?: () => void;
}) => {
  const ref = React.useRef(null);
  const isHovered = useHover(ref);
  return (
    <View
      // @ts-ignore
      onClick={onClick}
      ref={ref}
      style={{
        // @ts-ignore
        cursor: 'pointer',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
      }}
    >
      <Box
        style={{ marginRight: 5 }}
        height={20}
        bg={selected ? colors.primary : colors.white}
        width={2}
      />
      <TextNormal
        style={{
          color: isHovered || selected ? colors.accent : colors.black,
          fontWeight: 'bold',
        }}
      >
        {label}
      </TextNormal>
    </View>
  );
};
