import { observable, action, toJS } from 'mobx';

import * as api from '../utils/api';
import { Id, TrainerDocument, TransactionDocument } from '../types';

export interface EditTrainerPayload {
  status: TrainerDocument['status'];
  trainerId: Id;
  customShareRate: TrainerDocument['customShareRate'];
}

export interface TrainerStore {
  totalTrainers: number;
  totalTrainerPages: number;
  currentTrainer: TrainerDocument | null;
  trainers: TrainerDocument[];

  totalTrainerTransactions: number;
  totalTrainerTransactionPages: number;
  trainerTransactions: TransactionDocument[];

  fetchTrainers: (
    payload: {
      searchTerm?: string;
      page?: number;
      sort?: string;
      language?: 'en' | 'fr';
      status?:
        | 'all'
        | 'approved'
        | 'pending'
        | 'rejected'
        | 'banned'
        | 'vacation'
        | 'initial';
    } | void,
    gymId?: Id | 'any',
  ) => Promise<void>;
  fetchTrainer: (payload: { trainerId: Id }) => Promise<void>;
  getTrainers: () => TrainerDocument[];
  getCurrentTrainer: () => TrainerDocument | null;
  setCurrentTrainer: (trainer: TrainerDocument | null) => void;
  editTrainer: (payload: EditTrainerPayload) => Promise<void>;
  fetchTrainerTransactions: (
    payload: {
      trainerId: Id;
      searchTerm?: string;
      sort?: string;
      status?:
        | 'all'
        | 'pending'
        | 'accepted'
        | 'completed'
        | 'cancelled'
        | 'expired';
      page?: number;
    } | void,
  ) => Promise<void>;
  getTrainerTransactions: () => TransactionDocument[];
}

class Trainer implements TrainerStore {
  @observable trainers: TrainerStore['trainers'] = [];
  @observable totalTrainers: TrainerStore['totalTrainers'] = 0;
  @observable totalTrainerPages: TrainerStore['totalTrainerPages'] = 1;
  @observable currentTrainer: TrainerStore['currentTrainer'] = null;

  @observable trainerTransactions: TrainerStore['trainerTransactions'] = [];
  @observable
  totalTrainerTransactions: TrainerStore['totalTrainerTransactions'] = 0;
  @observable
  totalTrainerTransactionPages: TrainerStore['totalTrainerTransactionPages'] = 1;

  @action
  fetchTrainer: TrainerStore['fetchTrainer'] = async payload => {
    const trainer = await api.getTrainer(payload);
    this.currentTrainer = trainer;
  };

  @action
  getCurrentTrainer: TrainerStore['getCurrentTrainer'] = () => {
    if (this.currentTrainer) {
      return toJS(this.currentTrainer);
    }
    return null;
  };

  @action
  editTrainer: TrainerStore['editTrainer'] = async payload => {
    await api.editTrainer(payload);
  };

  @action
  setCurrentTrainer: TrainerStore['setCurrentTrainer'] = trainer => {
    this.currentTrainer = trainer;
  };

  @action
  fetchTrainers: TrainerStore['fetchTrainers'] = async payload => {
    const { trainers, totalDocs, totalPages } = await api.getTrainers(payload);
    this.trainers = trainers;
    this.totalTrainers = totalDocs;
    this.totalTrainerPages = totalPages;
  };

  @action
  getTrainers: TrainerStore['getTrainers'] = () => {
    return this.trainers.slice();
  };

  @action
  fetchTrainerTransactions: TrainerStore['fetchTrainerTransactions'] = async payload => {
    const { transactions, totalDocs, totalPages } = await api.getTransactions(
      payload,
    );
    this.trainerTransactions = transactions;
    this.totalTrainerTransactions = totalDocs;
    this.totalTrainerTransactionPages = totalPages;
  };

  @action
  getTrainerTransactions: TrainerStore['getTrainerTransactions'] = () => {
    if (this.trainerTransactions) {
      return toJS(this.trainerTransactions);
    }
    return [];
  };
}

const trainerObj = new Trainer();

export default trainerObj;
