import * as React from 'react';
import { View } from 'react-native';
import { UserDocument } from '../../types';
import { TextNormal } from '../common/Typography';
import { ViewStyle } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import colors from '../../constants/colors';

const UserItemCell = ({
  user,
  style,
  onClick,
}: {
  user: UserDocument;
  style?: ViewStyle;
  onClick?: () => void;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  return (
    // @ts-ignore
    <View
      onClick={onClick}
      ref={ref}
      style={[
        {
          height: 60,
          paddingHorizontal: 10,

          borderWidth: 1,
          borderColor: colors.borderColor,
          borderRadius: 10,
          // @ts-ignore
          cursor: 'pointer',
          flexDirection: 'row',
          backgroundColor: colors.white,
        },
        isHovered && {
          'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
        },
        style,
      ]}
    >
      <Section label={user.email} />
      <Section label={`${user.firstName} ${user.lastName}`} />
      <Section label={user.phone} />
      <Section
        label={user.createdAt ? new Date(user.createdAt).toLocaleString() : ''}
      />
    </View>
  );
};

export default UserItemCell;

export const Section = ({
  label,
  onClick,
  withHover,
  sorted,
}: {
  label: string;
  onClick?: () => void;
  withHover?: boolean;
  sorted?: boolean;
}) => {
  const ref = React.useRef(null);

  const isHovered = useHover(ref);

  return (
    <View
      ref={ref}
      // @ts-ignore
      style={[
        { flex: 1, justifyContent: 'center', paddingLeft: 5 },
        isHovered && withHover
          ? {
              // @ts-ignore
              'box-shadow': '-2px 6px 7px -5px rgba(92,92,92,1)',
            }
          : null,
      ]}
      // @ts-ignore
      onClick={onClick}
    >
      <TextNormal style={{ fontWeight: sorted ? 'bold' : 'normal' }}>
        {label}
      </TextNormal>
    </View>
  );
};
