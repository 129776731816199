import * as React from 'react';
import { Box } from 'react-native-kondo';
import { CompanyDocument } from '../../types';
import { TextNormal } from '../common/Typography';
import { TableCellSection } from '../common/AllTable';

export function companyDocumentCellSectionRenderer(
  company: CompanyDocument,
): TableCellSection[] {
  return [
    {
      render: () => (
        <Box>
          <TextNormal>{company.name}</TextNormal>
        </Box>
      ),
    },
  ];
}
