import * as React from 'react';
import { Box } from 'react-native-kondo';
import { TransactionDocument } from '../../types';
import { TextNormal } from '../common/Typography';
import { TableCellSection } from '../common/AllTable';
import { colorDict } from './TransactionItemCell';

export function transactionCellSectionRenderer({
  transaction,
}: {
  transaction: TransactionDocument;
}): TableCellSection[] {
  // @ts-ignore
  const color = colorDict[transaction.status];

  return [
    {
      render: () => (
        <Box>
          <TextNormal>{transaction.user.email}</TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>
            {transaction.user.firstName} {transaction.user.lastName}
          </TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal style={{ color: color }}>{transaction.status}</TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>{transaction.trainer.user.email}</TextNormal>
        </Box>
      ),
    },
    {
      render: () => (
        <Box>
          <TextNormal>
            {transaction.trainer.user.firstName}{' '}
            {transaction.trainer.user.lastName}
          </TextNormal>
        </Box>
      ),
    },
    {
      style: { alignItems: 'flex-end' },
      render: () => (
        <Box>
          <TextNormal style={{ textAlign: 'right' }}>
            {transaction.createdAt
              ? new Date(transaction.createdAt).toLocaleString()
              : ''}
          </TextNormal>
        </Box>
      ),
    },
  ];
}
