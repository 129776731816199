import * as React from 'react';
import { Box } from 'react-native-kondo';
import { observer, inject } from 'mobx-react';
import { Routing } from '../../types';
import { UIStoreType } from '../../stores/ui';
import debounce from 'lodash.debounce';
import { searchLocation } from '../../utils/api';
import FittTextInput from '../common/FittTextInput';
import { GymStore } from '../../stores/gym';
import FittSelect from '../common/FittSelect';

interface P {
  ui?: UIStoreType;
  routing?: Routing;
  gym?: GymStore;
  onChangeSelectedPlace?: any;
}

@inject('user', 'ui', 'routing', 'gym')
@observer
export default class CreateAutoPanel extends React.Component<P> {
  state = {
    googleSearchResults: [],
    searchTerm: '',
    selectedPlace: null,
    searchLoading: false,
    loading: false,
    dataFetchingMethod: 'auto',
  };

  _handleChange = (selectedPlace: any) => {
    this.props.onChangeSelectedPlace(selectedPlace);
    this.setState({ selectedPlace });
  };

  _onChangeSearchWord = (searchTerm: any) => {
    this.setState(
      {
        searchTerm,
        searchLoading: true,
      },
      this._searchAddress,
    );
  };

  _searchAddress = debounce(async () => {
    const { searchTerm } = this.state;

    if (!searchTerm.length) {
      return;
    }

    try {
      const results = await searchLocation({
        searchTerm,
      });
      const googleSearchResults = results.map((r: any) => ({
        label: r.description,
        value: r,
      }));

      if (googleSearchResults.length) {
        this.setState({ googleSearchResults });
      }
    } catch (e) {
      // this.props.ui!.openToaster({
      //   type: 'error',
      //   text: formatError(e),
      // });
    } finally {
      this.setState({ searchLoading: false });
    }
  }, 1000);

  render() {
    return (
      <Box>
        <Box flexDirection="row" alignItems="center" mb={10}>
          <FittTextInput
            placeholder="Search a gym by name or address"
            value={this.state.searchTerm}
            onChangeText={this._onChangeSearchWord}
            style={{ marginRight: 10, width: '100%' }}
            inputStyle={{ width: '100%', height: 30 }}
            loading={this.state.searchLoading}
          />
        </Box>

        {this.state.searchTerm.length ? (
          <FittSelect
            value={this.state.selectedPlace}
            onChange={this._handleChange}
            options={this.state.googleSearchResults}
            placeholder="Select a gym result"
            isSearchable={false}
          />
        ) : null}

        {this.state.selectedPlace && (
          <Box mt={10}>
            <FittTextInput
              // @ts-ignore
              value={this.state.selectedPlace!.value.formattedAddress}
              disabled
              style={{ width: '100%' }}
              inputStyle={{ width: '100%', height: 30 }}
            />
          </Box>
        )}
      </Box>
    );
  }
}
