import * as React from 'react';
import { Box } from 'react-native-kondo';
import { ClipLoader } from 'react-spinners';
import colors from '../../constants/colors';

const FittLoadingScreen = () => (
  <Box flex={1} alignItems="center" justifyContent="center">
    <ClipLoader
      sizeUnit={'px'}
      size={64}
      color={colors.primary}
      loading={true}
    />
  </Box>
);

export default FittLoadingScreen;
