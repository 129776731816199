const dev = {
  // apiURL: 'http://localhost:1337',

  apiURL: 'https://fittcoachstaging.appandflow.com',

  // This is Paul's IP Desktop
  //apiURL: 'http://192.168.128.7:1337',

  // This is Paul's IP Laptop
  //apiURL: 'http://192.168.2.163:1337',

  // apiURL: 'http://localhost:1337',
  // apiURL: 'http://ec2-35-183-35-248.ca-central-1.compute.amazonaws.com:1337',
  env: 'dev',

  websiteURL: 'https://fittcoach.vercel.app',
  chromeExtensionURL:
    'https://chrome.google.com/webstore/detail/ignore-x-frame-headers/gleekbfjekiniecknbkamfmkohkpodhe/related',
  databaseDashboardURL:
    'https://cloud.mongodb.com/v2/5e38c183a6f239352b77d8f1#metrics/replicaSet/5e470de0014b763774d573e1/explorer',
};

const prod = {
  apiURL: 'https://fittcoachprod.appandflow.com',
  env: 'production',
  websiteURL: 'https://fittcoach.vercel.app',
  databaseDashboardURL: dev.databaseDashboardURL,
  chromeExtensionURL: dev.chromeExtensionURL,
};

const config = prod;

export default config;
