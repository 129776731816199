import * as React from 'react';
import { Box } from 'react-native-kondo';
import { Image, ViewStyle } from 'react-native';
import { CircularProgress } from '@material-ui/core';
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosArrowDown,
  IoIosSearch,
  IoMdList,
  IoMdRadioButtonOff,
  IoMdRadioButtonOn,
} from 'react-icons/io';

import colors from '../../constants/colors';
import { TextSmall, TextNormal, TextNormalBold, fonts } from './Typography';
import styled from 'styled-components';
import { useUiStore } from '../../stores/ui';
import { observer } from 'mobx-react-lite';
import { Id } from '../../types';
import MUIMenu from './MUIMenu';
import ATag from './ATag';

export interface Actions {
  choices: string[];
  onSelectChoices?: (choice: string) => void;
}

interface Search {
  placeholder?: string;
  onChangeSearchText?: (searchTerm: string) => void;
}

interface Filters {
  choices: string[];
  onSelectChoices?: (choice: string) => void;
}

interface Section {
  label: string;
  style?: React.CSSProperties;
  onClick?: (section: Section) => void;
  selected?: boolean;
}

interface HeaderProps {
  leftTitle?: string;
  filters?: Filters;
  search?: Search;
  actions?: Actions;
  sections?: Section[];
  sectionsStyle?: ViewStyle;
  selectedItems?: any[];
  isLoading?: boolean;
  noLoadingIndicator?: boolean;
}

interface FooterProps {
  nbTotalDocument?: number;
  bottomText?: string;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
}

interface AllTableItem<ItemData> {
  sections: TableCellSection[];
  itemData: ItemData & { id: Id };
}

function AllTable<Type>({
  data,
  footer,
  onSelectItem,
  isLoading,
  onClickItem,
  onClickUrl,
  style = {},
  ...rest
}: HeaderProps & {
  footer?: FooterProps;
  data?: AllTableItem<Type>[];
  onSelectItem?: (selectedItems: AllTableItem<Type>['itemData'][]) => void;
  onClickItem?: (clikedItem: AllTableItem<Type>['itemData']) => void;
  onClickUrl?: string;
  isLoading?: boolean;
  style?: ViewStyle;
}) {
  const uiStore = useUiStore();

  const [selectedItems, setSelectedItems] = React.useState<
    AllTableItem<Type>['itemData'][]
  >([]);

  const onPressSelectableItem = (item: AllTableItem<Type>['itemData']) => {
    const tempSelectedItems = [...selectedItems];
    const index = selectedItems.findIndex(i => i.id === item.id);
    if (index === -1) {
      tempSelectedItems.push(item);
    } else {
      tempSelectedItems.splice(index, 1);
    }
    setSelectedItems(tempSelectedItems);
    if (onSelectItem) {
      onSelectItem(tempSelectedItems);
    }
  };

  React.useEffect(() => {
    setSelectedItems([]);
  }, [data?.length, footer?.nbTotalDocument]);

  return (
    <Box
      flex={1}
      style={{
        borderWidth: 1,
        borderRadius: 8,
        borderColor: colors.borderColor,
        backgroundColor: colors.white,
        paddingTop: 20,
        marginTop: 20,
        maxWidth: uiStore.innerWidth - 300,
        ...style,
      }}
    >
      <Header
        {...rest}
        isLoading={isLoading}
        selectedItems={selectedItems}
        sectionsStyle={{
          paddingLeft: onSelectItem
            ? SECTION_LEFT_PADDING_LARGE
            : SECTION_LEFT_PADDING,
        }}
      />

      <Box
        flex={1}
        style={{
          opacity: isLoading ? 0.6 : 1,
          //   maxHeight: '80%',
          // @ts-ignore
          'overflow-y': 'hidden',
        }}
      >
        {data
          ? data.map((item, index) => {
              return (
                <AllTableCell
                  isSelected={
                    selectedItems.findIndex(i => i.id === item.itemData.id) !==
                    -1
                  }
                  onClick={
                    onClickItem ? () => onClickItem(item.itemData) : undefined
                  }
                  url={
                    onClickUrl
                      ? onClickUrl.replace(':id', item.itemData.id)
                      : undefined
                  }
                  onSelectItem={
                    onSelectItem
                      ? () => onPressSelectableItem(item.itemData)
                      : undefined
                  }
                  key={`${index}-${
                    item.itemData && item.itemData.id
                      ? item.itemData.id
                      : 'cell'
                  }`}
                  sections={item.sections}
                />
              );
            })
          : null}
      </Box>

      {footer && (
        <Footer
          footer={{
            ...footer,
            onClickNext: footer.onClickNext
              ? () => {
                  // @ts-ignore
                  footer.onClickNext();
                  setSelectedItems([]);
                }
              : undefined,
            onClickPrevious: footer.onClickPrevious
              ? () => {
                  // @ts-ignore
                  footer.onClickPrevious();
                  setSelectedItems([]);
                }
              : undefined,
          }}
        />
      )}
    </Box>
  );
}

const Footer = ({ footer }: { footer: FooterProps }) => {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      height={72}
      justifyContent="flex-end"
      style={{
        borderTopWidth: 1,
        borderColor: colors.borderColor,
      }}
      pr={32}
    >
      <TextSmall style={{ marginRight: 24 }}>
        {footer.bottomText}{' '}
        {footer.nbTotalDocument ? `of ${footer.nbTotalDocument}` : ''}
      </TextSmall>

      <Box flexDirection="row" alignItems="center">
        <Box
          height={24}
          width={24}
          onClick={footer.onClickPrevious}
          mr={6}
          style={{
            // @ts-ignore
            cursor: footer.onClickPrevious ? 'pointer' : undefined,
            opacity: footer.onClickPrevious ? 1 : 0.4,
          }}
        >
          <IoIosArrowBack />
        </Box>
        <Box
          height={24}
          width={24}
          onClick={footer.onClickNext}
          style={{
            // @ts-ignore
            cursor: footer.onClickNext ? 'pointer' : undefined,
            opacity: footer.onClickNext ? 1 : 0.4,
          }}
        >
          <IoIosArrowForward />
        </Box>
      </Box>
    </Box>
  );
};

const Header = ({
  leftTitle,
  filters,
  search,
  actions,
  sections,
  sectionsStyle,
  selectedItems = [],
  isLoading,
  noLoadingIndicator,
}: HeaderProps) => {
  return (
    <Box
      style={{
        borderBottomWidth: 2,
        borderColor: colors.borderColor,
        paddingBottom: 12,
      }}
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        px={32}
        alignItems="center"
      >
        <Box flexDirection="row">
          <Box style={{ height: leftTitle ? 20 : undefined }}>
            <TextNormalBold style={{ color: colors.black }}>
              {leftTitle}
            </TextNormalBold>
            {selectedItems && selectedItems.length ? (
              <TextSmall>({selectedItems.length} selected)</TextSmall>
            ) : null}
          </Box>
          {isLoading && !noLoadingIndicator ? (
            <CircularProgress
              size={20}
              style={{
                color: colors.accent,
                marginLeft: 15,
                marginTop: 0,
              }}
            />
          ) : null}
        </Box>

        <Box flexDirection="row">
          {filters && <FilterButton filters={filters} />}
          {search && <SearchBar search={search} />}
          {actions && (
            <ActionButton
              actions={actions}
              disabled={!selectedItems || !selectedItems.length}
            />
          )}
        </Box>
      </Box>
      {sections && (
        <Sections
          sections={sections}
          style={{ ...sectionsStyle, marginTop: leftTitle ? 40 : 10 }}
        />
      )}
    </Box>
  );
};

const SECTION_LEFT_PADDING = 32;
const SECTION_LEFT_PADDING_LARGE = 72;

const Sections = observer(
  ({ sections, style }: { sections: Section[]; style?: ViewStyle }) => {
    const uiStore = useUiStore();

    const sectionWidth = uiStore.innerWidth / (sections ? sections.length : 1);
    return (
      <Box flexDirection="row" pl={SECTION_LEFT_PADDING} pr={32} style={style}>
        {sections.map(section => (
          <Box
            key={section.label}
            flex={1}
            // @ts-ignore
            onClick={section.onClick && (() => section.onClick(section))}
            style={[
              {
                width: sectionWidth,
                // @ts-ignore
                cursor: section.onClick ? 'pointer' : undefined,
              },
              // @ts-ignore
              section.style,
            ]}
          >
            <TextNormalBold
              style={{
                color: section.selected ? 'black' : '#9fa2b4',
                fontWeight: section.selected ? 'bold' : undefined,
              }}
            >
              {section.label}
            </TextNormalBold>
          </Box>
        ))}
      </Box>
    );
  },
);

const ActionButton = ({
  actions,
  disabled,
}: {
  actions: Actions;
  disabled?: boolean;
}) => {
  return (
    <MUIMenu
      choices={actions.choices}
      onClose={actions.onSelectChoices}
      disabled={disabled}
      withWarning
    >
      <Box
        // @ts-ignore
        height={40}
        width={132}
        style={{
          opacity: disabled ? 0.5 : 1,
          borderRadius: 8,
          borderWidth: 1,
          borderColor: colors.borderColor,
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 24,
          // @ts-ignore
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <TextNormal style={{ color: '#9fa2b4', marginRight: 16 }}>
          Actions
        </TextNormal>
        <IoIosArrowDown />
      </Box>
    </MUIMenu>
  );
};

const SearchBar = ({ search }: { search: Search }) => {
  return (
    <Box
      width={224}
      height={40}
      bg="rgb(252,253,254)"
      style={{
        borderWidth: 1,
        borderColor: 'rgb(242,243,248)',
        borderRadius: 8,
      }}
      flexDirection="row"
      pl={24}
      pr={10}
      alignItems="center"
      mr={31}
    >
      <IoIosSearch
        style={{
          width: 16,
          height: 16,
          marginRight: 8,
        }}
      />

      <SearchInput
        style={{
          fontFamily: fonts.Roboto,
          fontSize: 14,
          fontWeight: 400,
          color: '#4b506d',
          flex: 1,
        }}
        placeholder={search.placeholder}
        onChange={e => {
          if (search.onChangeSearchText) {
            search.onChangeSearchText(e.target.value);
          }
        }}
      />
    </Box>
  );
};

const SearchInput = styled.input`
  font-family: ${fonts.Roboto};
  font-size: 14px;
  font-weight: 400;
  color: #4b506d;
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  ::placeholder {
    color: rgb(187, 190, 208);
  }
`;

const FilterButton = ({ filters }: { filters: Filters }) => {
  return (
    <MUIMenu choices={filters.choices} onClose={filters.onSelectChoices}>
      <Box
        // @ts-ignore
        flexDirection="row"
        alignItems="center"
        style={{
          // @ts-ignore
          cursor: 'pointer',
        }}
        mr={31}
      >
        <IoMdList style={{ width: 16, height: 16, marginRight: 8 }} />

        <TextNormal
          style={{ fontWeight: 600, fontSize: 14, fontFamily: fonts.Roboto }}
        >
          Filter
        </TextNormal>
      </Box>
    </MUIMenu>
  );
};

export interface TableCellSection {
  render: () => React.ReactNode;
  style?: React.CSSProperties;
}

export const AllTableCell = observer(
  ({
    sections,
    onClick,
    isSelected,
    onSelectItem,
    url,
  }: {
    sections?: TableCellSection[];
    onClick?: () => void;
    isSelected?: boolean;
    onSelectItem?: () => void;
    url?: string;
  }) => {
    const uiStore = useUiStore();

    const sectionWidth = uiStore.innerWidth / (sections ? sections.length : 1);

    const MainContainer = url ? ATag : React.Fragment;

    let mainContainerProps = {};

    if (url) {
      mainContainerProps = { href: url, noOpacity: true };
    }

    return (
      <MainContainer {...mainContainerProps}>
        <BoxContainer
          onClick={onClick}
          style={{
            backgroundColor: isSelected ? colors.accent : undefined,
            minHeight: 30,
            paddingLeft: onSelectItem
              ? SECTION_LEFT_PADDING_LARGE
              : SECTION_LEFT_PADDING,
          }}
        >
          {sections
            ? sections.map((section, index) => (
                // @ts-ignore
                <Box
                  key={`${index}-cellTable`}
                  flex={1}
                  // @ts-ignore
                  style={[
                    { width: sectionWidth, overflow: 'hidden' },
                    // @ts-ignore
                    section.style,
                  ]}
                >
                  {section.render()}
                </Box>
              ))
            : null}
          {onSelectItem && (
            <Box
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                width: 76,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              // @ts-ignore
              onClick={onSelectItem}
            >
              {isSelected ? (
                <IoMdRadioButtonOn style={{ height: 20, width: 20 }} />
              ) : (
                <IoMdRadioButtonOff style={{ height: 20, width: 20 }} />
              )}
            </Box>
          )}
        </BoxContainer>
      </MainContainer>
    );
  },
);

const BoxContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  padding-left: ${SECTION_LEFT_PADDING}px;
  padding-right: ${SECTION_LEFT_PADDING}px;
  background-color: white;
  border-bottom: 1px solid ${colors.borderColor};
  &:hover {
    cursor: pointer;
    background-color: #f8f8f8;
  }
`;

export default AllTable;
